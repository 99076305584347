import { endpoints } from "../../../constants/url.endpoint";
import { getHelpAction } from "./getHelp.reducer";

const {
    getHelpSuccess,
    editHelpSuccess,
    addHelpSuccess,
    deleteHelpSuccess,
    viewHelpSuccess,
    resetHelpData
} = getHelpAction;

export const getHelps = (axiosInstance, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        axiosInstance({
            url: endpoints.getHelp,
            method: "GET",
            params: params,
        })
            .then((response) => {
                dispatch(getHelpSuccess(response?.data?.data));
                resolve(response?.data?.data);
            })
            .catch((error) => {
                console.error('Error in Get Help', error)
                dispatch(resetHelpData());
                reject(error);
            });
    });
};


export const viewHelp = (axiosInstance, helpId, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        axiosInstance({
            url: endpoints.editHelp + helpId,
            method: "GET",
            params: params,
        })
            .then((response) => {
                dispatch(viewHelpSuccess(response?.data?.data));
                resolve(response?.data?.data);
            })
            .catch((error) => {
                console.error('Error in View Help', error)
                dispatch(resetHelpData());
                reject(error);
            });
    });
};

export const editHelp = (axiosInstance, helpDetails, params) => async (dispatch) => {
    console.log(helpDetails)
    return new Promise((resolve, reject) => {
        axiosInstance({
            url: endpoints.editHelp + helpDetails.id,
            method: "PATCH",
            params: params,
            data: helpDetails
        })
            .then((response) => {
                dispatch(editHelpSuccess(response?.data?.data));
                resolve(response?.data?.data);
            })
            .catch((error) => {
                console.error('Error in Edit Help', error)
                dispatch(resetHelpData());
                reject(error);
            });
    });
};

export const addHelp = (axiosInstance, helpDetails, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        axiosInstance({
            url: endpoints.addHelp,
            method: "POST",
            params: params,
            data: helpDetails
        })
            .then((response) => {
                dispatch(addHelpSuccess(response?.data));
                resolve(response?.data);
            })
            .catch((error) => {
                console.error('Error in Add Help', error)
                dispatch(resetHelpData());
                reject(error);
            });
    });
};

export const deleteHelp = (axiosInstance, payload) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        axiosInstance({
            url: endpoints.deleteHelp,
            method: "DELETE",
            data: payload,
        })
            .then((response) => {
                dispatch(deleteHelpSuccess(response?.data));
                resolve(response?.data);
            })
            .catch((error) => {
                console.error('Error in Delete Help', error)
                dispatch(resetHelpData());
                reject(error);
            });
    });
};