import { endpoints } from "../../../constants/url.endpoint";
import { userRolesAction } from "./userRoles.reducer";

const {
    getUserRolesuccess,
    getPermissionssuccess,
    addUserRolesuccess,
    editUserRolesuccess,
    deleteUserRolesuccess,
    resetUserRolesData
} = userRolesAction;

export const getUserRoles =
    (axiosInstance, params, searchTerm) => async (dispatch) => {
        return new Promise((resolve, reject) => {
            axiosInstance({
                url: endpoints.getRoles,
                method: "GET",
                params: params,
            })
                .then((response) => {
                    dispatch(getUserRolesuccess(response?.data?.data));
                    resolve(response?.data?.data);
                })
                .catch((error) => {
                    console.log('er', error)
                    dispatch(resetUserRolesData());
                    reject(error);
                });
        });
    };

export const addUserRole =
    (axiosInstance, details) => async (dispatch) => {
        return new Promise((resolve, reject) => {
            axiosInstance({
                url: endpoints.addRole,
                method: "POST",
                data: details
            })
                .then((response) => {
                    dispatch(addUserRolesuccess(response?.data));
                    resolve(response?.data);
                })
                .catch((error) => {
                    console.log('er', error)
                    dispatch(resetUserRolesData());
                    reject(error);
                });
        });
    };

export const editUserRole =
    (axiosInstance, details, params) => async (dispatch) => {
        return new Promise((resolve, reject) => {
            axiosInstance({
                url: endpoints.editRole + params.id,
                method: "POST",
                data: details
            })
                .then((response) => {
                    dispatch(editUserRolesuccess(response?.data));
                    resolve(response?.data);
                })
                .catch((error) => {
                    console.log('er', error)
                    dispatch(resetUserRolesData());
                    reject(error);
                });
        });
    };

export const deleteUserRole =
    (axiosInstance, params, ids) => async (dispatch) => {
        return new Promise((resolve, reject) => {
            axiosInstance({
                url: endpoints.deleteRole,
                method: "POST",
                params: params,
                data: ids
            })
                .then((response) => {
                    dispatch(deleteUserRolesuccess(response?.data?.data));
                    resolve(response?.data?.data);
                })
                .catch((error) => {
                    console.log('er', error)
                    dispatch(resetUserRolesData());
                    reject(error);
                });
        });
    };

export const getPermissions =
    (axiosInstance, params) => async (dispatch) => {
        return new Promise((resolve, reject) => {
            axiosInstance({
                url: endpoints.permissions,
                method: "GET",
                params: params,
            })
                .then((response) => {
                    dispatch(getPermissionssuccess(response?.data?.data));
                    resolve(response?.data?.data);
                })
                .catch((error) => {
                    console.log('er', error)
                    dispatch(resetUserRolesData());
                    reject(error);
                });
        });
    };