export const validate = ({ name, rememberMe, email, password }) => {
  const errors = {};
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  if (!email) {
    errors.email = "Email is required!";
  } else if (!regex.test(email.trim())) {
    errors.email = "This is not a valid email format!";
  }
  return errors;
};
