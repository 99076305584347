import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Toaster from "../../../../../components/Global/Toaster/Toaster.component";
import useAxiosInstance from "../../../../../hooks/useAxiosInstance";
import {
  deleteHelp,
  viewHelp,
} from "../../../../../redux/AurLaVieAdmin/getHelp/getHelp.action";
import Button from "../../../../../shared/components/button/button";
import buttonStyle from "../../../../../shared/components/button/button.module.scss";
import Delete from "../../../../../shared/components/delete/delete";
import Heading from "../../../../../shared/components/heading/heading";
import CenterModal from "../../../../../shared/components/modal/centeredModal/centeredModal";
import { SETTINGS_MANAGEMENT } from "../../settings.constant";
import EditGetHelp from "../editGetHelp/editGetHelp";
import style from "./viewGetHelp.module.scss";

const ViewHelp = (props) => {
  const { details, deleteSuccess, isFengShuiSetting } = props;
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const [isEdit, setIsEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [helpDetails, setHelpDetails] = useState();

  useEffect(() => {
    const params = {
      isAdmin: true,
    };
    viewGetHelp(axiosInstance, details.id, params);
  }, []);

  const onEdit = () => {
    setIsEdit(true);
  };

  const onButtonClick = (clickedButton) => {
    if (clickedButton === "Cancel") {
      setShowDelete(false);
    } else if (clickedButton === "Ok") {
      const params = {
        isAdmin: true,
      };
      deleteGetHelp(axiosInstance, { ids: [details.id] }, params);
    }
  };

  const viewGetHelp = (axiosInstance, helpId, params) => {
    dispatch(viewHelp(axiosInstance, helpId, params))
      .then((response) => {
        setHelpDetails({ ...response, id: response?._id });
      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            // title: "Failed to Fetch",
            title: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const deleteGetHelp = (axiosInstance, helpId, params) => {
    dispatch(deleteHelp(axiosInstance, helpId, params))
      .then((response) => {
        setShowDelete(false);
        setToastList([
          {
            id: 0,
            // title: "Help Deleted",
            title: response?.message,
            icon: "success",
          },
        ]);
        setTimeout(() => {
          deleteSuccess(true);
        }, 1500);
      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            // title: "Deleting Helps Failed",
            title: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const editSuccess = (status) => {
    if (status) {
      setIsEdit(false);
      const params = {
        isAdmin: true,
      };
      viewGetHelp(axiosInstance, details.id, params);
    }
  };

  return (
    <>
      {isEdit ? (
        <EditGetHelp details={helpDetails} editSuccess={editSuccess} />
      ) : (
        <>
          <section className={style.container}>
            <div className={style.wrapper}>
              <CenterModal
                isOpen={showDelete}
                onClose={() => {
                  setShowDelete(false);
                }}
                minWidth={"300px"}
              >
                <Delete
                  title={"Delete Help"}
                  description={"Are you sure you want to delete this Help?"}
                  onButtonClick={onButtonClick}
                />
              </CenterModal>
              <section className={style.title_filters}>
                <Heading
                  title={
                    isFengShuiSetting
                      ? SETTINGS_MANAGEMENT.FAQ.HEADING.TITLE
                      : SETTINGS_MANAGEMENT.GET_HELP.HEADING.TITLE
                  }
                  description={
                    isFengShuiSetting
                      ? SETTINGS_MANAGEMENT.FAQ.HEADING.DESCRIPTION
                      : SETTINGS_MANAGEMENT.GET_HELP.HEADING.DESCRIPTION
                  }
                />
                <div className={style.filters}>
                  {isEdit ? (
                    <Button
                      text={
                        isFengShuiSetting
                          ? SETTINGS_MANAGEMENT.FAQ.BUTTONS.SAVE_TITLE
                          : SETTINGS_MANAGEMENT.GET_HELP.BUTTONS.SAVE_TITLE
                      }
                      buttonType={`${buttonStyle.button_primary}`}
                    />
                  ) : (
                    <>
                      <Button
                        icon={"/icons/edit.svg"}
                        text={
                          isFengShuiSetting
                            ? SETTINGS_MANAGEMENT.FAQ.BUTTONS.EDIT_TITLE
                            : SETTINGS_MANAGEMENT.GET_HELP.BUTTONS.EDIT_TITLE
                        }
                        onClick={onEdit}
                      />
                      <Button
                        icon={"/icons/delete.svg"}
                        text={
                          isFengShuiSetting
                            ? SETTINGS_MANAGEMENT.FAQ.BUTTONS.DELETE_TITLE
                            : SETTINGS_MANAGEMENT.GET_HELP.BUTTONS.DELETE_TITLE
                        }
                        onClick={() => {
                          setShowDelete(true);
                        }}
                      />
                    </>
                  )}
                </div>
              </section>
              <section className={style.content}>
                <label className={style.label}>Question</label>
                <p className={style.question}>{helpDetails?.question}</p>
                <label className={style.label}>Answer</label>
                <p className={style.answer}>{helpDetails?.answer}</p>
              </section>
            </div>
            <Toaster
              toastList={toastList}
              position={"top-right"}
              autoDelete={true}
              autoDeleteTime={2500}
            />
          </section>
        </>
      )}
    </>
  );
};

export default ViewHelp;
