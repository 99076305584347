export const PROFILE_MANAGEMENT = {
    HEADING: {
        TITLE: 'Profile Management',
        DESCRIPTION: "",
    },
    EDIT_PROFILE: {
        TITLE: "Edit Profile",
        BACK: {
            TITLE: 'Edit Profile',
            DESCRIPTION: 'Back to profile management',
        },
        BUTTONS: {
            TITLE: 'Update Tip'
        },
        UPLOAD: {
            TITLE: 'Profile Image'
        }
    }
}