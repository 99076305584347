import { endpoints } from "../../../constants/url.endpoint";
import { manageTipsActions } from "./manageTips.reducer";

const {
  manageTipSuccess,
  addTipSuccess,
  editTipSuccess,
  viewTipSuccess,
  resetManageTipData,
  resetTipDetail,
} = manageTipsActions;

export const fetchTipsListing =
  (axiosInstance, payload, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.tipsListing,
        method: "GET",
        params: params,
        data: payload,
      })
        .then((res) => {
          dispatch(manageTipSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(resetTipDetail());
          reject(err);
        });
    });
  };

export const addTipCreate =
  (axiosInstance, tipDetails, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.addTip,
        method: "POST",
        data: tipDetails,
        params: params,
      })
        .then((response) => {
          dispatch(addTipSuccess(response?.data?.data));
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(resetTipDetail());
          reject(error);
        });
    });
  };

export const editTip =
  (axiosInstance, data, id, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.editTip + id,
        method: "PATCH",
        data,
        params,
      })
        .then((response) => {
          dispatch(editTipSuccess(response?.data?.data));
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(resetTipDetail());
          reject(error);
        });
    });
  };

export const viewTip = (axiosInstance, id, params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: endpoints.viewTip + id,
      method: "GET",
      params,
    })
      .then((response) => {
        dispatch(viewTipSuccess(response?.data?.data));
        resolve(response?.data?.data);
      })
      .catch((error) => {
        dispatch(resetTipDetail());
        reject(error);
      });
  });
};

export const deleteTip = (axiosInstance, data, params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: endpoints.deleteTip,
      method: "POST",
      params,
      data,
    })
      .then((response) => {
        dispatch(resetManageTipData(response?.data?.data));
        resolve(response?.data?.data);
      })
      .catch((error) => {
        dispatch(resetTipDetail());
        reject(error);
      });
  });
};
