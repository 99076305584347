import { endpoints } from "../../../constants/url.endpoint";
import { subAdminAction } from "./subAdmin.reducer";

const {
    getSubAdminSuccess,
    viewSubAdminSuccess,
    addSubAdminSuccess,
    editSubAdminSuccess,
    deleteSubAdminSuccess,
    getRolesSuccess,
    resetSubAdminData
} = subAdminAction;

export const getSubAdmins =
    (axiosInstance, params, searchTerm) => async (dispatch) => {
        return new Promise((resolve, reject) => {
            axiosInstance({
                url: endpoints.getSubAdmins,
                method: "POST",
                params: params,
                data: {
                    keyword: searchTerm
                }
            })
                .then((response) => {
                    dispatch(getSubAdminSuccess(response?.data?.data));
                    resolve(response?.data?.data);
                })
                .catch((error) => {
                    console.log('er', error)
                    dispatch(resetSubAdminData());
                    reject(error);
                });
        });
    };

export const viewSubAdmin =
    (axiosInstance, params, userId) => async (dispatch) => {
        return new Promise((resolve, reject) => {
            axiosInstance({
                url: endpoints.viewSubAdmin + userId,
                method: "GET",
                params: params,
            })
                .then((response) => {
                    console.log(response.data, 'my User')
                    dispatch(viewSubAdminSuccess(response?.data?.data));
                    resolve(response?.data?.data);
                })
                .catch((error) => {
                    console.log('er', error)
                    dispatch(resetSubAdminData());
                    reject(error);
                });
        });
    };

export const addSubAdmin =
    (axiosInstance, params, subAdminDetails) => async (dispatch) => {
        return new Promise((resolve, reject) => {
            axiosInstance({
                url: endpoints.addSubAdmin,
                method: "POST",
                params: params,
                data: subAdminDetails
            })
                .then((response) => {
                    dispatch(addSubAdminSuccess(response?.data?.data));
                    resolve(response?.data?.data);
                })
                .catch((error) => {
                    console.log('er', error)
                    dispatch(resetSubAdminData());
                    reject(error);
                });
        });
    };

export const editSubAdmin =
    (axiosInstance, params, subAdminDetails, subAdminId) => async (dispatch) => {
        console.log('subAdminId', subAdminId)
        return new Promise((resolve, reject) => {
            axiosInstance({
                url: endpoints.editSubAdmin + subAdminId,
                method: "POST",
                params: params,
                data: subAdminDetails
            })
                .then((response) => {
                    dispatch(editSubAdminSuccess(response?.data?.data));
                    resolve(response?.data?.data);
                })
                .catch((error) => {
                    console.log('er', error)
                    dispatch(resetSubAdminData());
                    reject(error);
                });
        });
    };

export const deleteSubAdmin =
    (axiosInstance, subAdminDetails) => async (dispatch) => {
        return new Promise((resolve, reject) => {
            axiosInstance({
                url: endpoints.deleteSubAdmin,
                method: "POST",
                data: subAdminDetails
            })
                .then((response) => {
                    dispatch(deleteSubAdminSuccess(response?.data?.data));
                    resolve(response?.data?.data);
                })
                .catch((error) => {
                    console.log('er', error)
                    dispatch(resetSubAdminData());
                    reject(error);
                });
        });
    };

export const getRoles =
    (axiosInstance, params) => async (dispatch) => {
        return new Promise((resolve, reject) => {
            axiosInstance({
                url: endpoints.roles,
                method: "GET",
                params: params,
            })
                .then((response) => {
                    dispatch(getRolesSuccess(response?.data?.data));
                    resolve(response?.data?.data);
                })
                .catch((error) => {
                    console.log('er', error)
                    dispatch(resetSubAdminData());
                    reject(error);
                });
        });
    };