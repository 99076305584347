import React, { useEffect, useRef, useState } from "react";
import Table from "../../../../shared/components/table/table";
import Input from "../../../../shared/components/inputFields/input";
import Button from "../../../../shared/components/button/button";
import Heading from "../../../../shared/components/heading/heading";
import style from "./userRoles.module.scss";
import { USER_ROLES } from "../subAdminUserRolesContants";
import { deleteUserRole, getPermissions, getUserRoles } from "../../../../redux/AurLaVieAdmin/userRoles/userRoles.action";
import { useDispatch } from "react-redux";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import moment from "moment";
import CenterModal from "../../../../shared/components/modal/centeredModal/centeredModal";
import AddEditUserRole from "./addEditUserRole/addEditUserRole";
import Delete from "../../../../shared/components/delete/delete";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";

const UserRoles = () => {

    const [roles, setRoles] = useState([]);
    const [toastList, setToastList] = useState([]);
    const dispatch = useDispatch();
    const axiosInstance = useAxiosInstance();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [showAddRoles, setShowAddRoles] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [ids, setIds] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null);
    const [permissions, setPermissions] = useState();
    const currentDeleteRef = useRef(null);
    const permissionRef = useRef(null);

    const params = {
        page,
        limit: pageSize,
        isAdmin: true
    }

    useEffect(() => {
        getAllUserRoles(axiosInstance, params);
        getAllPermissions(axiosInstance, params);
    }, [page])

    useEffect(() => {
        let timerId;
        const fetchResults = async () => {
            try {
                setPage(1);
                const params = {
                    page: 1,
                    limit: pageSize,
                    keyword: searchTerm
                };
                getAllUserRoles(axiosInstance, params);
            } catch (error) {
                console.log(error);
            }
        };

        if (searchTerm || searchTerm === "") {
            timerId = setTimeout(() => {
                fetchResults();
            }, 500);
        }
        return () => {
            clearTimeout(timerId);
        };
    }, [searchTerm]);

    const onSearch = (event) => {
        setSearchTerm(event);
    };

    const getAllUserRoles = (axiosInstance, params, searchTerm) => {
        dispatch(getUserRoles(axiosInstance, params, searchTerm)).then((response) => {
            const updatedRoles = response?.listingData?.map((role, index) => {
                const newIndex = ((page - 1) * pageSize) + index + 1;
                return {
                    id: role?.roleId,
                    "S. No": newIndex > 100
                        ? newIndex
                        : newIndex < 100 && newIndex > 9
                            ? `0${newIndex}`
                            : `00${newIndex}`,
                    userRole: role?._id,
                    accessProvided: role?.permissionDetails.map((item) => {
                        return item.permissionLink && item.permissionLink.includes("aurlavie") ? 'ALV ' + item.permissionName : item.permissionLink && item.permissionLink.includes("fengshui") ? 'FS ' + item.permissionName : item.permissionName;
                    }).join(", "),
                    lastUpdatedOn: moment(role?.modified_at).format("MM/DD/YYYY"),
                }
            })
            setRoles(updatedRoles);
            setTotalItem(response?.meta?.total);
        }).catch((err) => {
            setToastList([
                {
                    id: 0,
                    // title: "Fething Helps Failed",
                    title: err?.response?.data?.message,
                    icon: "error",
                },
            ]);
        });
    }
    
    const getAllPermissions = (axiosInstance, params) => {
        dispatch(getPermissions(axiosInstance, params)).then((response) => {
            const updatedPermissions = response.map((permission) => {
                if (permission.parentId && permission.parentId.permissionName === 'Feng Shui') {
                  return {
                    ...permission,
                    value: permission._id,
                    label: `FS ${permission.permissionName}`,
                  };
                }
                if (permission.parentId && permission.parentId.permissionName === 'Auracle Life') {
                  return {
                    ...permission,
                    value: permission._id,
                    label: `ALV ${permission.permissionName}`,
                  };
                }
                return {
                    value: permission._id,
                    label: permission.permissionName
                };
              });
            setPermissions(updatedPermissions);
        }).catch((err) => {
            setToastList([
                {
                    id: 0,
                    // title: "Fething Permissions Failed",
                    title: err?.response?.data?.message,
                    icon: "error",
                },
            ]);
        });
    }

    const handlePage = (page) => {
        setPage(page);
    };

    const handleMultiple = (event) => {
        setIds(event);
    };

    const closeModal = () => {
        setShowAddRoles(false);
    };

    const handleClose = () => {
        setShowAddRoles(false);

        getAllUserRoles(axiosInstance, params);
        getAllPermissions(axiosInstance, params);
    }

    const addRole = () => {
        setIsEdit(false);
        setShowAddRoles(true);
    }

    const onEdit = (data) => {
        const splitData = data.accessProvided.split(", ");
        const intersection = permissions.filter(element => splitData.includes(element.label));
        const payload = {
            permissions: intersection,
            roleName: data.userRole,
            id: data.id
        }
        permissionRef.current = payload;
        setIsEdit(true);
        setShowAddRoles(true);
    }

    const deletemany = () => {
        if (ids.length) {
            setShowDeleteModal(true);
        }
    };

    const onDelete = (data) => {
        currentDeleteRef.current = [data.id];
        setShowDeleteModal(true);
    }

    const handleDeleteRoles = (clicked) => {
        if (clicked === "Cancel") {
            setShowDeleteModal(false);
        } else {
            const data = currentDeleteRef.current?.length
                ? { ids: currentDeleteRef.current }
                : { ids };
            const params = {
                isAdmin: true
            }
            dispatch(deleteUserRole(axiosInstance, params, data))
                .then((res) => {
                    setShowDeleteModal(false);
                    setIds(null);
                    currentDeleteRef.current = null;
                    setToastList([
                        {
                            id: 0,
                            // title: "User Deleted",
                            title: "User Deleted Successfully",
                            icon: "success",
                        },
                    ]);
                    setTimeout(() => {
                        getAllUserRoles(axiosInstance, params);
                    }, 1500);
                })
                .catch((err) => {
                    console.log(err);
                    setToastList([
                        {
                            id: 0,
                            // title: "Delete User Error",
                            title: err?.response?.data?.message,
                            icon: "error",
                        },
                    ]);
                });
        }
    };

    return <section className={style.container}>
        <div className={style.wrapper}>
            <CenterModal isOpen={showAddRoles} onClose={closeModal}>
                <AddEditUserRole closed={showAddRoles} selectedOptions={isEdit ? permissionRef.current : null} handleClose={handleClose} permissions={permissions} isEdit={isEdit} />
            </CenterModal>
            <CenterModal isOpen={showDeleteModal} onClose={() => { setShowDeleteModal(false); }} minWidth={"300px"}>
                <Delete title={USER_ROLES.DELETE.TITLE} description={USER_ROLES.DELETE.DESCRIPTION} onButtonClick={handleDeleteRoles} />
            </CenterModal>
            <section className={style.title_filters}>
                <Heading title={USER_ROLES.HEADING.TITLE} description={USER_ROLES.HEADING.DESCRIPTION} icon="/icons/subAdmin_blue.svg" />
                <div className={style.filters}>
                    <Button icon={"/icons/add.svg"} text={USER_ROLES.BUTTONS.TITLE} onClick={addRole} />
                    <Input
                        type={"search"}
                        placeholder={USER_ROLES.FILTERS.SEARCH.PLACEHOLDER}
                        value={searchTerm}
                        onChange={onSearch}
                    />
                    <Button icon={"/icons/delete.svg"} onClick={deletemany} />
                </div>
            </section>
            <section className={style.listing}>
                <Table
                    data={roles}
                    viewNotRequired={true}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    noDataText={USER_ROLES.TABLE.NO_DATA}
                    totalItem={totalItem}
                    handlePage={handlePage}
                    pageNumber={page}
                    handleMultiple={handleMultiple}
                />
            </section>
        </div>
        <Toaster
            toastList={toastList}
            position={"top-right"}
            autoDelete={true}
            autoDeleteTime={2500}
        />
    </section>
}

export default UserRoles;