import { useLocation, useSearchParams } from "react-router-dom";
import Heading from "../../../shared/components/heading/heading";
import Tabs from "../../../shared/components/tabs/tabs";
import About from "./about/about";
import GetHelp from "./getHelp/getHelp";
import PrivacyPolicy from "./privacyPolicy/privacyPolicy";
import { SETTINGS_MANAGEMENT } from "./settings.constant";
import style from "./settings.module.scss";
import TermsOfService from "./termsOfServices/termsOfServices";

const ViewBookSection = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isFengShuiSetting =
    location.pathname === SETTINGS_MANAGEMENT.URL_PATH.FENG_SHUI;

  const tabs = [
    {
      label: "Terms of Services",
      icon: "/icons/info_",
      content: <TermsOfService />,
    },
    {
      label: isFengShuiSetting ? "How Aursome Works" : "About",
      icon: "/icons/info_",
      content: <About />,
    },
    {
      label: "Privacy Policy",
      icon: "/icons/privacy_",
      content: <PrivacyPolicy />,
    },
    {
      label: isFengShuiSetting ? "FAQs" : "Get Help",
      icon: "/icons/help_",
      content: <GetHelp />,
    },
  ];

  return (
    <section className={style.container}>
      <div className={style.wrapper}>
        <Heading
          title={SETTINGS_MANAGEMENT.HEADING.TITLE}
          description={SETTINGS_MANAGEMENT.HEADING.DESCRIPTION}
          icon="/icons/settings.svg"
        />
        <div className={style.tabs}>
          <Tabs tabs={tabs} />
        </div>
      </div>
    </section>
  );
};

export default ViewBookSection;
