import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import "./Toaster.component.scss";
import { useDispatch } from 'react-redux';
import { resetToasters } from '../../../redux/appToaster/appToaster.actions'

const Toaster = props => {
    const { toastList, position, autoDelete, autoDeleteTime } = props;
    const [list, setList] = useState(toastList);
    const [toastIcon, setToastIcon] = useState("");
    const dispatch = useDispatch()

    useEffect(() => {
        setList([...toastList]);
    }, [toastList]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (autoDelete && toastList.length && list.length) {
                deleteToast(toastList[0].id);
                dispatch(resetToasters())
            }
        }, autoDeleteTime);
        
        return () => {
            clearInterval(interval);
        }

        // eslint-disable-next-line
    }, [toastList, autoDelete, autoDeleteTime, list]);

    const deleteToast = id => {
        const listItemIndex = list.findIndex(e => e.id === id);
        const toastListItem = toastList.findIndex(e => e.id === id);
        list.splice(listItemIndex, 1);
        toastList.splice(toastListItem, 1);
        setList([...list]);
    }

    return (
        <>
            <div className={`toaster-container ${position}`}>
                {
                    list.map((toast, i) =>     
                        <div 
                            key={i}
                            className={`toaster toast ${position}`}
                            style={{ backgroundColor: toast.icon!=="success" ? "rgb(225, 112, 112)" : ""}}
                        >
                            <button onClick={() => deleteToast(toast.id)}>
                                &times;
                            </button>
                            <div className="toaster-image">
                                <img src={toast.icon ==="success" ? "/toast/check.svg" : "/images/error.svg"} alt="" />
                            </div>
                            <div>
                                <p className="toaster-title" style={{ color: toast.icon!=="success" ? "white" : ""}}>{toast.title}</p>
                                <p className="toaster-message" style={{ color: toast.icon!=="success" ? "white" : ""}}>
                                    {toast.description}
                                </p>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
}

Toaster.propTypes = {
    toastList: PropTypes.array.isRequired,
    position: PropTypes.string.isRequired,
    autoDelete: PropTypes.bool,
    autoDeleteTime: PropTypes.number
}

export default Toaster;