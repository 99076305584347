import Button from '../button/button';
import style from './delete.module.scss';
import buttonStyle from '../button/button.module.scss';

const Delete = ({ title, description, onButtonClick}) => {

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <h2 className={style.title}>{title}</h2>
                <p className={style.description}>{description}</p>
                <div className={style.action_buttons}>
                    <Button text={'Cancel'} buttonType={`${buttonStyle.button_primary}`} marginTop={0} onClick={() => onButtonClick('Cancel')}/>
                    <Button text={'OK'} buttonType={`${buttonStyle.button_secondary}`} marginTop={0} onClick={() => onButtonClick('Ok')}/>
                </div>
            </div>
        </div>
    )
}

export default Delete;