import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    data: null,
};

const userTypesSlice = createSlice({
    name: "userTypes",
    initialState,
    reducers: {
        getUserTypeSuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        resetUserTypeData: (state, action) => {
            state = {
                listing: [],
                data: null,
            };
        },
    },
});

export const {
    actions: userTypesAction,
    reducer: userTypesReducer,
    name: userTypes,
} = userTypesSlice;