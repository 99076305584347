import { useEffect, useState } from 'react';
import { APP } from '../../globalConstants/constants';
import style from './appSwitchButton.module.scss';

const AppSwitchButton = (props) => {

    const { selectedApp, handleSwitch, length } = props;
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(selectedApp);
    }, [selectedApp])

    const handleClick = (app) => {
        if (length === 2) {
            handleSwitch(app)
            setIsActive(app);
        } else {
            return true;
        }

    }

    return (
        <div className={style.container}>
            <button style={{ cursor: length === 2 ? "pointer" : "default" }} className={isActive === APP.FENG_SHUI[0].value ? `${style.active}` : `${style.inactive}`} onClick={() => handleClick(APP.FENG_SHUI[0].value)}>
                <img src={isActive === APP.FENG_SHUI[0].value ? '/icons/fs_white.svg' : '/icons/fs_grey.svg'} alt='Feng Shui' />
                <span>{APP.FENG_SHUI[0].lable}</span>
            </button>
            <button style={{ cursor: length === 2 ? "pointer" : "default" }} className={isActive === APP.AUR_LA_VIE[0].value ? `${style.active}` : `${style.inactive}`} onClick={() => handleClick(APP.AUR_LA_VIE[0].value)}>
                <img src={isActive === APP.AUR_LA_VIE[0].value ? '/icons/alv_white.svg' : '/icons/alv_grey.svg'} alt='Feng Shui' />
                <span>{APP.AUR_LA_VIE[0].lable}</span>
            </button>
        </div>
    )
}

export default AppSwitchButton;