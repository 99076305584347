import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useAxiosInstance from "../../../../../hooks/useAxiosInstance";
import { addHelp } from "../../../../../redux/AurLaVieAdmin/getHelp/getHelp.action";
import Button from "../../../../../shared/components/button/button";
import buttonStyle from "../../../../../shared/components/button/button.module.scss";
import Heading from "../../../../../shared/components/heading/heading";
import Input from "../../../../../shared/components/inputFields/input";
import { TextAlign } from "../../../../../shared/globalConstants/constants";
import { SETTINGS_MANAGEMENT } from "../../settings.constant";
import style from "./addGetHelp.module.scss";

const AddGetHelp = (props) => {
  const { handleClose, appId, languageId, setToastList, isFengShuiSetting } =
    props;

  const inputFields = [
    {
      type: "text",
      name: "question",
      placeholder: "Type your question here",
      label: "Question",
      required: true,
      width: "100%",
    },
    {
      type: "textarea",
      name: "answer",
      placeholder: "Answer",
      label: "Answer",
      required: true,
      width: "100%",
    },
  ];
  const [formData, setFormData] = useState({});
  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();
  // const [toastList, setToastList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    const hasEmptyRequiredFields = inputFields
      .filter((field) => field.required)
      .some((field) => !formData[field.name]);
    setIsSubmitDisabled(hasEmptyRequiredFields);
  }, [inputFields, formData]);

  const handleInputChange = (fieldName, fieldValue) => {
    setFormData({ ...formData, [fieldName]: fieldValue });
  };

  useEffect(() => {
    if (props.resetText) {
      setFormData({});
    }
  }, [props.resetText]);

  const handleSubmit = (event) => {
    event.preventDefault();
    formData["languageId"] = languageId;
    formData["appId"] = appId;
    const params = {
      isAdmin: true,
    };
    addGetHelp(axiosInstance, formData, params);
  };

  const addGetHelp = (axiosInstance, helpDetails, params) => {
    dispatch(addHelp(axiosInstance, helpDetails, params))
      .then((response) => {
        handleClose();
        setToastList([
          {
            id: 0,
            // title: "Help Added",
            title: response.message,
            icon: "success",
          },
        ]);

        setTimeout(() => {
          setFormData({});
          // handleClose();
        }, 1000);
      })
      .catch((error) => {
        setToastList([
          {
            id: 0,
            // title: "Error adding help",
            title: error.response.data.message,
            icon: "error",
          },
        ]);
      });
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    if (name === "question") {
      if (value === "") {
        setFormErrors({ ...formErrors, question: "Question is required" });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    } else if (name === "answer") {
      if (value === "") {
        setFormErrors({ ...formErrors, answer: "Answer is required" });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className={style.heading}>
          <Heading
            title={
              isFengShuiSetting
                ? SETTINGS_MANAGEMENT.FAQ.ADD_FAQ.HEADING.TITLE
                : SETTINGS_MANAGEMENT.GET_HELP.ADD_GET_HELP.HEADING.TITLE
            }
            description={
              isFengShuiSetting
                ? SETTINGS_MANAGEMENT.FAQ.ADD_FAQ.HEADING.DESCRIPTION
                : SETTINGS_MANAGEMENT.GET_HELP.ADD_GET_HELP.HEADING.DESCRIPTION
            }
            textAlign={TextAlign.CENTER}
          />
        </div>
        <div className={style.form_container}>
          <form onSubmit={handleSubmit}>
            {inputFields.map((inputField, index) => (
              <div
                key={index}
                className={style.input_field}
                style={{ width: inputField.width }}
              >
                <Input
                  type={inputField.type}
                  name={inputField.name}
                  label={inputField.label}
                  value={formData[inputField.name]}
                  placeholder={inputField.placeholder}
                  onChange={(value) =>
                    handleInputChange(inputField.name, value)
                  }
                  options={inputField.options}
                  required={inputField.required}
                  handleInputBlur={handleInputBlur}
                />
                <div className={style.error}>{formErrors[inputField.name]}</div>
              </div>
            ))}
            <div className={style.action_button}>
              <Button
                text={
                  isFengShuiSetting
                    ? SETTINGS_MANAGEMENT.FAQ.ADD_FAQ.BUTTONS.TITLE
                    : SETTINGS_MANAGEMENT.GET_HELP.ADD_GET_HELP.BUTTONS.TITLE
                }
                disabled={isSubmitDisabled}
                buttonType={
                  isSubmitDisabled
                    ? `${buttonStyle.button_disabled}`
                    : `${buttonStyle.button_primary}`
                }
              />
            </div>
          </form>
        </div>
      </div>
      {/* <Toaster
                toastList={toastList}
                position={"top-right"}
                autoDelete={true}
                autoDeleteTime={3500}
            /> */}
    </div>
  );
};

export default AddGetHelp;
