import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  listing: [],
  data: null,
};
const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    getReportsSuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;
    },
    addContentIdSuccess: (state, action) => {
      state.data = action.payload;
    },
    editContentIdSuccess: (state, action) => {
      state.data = action.payload;
    },
    deleteReportSuccess: (state, action) => {
      state.data = action.payload;
    },
    getReportCategoriesSuccess: (state, action) => {
      state.data = action.payload;
    },
    getReportsContentIdSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetReportDetail: (state, action) => {
      state.data = null;
    },
  },
});

export const {
  actions: reportsActions,
  reducer: reportsReducer,
  name: reports,
} = reportsSlice;
