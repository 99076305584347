import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    data: null,
};

const getHelpSlice = createSlice({
    name: "getHelp",
    initialState,
    reducers: {
        getHelpSuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        editHelpSuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        addHelpSuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        deleteHelpSuccess:  (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        viewHelpSuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        resetHelpData: (state, action) => {
            state = {
                listing: [],
                data: null,
            };
        },
    },
});

export const {
    actions: getHelpAction,
    reducer: getHelpReducer,
    name: getHelp,
} = getHelpSlice;