import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { languageState } from "../../../../redux/AurLaVieAdmin/languages/getLanguages.selector";
import {
  deleteReport,
  getReports,
} from "../../../../redux/AurLaVieAdmin/reports/reports.action";
import Back from "../../../../shared/components/backButton/backButton";
import Button from "../../../../shared/components/button/button";
import Delete from "../../../../shared/components/delete/delete";
import Heading from "../../../../shared/components/heading/heading";
import CenterModal from "../../../../shared/components/modal/centeredModal/centeredModal";
import SwitchButton from "../../../../shared/components/switchButton/switchButton";
import { REPORT_MANAGEMENT } from "../reportsManagementContants";
import style from "./viewContentId.module.scss";

const ViewContentId = () => {
  const navigate = useNavigate();
  const { type, id } = useParams();
  const [isTips, setIsTips] = useState(type === "property" ? false : true);
  const onCategoryClick = () => {
    navigate(`/fengshui/reportsManagement/viewContentId/property/${id}`);
    setIsTips(false);
  };
  const onTipsClick = () => {
    navigate(`/fengshui/reportsManagement/viewContentId/resident/${id}`);
    setIsTips(true);
  };
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const [toastList, setToastList] = useState([]);
  const languageData = useSelector(languageState);
  const [reports, setReports] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const currentDeleteRef = useRef(null);
  const [ids, setIds] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const params = {
      languageId: languageData?.languageId
        ? languageData?.languageId
        : localStorage.getItem("languageId"),
      reportType: isTips ? "resident" : "property",
      reportContentId: id,
    };
    getReport(axiosInstance, params);
  }, [axiosInstance, isTips]);

  const getReport = (axiosInstance, params) => {
    dispatch(getReports(axiosInstance, params))
      .then((response) => {
        let y = [...response?.listingData]?.sort(
          (a, b) => a.reportLabel.order - b.reportLabel.order
        );
        setReports(y);
      })
      .catch((error) => {
        console.log("err", error.response.data.message);
        setToastList([
          {
            id: 0,
            // title: "Error fetching Report",
            title: error.response.data.message,
            icon: "error",
          },
        ]);
      });
  };

  const onDelete = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteReport = (clicked) => {
    if (clicked === "Cancel") {
      setShowDeleteModal(false);
    } else {
      const data = { contentIds: [location.state.id] };
      dispatch(deleteReport(axiosInstance, data))
        .then((res) => {
          setIds(null);
          currentDeleteRef.current = null;
          setToastList([
            {
              id: 0,
              // title: "Report Deleted",
              title: "Report Deleted Successfully",
              icon: "success",
            },
          ]);
          setTimeout(() => {
            setShowDeleteModal(false);
            navigate("/fengshui/reportsManagement/");
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setToastList([
            {
              id: 0,
              // title: "Delete Report Error",
              title: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    }
  };

  const onEdit = (report) => {
    navigate(
      `/fengshui/reportsManagement/editContentId/${type}/${report?._id}`
    );
  };

  const goback = () => {
    navigate("/fengshui/reportsManagement/");
  };

  return (
    <div className={style.container}>
      <CenterModal
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        minWidth={"300px"}
      >
        <Delete
          title={REPORT_MANAGEMENT.DELETE_REPORT.TITLE}
          description={REPORT_MANAGEMENT.DELETE_REPORT.DESCRIPTION}
          onButtonClick={handleDeleteReport}
        />
      </CenterModal>
      <div className={style.wrapper}>
        <div className={style.back}>
          <Back
            redirect={goback}
            title={REPORT_MANAGEMENT.VIEW_REPORT_CONTENT_ID.BACK.TITLE}
            description={
              REPORT_MANAGEMENT.VIEW_REPORT_CONTENT_ID.BACK.DESCRIPTION
            }
          />
        </div>
        <div className={style.switch}>
          <SwitchButton
            FirstIcon={CottageOutlinedIcon}
            SecondIcon={ApartmentOutlinedIcon}
            firstText="Property Report"
            secondText="Resident Report"
            onCategoryClick={onCategoryClick}
            onTipsClick={onTipsClick}
            isTips={isTips}
          />
        </div>
        <div className={style.heading}>
          <Heading
            fontStyle={`italic`}
            title={`${id.substring(0, 19)}..`}
            description={
              REPORT_MANAGEMENT.VIEW_REPORT_CONTENT_ID.HEADING.DESCRIPTION
            }
            subtitle={" ( Content Id )"}
          />
          <div className={style.action_buttons}>
            <Button
              text={
                REPORT_MANAGEMENT.VIEW_REPORT_CONTENT_ID.BUTTONS.DELETE_TITLE
              }
              icon={"/icons/delete.svg"}
              onClick={onDelete}
            />
          </div>
        </div>
        {reports?.length > 0 &&
          reports.map((report, index) => {
            return (
              <div key={index} className={style.content}>
                <div className={style.content_wrapper}>
                  <div className={style.img_container}>
                    <img
                      src={
                        report?.imagePath
                          ? report?.imagePath
                          : "/icons/upload.png"
                      }
                      alt="Main Image"
                    />
                  </div>
                  <div className={style.details}>
                    <div className={style.report}>
                      <h3 className={style.name}>{report?.reportName}</h3>
                      <p
                        className={style.description}
                        dangerouslySetInnerHTML={{
                          __html: report?.description,
                        }}
                      ></p>
                    </div>
                    <Button
                      icon={"/icons/edit.svg"}
                      onClick={() => onEdit(report)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={3500}
      />
    </div>
  );
};

export default ViewContentId;
