import { useEffect, useState } from "react";
import Back from "../../../../shared/components/backButton/backButton";
import Button from "../../../../shared/components/button/button";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { useDispatch, useSelector } from "react-redux";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import Input from "../../../../shared/components/inputFields/input";
import { NOTIFICATION_MANAGEMENT } from "../notification.constant";
import {
  viewNotification,
  updateNotification,
} from "../../../../redux/AurLaVieAdmin/manageNotifications/manageNotification.actions";
import style from "../addNotification/addNotification.module.scss";
import buttonStyle from "../../../../shared/components/button/button.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
import { languageState } from "../../../../redux/AurLaVieAdmin/languages/getLanguages.selector";

const AddNotification = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [toastList, setToastList] = useState([]);
  const languageData = useSelector(languageState)
  const state = useSelector(state => state)
  let inputFields = [
    {
      type: "text",
      name: "title",
      label: "Notification Title",
      width: "100%",
    },

    {
      type: "textarea",
      name: "description",
      label: "Content",
      width: "100%",
    },

    {
      type: "mui-date-picker",
      name: "scheduleDate",
      label: "Triggered Date",
      width: "100%",
    },
  ]

  const routeSplit = location?.pathname.split("/");
  const urlToken = routeSplit[routeSplit.length - 1];

  const isFengShuiSetting = location.pathname === NOTIFICATION_MANAGEMENT.URL_PATH.EDIT_NOTIFICATION.replace(/:id/g, urlToken)
  const appId = localStorage.getItem("appId")
  const languageId = languageData?.languageId

  console.log(isFengShuiSetting, appId, languageId)


  useEffect(() => {
    handleUpdate(urlToken);
  }, [axiosInstance]);

  const handleUpdate = (id) => {
    dispatch(
      viewNotification(axiosInstance, {
        id,
      })
    )
      .then((res) => {
        const response = {
          title: res.title,
          description: res.description,
          scheduleStatus: res.scheduleStatus,
        };

        if (res.scheduleStatus) {
          response["scheduleDate"] = dayjs(res.scheduleDate)
        }

        setFormData(response);
      })
      .catch((err) => {
        console.log(err);
        setToastList([
          {
            id: 0,
            // title: "Fetch Notification Error",
            title: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const handleInputChange = (fieldName, fieldValue) => {
    setFormData({ ...formData, [fieldName]: fieldValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const payload = {
      appId, languageId,
      description: formData.description
    }

    if (formData.scheduleDate) {
      payload.scheduleDate = dayjs(formData.scheduleDate).toISOString();
    }

    if (formData.title) {
      payload.title = formData.title
    }

    if (formData.description) {
      payload.description = formData.description
    }

    dispatch(
      updateNotification(axiosInstance, payload, {
        id: urlToken,
      })
    )
      .then((response) => {
        setToastList([
          {
            id: 0,
            // title: "Notification Updated",
            title: response.message,
            icon: "success",
          },
        ]);

        setTimeout(() => {
          isFengShuiSetting ? navigate(NOTIFICATION_MANAGEMENT.URL_PATH.FENG_SHUI) : navigate("/aurlavie/notifications")
        }, 1000);
      })
      .catch((error) => {
        console.log("err", error.response.data.message);
        setToastList([
          {
            id: 0,
            // title: "Error updating notification",
            title: error.response.data.message,
            icon: "error",
          },
        ]);
      });
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className={style.back}>
          <Back
            title={NOTIFICATION_MANAGEMENT.UPDATE_NOTIFICATION.BACK.TITLE}
            description={
              NOTIFICATION_MANAGEMENT.UPDATE_NOTIFICATION.BACK.DESCRIPTION
            }
          />
        </div>
        <div className={style.form_container}>
          <form onSubmit={(event) => handleSubmit(event)}>
            <div className={style.form_container}>
              {inputFields.map((inputField, index) =>
                inputField.name === "scheduleDate" &&
                  !formData["scheduleStatus"] ? null : (
                  <div key={index} style={{ width: "100%" }}>
                    <Input
                      disablePast={true}
                      name={inputField.name}
                      type={inputField.type}
                      label={inputField.label}
                      value={formData[inputField.name]}
                      placeholder={inputField.placeholder}
                      options={inputField.options}
                      onChange={(value) =>
                        handleInputChange(inputField.name, value)
                      }
                      views={["day", "hours", "minutes"]}
                      openTo={"day"}
                    />
                  </div>
                )
              )}
              <div className={style.action_button}>
                <Button
                  text={
                    NOTIFICATION_MANAGEMENT.UPDATE_NOTIFICATION.BUTTONS.TITLE
                  }
                  buttonType={`${buttonStyle.button_primary}`}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </div>
  );
};

export default AddNotification;
