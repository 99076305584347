import React from "react";
import style from "./landing.module.scss"

const LandingScreen = () => {

    return (
        <section className={style.container}>
            <div className={style.wrapper}>
                <h1 style={{ textAlign: 'center' }}>Welcome, {localStorage.getItem("userName")}</h1>
            </div>
        </section>
    );
};

export default LandingScreen;
