import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Toaster from "../../components/Global/Toaster/Toaster.component";

import InputEvent from "../../components/Global/inputs/input.component";
import AlertDialog from "../ForgetPassword/forgetPassword.page";

import useAuth from "../../hooks/useAuth.hook";
import { login } from "../../redux/users/user.actions";
import "./Login.styles.scss";
import { validate } from "./loginValidation";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Heading from "../../shared/components/heading/heading";
import CenterModal from "../../shared/components/modal/centeredModal/centeredModal";

import style from "./login.module.scss";
import ForgetDialogue from "../ForgetPassword/forgetPassword.page";
import { getLanguages } from "../../redux/AurLaVieAdmin/languages/getLanguages.action";
import { axiosInstance } from "../../constants/axiosInstance";

const theme = createTheme();

const LoginPage = () => {
  const dispatch = useDispatch();
  // const user = useSelector(selectUserData);
  const { auth, createUserAuth } = useAuth();
  const navigateTo = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [rememberMe, setRememberMe] = useState(false);
  const [confirmDialog, setConfirmDiaogue] = useState({
    openAlert: false,
    alertMessage: "",
  });
  const [confirmAction, setConfirmAction] = useState(false);
  const [languageId, setLanguageId] = useState(null);
  // const [setShowForgotPasswordModel] = useState(false);

  const handleLogin = () => {
    dispatch(login({ email, password }, { isAdmin: true }))
      .then((res) => {
        createUserAuth(
          res?.data?.token,
          res?.data?.refreshToken,
          res?.data?.data?._id,
          res?.data?.data?.appId
        );
        localStorage.setItem("languageId", languageId);
        localStorage.setItem("appId", "641bdc142b1809d1b4c83977");
        localStorage.setItem("userName", res?.data?.data?.name);
        localStorage.setItem("userPic", res?.data?.data?.profilePic);
        setToastList([
          {
            id: 0,
            // title: "Login Success",
            title: "User has been logged in.",
            icon: "success",
          },
        ]);

        setTimeout(() => {
          return navigateTo("/home", { state: { navigate: true } });
        }, 1500);
      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            // title: "Login Error",
            title: err?.response?.data?.message || "Login Error",
            icon: "error",
          },
        ]);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(
      validate({ email: email, rememberMe: rememberMe, password: password })
    );
    setIsSubmit(true);
  };

  useEffect(() => {
    return () => {
      clearTimeout();
    };
  }, []);

  useEffect(() => {
    if (axiosInstance) {
      handleLanguage(axiosInstance, {
        languageCode: "en-gb",
      });
    }
  }, [axiosInstance]);

  const handleLanguage = (axiosInstance, params) => {
    dispatch(getLanguages(axiosInstance, params))
      .then((response) => {
        setLanguageId(response._id);
      })
      .catch((err) => {
        return true;
      });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      handleLogin();
    }
  }, [formErrors]);

  useEffect(() => {
    localStorage.setItem("keepMeLoggedIn", rememberMe);
  }, [rememberMe]);

  const onVisibleClick = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className={style.container}>
      {/* <CenterModal isOpen={showForgotPasswordModel} onClose={() => setShowForgotPasswordModel(false)}>
        <ForgetDialogue/>
      </CenterModal> */}
      <div className={style.wrapper}>
        <div className={style.left}>
          <div className={style.content}>
            <p className={style.top_text}>Mauris varius</p>
            <p className={style.middle_text}>
              Aenean Varius Pulvinar Dui Mattis Aoreet.
            </p>
            <p className={style.bottom_text}>
              Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
              posuere cubilia curae; Cras vel arcu non magna porta volutpat. Sed
              lacus ligula, ultricies venenatis tellus eget, ultrices blandit
              turpis.
            </p>
          </div>
        </div>
        <div className={style.right}>
          <div className={style.heading}>
            <Heading
              title="Welcome back"
              description=""
            />
          </div>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ my: "20px", mx: "auto", width: "80%" }}
          >
            <InputEvent
              placeholder={"Email Address"}
              text="Registered Email Id"
              required={true}
              value={email}
              setValue={setEmail}
            />
            {formErrors?.email && (
              <>
                <p style={{ color: "red" }}>{formErrors?.email}</p>
              </>
            )}
            <InputEvent
              placeholder={"Password"}
              text="Password"
              required={true}
              maxLength={16}
              type={passwordVisible ? "text" : "password"}
              value={password}
              setValue={setPassword}
              icon={
                passwordVisible
                  ? "/icons/visible-eye.png"
                  : "/icons/not-visible-eye.png"
              }
              iconFunc={onVisibleClick}
            />
            {formErrors?.password && (
              <>
                <p style={{ color: "red" }}>{formErrors?.password}</p>
              </>
            )}
            <div className="forget-password-container">
              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    onClick={() => setRememberMe(!rememberMe)}
                    sx={{
                      backgroundColor: "#FFFFFF",
                      opacity: !rememberMe ? 0.6 : 1,
                    }}
                  />
                }
                label={<p className="remember-me-text">Remember Password</p>}
              />
              <p
                onClick={
                  () =>
                    setConfirmDiaogue({
                      openAlert: true,
                      alertMessage: "Forgot Password",
                    })
                  // setShowForgotPasswordModel(true)
                }
                className="forget-password-text"
              >
                Forgot Password?
              </p>
            </div>
            <Button
              style={{ background: "#0E4D92" }}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              LOGIN
            </Button>
          </Box>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
      <AlertDialog
        openAlert={confirmDialog?.openAlert}
        alertMessage={confirmDialog.alertMessage}
        setConfirmDiaogue={setConfirmDiaogue}
        setConfirmAction={setConfirmAction}
      />
    </div>
    // <ThemeProvider theme={theme}>

    //   <Grid container component="main" sx={{ height: "100vh" }}>
    //     <CssBaseline />
    //     <Grid
    //       className="login-left-text-container"
    //       item
    //       xs={false}
    //       sm={4}
    //       md={7}
    //       sx={{
    //         backgroundColor: "#0E4D92",
    //         backgroundSize: "cover",
    //         backgroundPosition: "center",
    //       }}
    //     >
    //       <div className="login-left-text-div">
    //         <div className="content">
    //           <p className="top-text-p">Mauris varius</p>
    //           <p className="middle-text-p">
    //             Aenean Varius Pulvinar Dui Mattis Aoreet.
    //           </p>
    //           <p className="bottom-text-p">
    //             Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
    //             posuere cubilia curae; Cras vel arcu non magna porta volutpat. Sed
    //             lacus ligula, ultricies venenatis tellus eget, ultrices blandit
    //             turpis.
    //           </p>
    //         </div>
    //       </div>
    //     </Grid>
    //     <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
    //       <Box
    //         sx={{
    //           // my: 8,
    //           // mx: 4,
    //           height: '100%',
    //           display: "flex",
    //           flexDirection: "column",
    //           alignItems: "center",
    //           justifyContent: 'center'
    //         }}
    //       >
    //         <Box
    //           component="form"
    //           noValidate
    //           onSubmit={handleSubmit}
    //           sx={{ mt: 1, width: "90%" }}
    //         >
    //           <Heading title="Welcome back" description="Pellentesque at ipsum ac felis suscipit sollicitudin nec eu ex."/>
    //           <InputEvent
    //             // placeholder={"Email Address"}
    //             text="Registered Email Id"
    //             required={true}
    //             value={email}
    //             setValue={setEmail}
    //           />
    //           {formErrors?.email && (
    //             <>
    //               <p style={{ color: "red" }}>{formErrors?.email}</p>
    //             </>
    //           )}
    //           <InputEvent
    //             text="Password"
    //             required={true}
    //             maxLength={16}
    //             type={passwordVisible ? "text" : "password"}
    //             value={password}
    //             setValue={setPassword}
    //             icon={
    //               passwordVisible
    //                 ? "/icons/visible-eye.png"
    //                 : "/icons/not-visible-eye.png"
    //             }
    //             iconFunc={onVisibleClick}
    //           />
    //           {formErrors?.password && (
    //             <>
    //               <p style={{ color: "red" }}>{formErrors?.password}</p>
    //             </>
    //           )}
    //           <div className="forget-password-container">
    //             <FormControlLabel
    //               control={
    //                 <Checkbox
    //                   value="remember"
    //                   onClick={() => setRememberMe(!rememberMe)}
    //                   sx={{
    //                     backgroundColor: "#FFFFFF",
    //                     opacity: !rememberMe ? 0.6 : 1,
    //                   }}
    //                 />
    //               }
    //               label={<p className="remember-me-text">Remember Password</p>}
    //             />
    //             <p
    //               onClick={() =>
    //                 setConfirmDiaogue({
    //                   openAlert: true,
    //                   alertMessage: "Forgot Password",
    //                 })
    //               }
    //               className="forget-password-text"
    //             >
    //               Forget Password?
    //             </p>
    //           </div>
    //           <Button
    //             style={{ background: "#0E4D92" }}
    //             type="submit"
    //             fullWidth
    //             variant="contained"
    //             sx={{ mt: 3, mb: 2 }}
    //           >
    //             LOGIN
    //           </Button>
    //         </Box>
    //       </Box>
    //     </Grid>
    //     <Toaster
    //       toastList={toastList}
    //       position={"top-right"}
    //       autoDelete={true}
    //       autoDeleteTime={2500}
    //     />
    //     <AlertDialog
    //       openAlert={confirmDialog?.openAlert}
    //       alertMessage={confirmDialog.alertMessage}
    //       setConfirmDiaogue={setConfirmDiaogue}
    //       setConfirmAction={setConfirmAction}
    //     />
    //   </Grid>
    // </ThemeProvider>
  );
};

export default LoginPage;
