import React, { useEffect, useState } from "react";
import style from "./imageUpload.module.scss";
import Toaster from "../../../components/Global/Toaster/Toaster.component";
import AWS from "aws-sdk";
import { isLoading } from "../../../redux/progressLoader/progressLoader.actions";
import { useDispatch } from "react-redux";

const ImageUpload = (props) => {
  const { title } = props;
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [previewImage, setPreviewImage] = useState();
  const dispatch = useDispatch();
  const [toastList, setToastList] = useState([]);



  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
    region: process.env.REACT_APP_AWS_REGION,
  });

  useEffect(() => {
    if (props.select) {
      setPreviewImage(props.previewImage);
    }

  }, [props.select])

  useEffect(() => {
    setPreviewImage(props.previewImage);
  }, [props.previewImage]);

  useEffect(() => {
    if (props.handleFile) {
      props.handleFile(selectedImage);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (props.removeImage) {
      handleRemoveImage();
    }
  }, [props.removeImage]);

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    // You can add validation here to check for valid image file types, size, etc.
    if (!imageFile.type.includes("image")) {
      setToastList([
        {
          id: 0,
          // title: "Failed to Upload Image",
          title: "Please upload only Images",
          icon: "error",
        },
      ]);
      return;
    }
    setSelectedImage(imageFile);
    setSelectedImageURL(URL.createObjectURL(imageFile));
    event.target.value = "";
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    const imageFile = event.dataTransfer.files[0];
    // You can add validation here to check for valid image file types, size, etc.
    if (!imageFile.type.includes("image")) {
      setToastList([
        {
          id: 0,
          // title: "Failed to Upload Image",
          title: "Please upload only Images",
          icon: "error",
        },
      ]);
      return;
    }
    setSelectedImage(imageFile);
    setSelectedImageURL(URL.createObjectURL(imageFile));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setSelectedImageURL(null);
    setPreviewImage(null);
    if (props.handleFile) {
      props.handleFile(" ");
    }
  };

  const uploadToS3 = async (file, name) => {
    const fileKey = `profile-${name}.${file?.type.split("/")[1]}`;
    const params = {
      Body: file,
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: fileKey,
    };
    try {
      const upload = await myBucket.upload(params).promise();
      if (upload) {
        dispatch(isLoading(false));
        // getUserType(axiosInstance, appId, upload.Location);
      }
    } catch (error) {
      console.log(error);
      setToastList([
        {
          id: 0,
          // title: "Error",
          title: "Error while uploading the resume.",
          icon: "error",
        },
      ]);
      dispatch(isLoading(false));
    }
  };

  return (
    <div className={style.container}>
      <h3 className={style.title}>{title}</h3>
      <div
        className={style.upload_container}
        style={{
          border: dragging ? "2px solid #0E4D92" : "2px solid #DEDEDE",
          height: props.height,
          width: props.width,
          backgroundSize: props.backgroundSize,
          padding: props.padding,
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <input
          disabled={props.isDisabled}
          type="file"
          accept=".jpg,.jpeg,.png"
          onChange={handleImageChange}
          style={{ display: "none" }}
          id={props.id ? props.id : "upload"}
        />
        <label
          htmlFor={props.id ? props.id : "upload"}
          className={style.label}
        ></label>
        {(selectedImageURL || previewImage) && (
          <div
            className={style.image_preview}
            style={{ padding: props.padding }}
          >
            <img
              src={previewImage ? previewImage : selectedImageURL}
              alt="Selected"
              style={{
                maxWidth: "100%",
                height: "100%",
                objectFit: "cover",
                width: "100%",
              }}
            />
            {!props.isDisabled ?
              <img
                src="/icons/delete.svg"
                alt="delete"
                className={style.delete_icon}
                style={{ height: "30px", width: "30px" }}
                onClick={handleRemoveImage}
              /> : null
            }

          </div>
        )}
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </div>
  );
};

export default ImageUpload;
