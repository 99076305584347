import React from 'react'
import style from './TitleAndDetail.module.scss'

const TitleAndDetail = ({title, detail, detail_style }) => {
  return (
        <div className={style.Container}>
            <span className={style.Container_title}>{title}</span>
            <span className={style.Container_deatil} style={{ ...detail_style }}>{detail}</span>
        </div>
  )
}

export default TitleAndDetail