export const SETTINGS_MANAGEMENT = {
  HEADING: {
    TITLE: "Settings",
    DESCRIPTION: "Content Management page list",
  },
  TERMS_OF_SERVICES: {
    HEADING: {
      TITLE: "Terms of Services",
      DESCRIPTION: "",
    },
    BUTTONS: {
      TITLE: "Edit Terms of Services",
      SAVE_TITLE: "Save Changes",
    },
  },
  TERMS_AND_CONDITIONS: {
    HEADING: {
      TITLE: "Terms And Conditions",
      DESCRIPTION: "Pellentesque at ipsum ac felis suscipit sollicitudin nec eu ex.",
    },
    BUTTONS: {
      TITLE: "Edit Terms & Conditions",
      SAVE_TITLE: "Save Changes",
    },
  },
  ABOUT: {
    HEADING: {
      TITLE: "About",
      DESCRIPTION: "",
    },
    BUTTONS: {
      TITLE: "Edit How Aursome Works",
      SAVE_TITLE: "Save Changes",
    },
  },
  AURSOME: {
    HEADING: {
      TITLE: "How Aursome Works",
      DESCRIPTION: "Pellentesque at ipsum ac felis suscipit sollicitudin nec eu ex.",
    },
    BUTTONS: {
      TITLE: "Edit How Aursome Works",
      SAVE_TITLE: "Save Changes",
    },
  },
  PRIVACY_POLICY: {
    HEADING: {
      TITLE: "Privacy Policy",
      DESCRIPTION: "Pellentesque at ipsum ac felis suscipit sollicitudin nec eu ex.",
    },
    BUTTONS: {
      TITLE: "Edit Privacy Policy",
      SAVE_TITLE: "Save Changes",
    },
  },
  GET_HELP: {
    HEADING: {
      TITLE: "Get Help",
      DESCRIPTION: "Pellentesque at ipsum ac felis suscipit sollicitudin nec eu ex.",
    },
    BUTTONS: {
      TITLE: "Add Get Help",
      EDIT_TITLE: "Edit Get Help",
      DELETE_TITLE: "Delete Get Help",
      SAVE_TITLE: "Save Changes",
    },
    FILTERS: {
      SEARCH: {
        PLACEHOLDER: "Search Get Help"
      }
    },
    ADD_GET_HELP: {
      HEADING: {
        TITLE: "Add Help",
        DESCRIPTION: "",
      },
      BUTTONS: {
        TITLE: "Add Help",
      }
    },
    EDIT_GET_HELP: {
      HEADING: {
        TITLE: "Get Help",
        DESCRIPTION: "",
      },
      BUTTONS: {
        SAVE_TITLE: "Save Changes",
      }
    }
  },
  FAQ: {
    HEADING: {
      TITLE: "FAQs",
      DESCRIPTION: "Pellentesque at ipsum ac felis suscipit sollicitudin nec eu ex.",
    },
    BUTTONS: {
      TITLE: "Add FAQ",
      EDIT_TITLE: "Edit FAQ",
      DELETE_TITLE: "Delete FAQ",
      SAVE_TITLE: "Save Changes",
    },
    FILTERS: {
      SEARCH: {
        PLACEHOLDER: "Search FAQ"
      }
    },
    ADD_FAQ: {
      HEADING: {
        TITLE: "Add FAQ",
        DESCRIPTION: "",
      },
      BUTTONS: {
        TITLE: "Add FAQ",
      }
    },
    EDIT_FAQ: {
      HEADING: {
        TITLE: "Edit FAQ",
        DESCRIPTION: "",
      },
      BUTTONS: {
        SAVE_TITLE: "Save Changes",
      }
    }
  },
  URL_PATH: {
    FENG_SHUI: '/fengshui/settings'
  }
};
