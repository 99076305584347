import Button from '../button/button';
import style from './betaConfirmation.module.scss';
import buttonStyle from '../button/button.module.scss';

const BetaConfirmation = ({ title, description, description2, onButtonClick}) => {

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <h2 className={style.title}>{title}</h2>
                <p className={style.description}>{description}</p>
                <p className={style.description}>{description2}</p>
                <div className={style.action_buttons}>
                    <h3 className={style.box_title}>Proceed To Add</h3>
                    <h3 className={style.box_title}>Property & Resident</h3>
                    <p className={style.box_description}>Pellentesque at ipsum ac felis nec eu ex.</p>
                    <Button buttonTextStyle={{ fontWeight: 800 }} text={'OK'}  buttonType={`${buttonStyle.button_primary}`} marginTop={0} onClick={() => onButtonClick('Ok')}/>
                </div>
            </div>
        </div>
    )
}

export default BetaConfirmation;