import React, { useEffect, useState } from 'react'
import style from './EditCategory.module.scss'
import Back from '../../../../shared/components/backButton/backButton'
import ImageUpload from '../../../../shared/components/imageUpload/imageUpload'
import Input from '../../../../shared/components/inputFields/input'
import { CATEGORY } from '../category/categoryManagementConstant'
import Button from '../../../../shared/components/button/button'
import buttonStyle from '../../../../shared/components/button/button.module.scss'
import { Status } from '../../../../shared/globalConstants/constants'
import useAxiosInstance from '../../../../hooks/useAxiosInstance'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Toaster from '../../../../components/Global/Toaster/Toaster.component'
import { editAdminCategory } from '../../../../redux/FengShui/adminCategory/adminCategory.actions'
import { isLoading } from '../../../../redux/progressLoader/progressLoader.actions'
import AWS from "aws-sdk"
import { viewAdminCategory } from '../../../../redux/FengShui/adminCategory/adminCategory.actions'
import { generateUUID } from '../../../../hooks/uniqueIdGenerator'

const EditCategory = () => {
  const [formData, setFormData] = useState({})
  const [toastList, setToastList] = useState([])
  const axiosInstance = useAxiosInstance()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [mainImage, setMainImage] = useState("")
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const queryParams = {
      isAdmin: true,
    }
    editCategoryDetail(axiosInstance, queryParams)
  }, [])

  const editCategoryDetail = (axiosInstance, params) => {
    dispatch(viewAdminCategory(axiosInstance, id, params)).then((response) => {
      const newFormData = {
        "Category Name": response?.categoryName,
        "Category Sub Heading": response?.subHeading,
        "status": response?.status,
        "imgPath": response?.imgPath
      }
      setFormData({ ...formData, ...newFormData })
    }).catch((error) => {
      setToastList([
        {
          id: 0,
          title: error.response.data.message,
          icon: "error",
        },
      ]);
    });
  }

  const inputFields = [
    {
      type: "text",
      name: "Category Name",
      label: 'Category Name',
      required: false,
      width: '100%'
    },
    {
      type: "text",
      name: "Category Sub Heading",
      label: 'Category Sub Heading',
      required: false,
      width: '100%'
    },
    {
      type: "radio",
      name: "status",
      label: 'Status',
      options: Status,
      width: '50%'
    },
  ]

  const handleInputChange = (fieldName, fieldValue) => {
    setFormData({ ...formData, [fieldName]: fieldValue });
  }

  const handleFile = (url) => {
    if (url || url === "") {
        setMainImage(url);
    }
}

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  })

  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
    region: process.env.REACT_APP_AWS_REGION,
  })

  const handleUpdate = (imageUrl) => {
    // const appId = localStorage.getItem("appId") // Getting Undefine
    const userId = state?.user?.data?._id

    const categoryDetails = {
      "languageId": state?.getLanguages?.languageId,
      "appId": localStorage.getItem("appId"),
      "categoryName": formData['Category Name'],
      "subHeading": formData['Category Sub Heading'],
      "status": formData?.status,
      "modified_by": userId,
      "parentId": null,
    }
    if (imageUrl && !imageUrl?.includes('undefined')) {
      categoryDetails['imgPath'] = imageUrl;
    } else {
      categoryDetails['imgPath'] = mainImage || formData.imgPath;
    }
    dispatch(editAdminCategory(axiosInstance, categoryDetails, id, {}))
      .then((res) => {
        setToastList([
          {
            id: 0,
            title: res.message,
            icon: "success",
          },
        ])
        setTimeout(() => {
          navigate(-1)
        }, 1500)
        const newFormData = {
          "Category Name": res?.data?.categoryName,
          "Category Sub Heading": res?.data?.subHeading,
          "status": res?.data?.status,
          "imgPath": res?.data?.imgPath
        }
        setFormData({ ...formData, ...newFormData })
      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            title: err?.response?.data?.message,
            icon: "error",
          },
        ])
      })
  }

  const uploadToS3 = async (file, name) => {
    const id = generateUUID();
    const fileKey = `tip-main-${id}.${file?.type.split("/")[1]}`;
    const params = {
      Body: file,
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: fileKey,
    }
    try {
      dispatch(isLoading(true))
      const upload = await myBucket.upload(params).promise()
      if (upload) {
        dispatch(isLoading(false))
        handleUpdate(upload.Location)
      }
    } catch (error) {
      setToastList([
        {
          id: 0,
          title: "Error while uploading the Category image.",
          icon: "error",
        },
      ]);
      dispatch(isLoading(false));
    }
  }

  const onUpdate = (e) => {
    e.preventDefault()
    if (mainImage?.name) {
      uploadToS3(mainImage, mainImage.name)
    } else {
      handleUpdate()
    }
  }

  useEffect(() => {
    if(formData['Category Name'] !== ''
        && formData['Category Name'] !== undefined
        && formData["Category Sub Heading"] !== ''
        && formData["Category Sub Heading"] !== undefined
        && formData["status"] !== ''
        && formData["status"] !== undefined) {
      setIsSubmitDisabled(false)
    } else {
      setIsSubmitDisabled(true)
    }
  },[formData])

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className={style.back}>
          <Back title={CATEGORY.EDIT_CATEGORY.BACK.TITLE} description={CATEGORY.EDIT_CATEGORY.BACK.DESCRIPTION} />
        </div>
        <div className={style.form_container} style={{ marginTop: '2rem' }}>
          <div className={style.upload}>
            <ImageUpload handleFile={handleFile} title={CATEGORY.EDIT_CATEGORY.UPLOAD.TITLE} previewImage={formData?.imgPath !== "" && formData?.imgPath} />
          </div>
          <form onSubmit={onUpdate}>
            <div className={style.form_container}>
              {inputFields.map((inputField, index) => (
                <div key={index} className={style.input_field} style={{ width: inputField.width }}>
                  <Input
                    type={inputField.type}
                    name={inputField.name}
                    label={inputField.label}
                    value={formData[inputField?.name]}
                    placeholder={inputField.placeholder}
                    options={inputField.options}
                    required={inputField.required}
                    onChange={(value) => handleInputChange(inputField?.name, value)}
                    handleInputBlur={() => { }}
                  />
                  {/* <div className={style.error}>{formErrors[inputField.name]}</div> */}
                </div>
              ))}
              <div className={style.action_button}>
                <Button disabled={isSubmitDisabled} text={CATEGORY.EDIT_CATEGORY.BUTTONS.TITLE} buttonType={isSubmitDisabled ? `${buttonStyle.button_disabled}` : `${buttonStyle.button_primary}`} />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </div>
  )
}

export default EditCategory