import Button from '../button/button';
import style from './betaAddProperty.module.scss';
import buttonStyle from '../button/button.module.scss';
import Input from '../inputFields/input';
import { useDispatch, useSelector } from 'react-redux';
import { selectAdminCategoryListingData } from '../../../redux/FengShui/adminCategory/adminCategory.selectors';
import { useEffect, useState } from 'react';
import { betaUserAddProperty, betaUserEditProperty, getAdminPropertyType } from '../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import { Country, State, City }  from 'country-state-city';
import { languageState } from '../../../redux/AurLaVieAdmin/languages/getLanguages.selector';
import ImageUpload from '../imageUpload/imageUpload';
import { generateUUID } from '../../../hooks/uniqueIdGenerator';
import { isLoading } from '../../../redux/progressLoader/progressLoader.actions';
import AWS from "aws-sdk";

const BetaAddProperty = ({ title, description, userId, setBetaProperty, setShowConfirmation, setBetaResident,
    onviewPage, setShow, setToastList, singleData, onEditOpen, refresh }) => {
    const [formData, setFormData] = useState({})
    const dispatch = useDispatch()
    const axiosInstance = useAxiosInstance()
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
    const [country, setCountry] = useState([{ value: '', label: 'Select Country' }])
    const [state, setState] = useState([{ value: '', label: 'Select State' }])
    const [city, setCity] = useState([{ value: '', label: 'Select City' }])
    const [propertyType, setPropertyType] = useState([{ value: '', label: 'Select Property Type'}])
    const languageData = useSelector(languageState);
    const [removeImage, setRemoveImage] = useState(false);
    const [mainImage, setMainImage] = useState("");

    useEffect(() => {
        if(languageData?.languageId) {
            const params = {
                languageId: languageData?.languageId
            }
             dispatch(getAdminPropertyType(axiosInstance, {}, params)).then((response) => {
                const data = response?.data?.data?.listingData?.map(value => {
                    return {
                        value: value?._id,
                        label: value?.typeName
                    }
                })
                setPropertyType([{ value: '', label: 'Select Property Type'}, ...data])
            }).catch((error) => {
                setToastList([
                    {
                        id: 0,
                        // title: "Error fetching Property Type",
                        title: error.response.data.message,
                        icon: "error",
                    },
                ]);
            });
        }
    }, [languageData?.languageId])

    useEffect(() => {
        if(singleData && propertyType && onEditOpen) {
            const data = {
                "Property Name": singleData.propName,
                "Property Type": {value: singleData?.propTypeId?._id || '', label: propertyType.find(item => item.value === singleData?.propTypeId?._id)?.label},
                "Street Name": singleData.streetName,
                "Street Number": singleData.streetNumber,
                "Country": {value: singleData?.countryId || '', label: Country?.getCountryByCode(singleData?.countryId)?.name || 'Select Country'},
                "State": {value: singleData?.stateId || '', label: State?.getStateByCodeAndCountry(singleData?.stateId, singleData?.countryId)?.name || 'Select State'},
                "City": {value: singleData?.cityId || '', label: singleData?.cityId || 'Select City'},
                "Zip/Postal Code": singleData?.zipCode,
                "imgPath": singleData?.imgPath
            }
            setFormData(data)
        }
    }, [singleData, propertyType, onEditOpen])

    useEffect(() => {
        const countryData = Country.getAllCountries()?.map(value => ({
                value: value.isoCode,
                label: value.name
            })
        )
        const stateData = State.getStatesOfCountry(formData?.Country?.value)?.map(value => ({
                value: value.isoCode,
                label: value.name
            })
        )
        const cityData = City.getCitiesOfState(formData?.Country?.value, formData?.State?.value)?.map(value => ({
                value: value.name,
                label: value.name
            })
        )
        setCountry([{ value: '', label: 'Select Country' }, ...countryData])
        setState([{ value: '', label: 'Select State' }, ...stateData])
        setCity([{ value: '', label: 'Select City' }, ...cityData])
    }, [formData])

    const inputFields = [
        {
            type: "text",
            name: "Property Name",
            placeholder: 'Property Name',
            label: 'Property Name',
            required: true,
            width: '50%'
        },
        {
            type: "dropdown",
            name: "Property Type",
            options: propertyType,
            placeholder: 'Select Property Type',
            label: 'Property Type',
            required: true,
            width: '50%'
        },
        {
            type: "text",
            name: "Street Name",
            placeholder: 'Street Name',
            label: 'Street Name',
            required: true,
            width: '50%'
        },
        {
            type: "number",
            name: "Street Number",
            placeholder: 'Street Number',
            label: 'Street Number',
            required: true,
            width: '50%'
        },
        {
            type: "dropdown",
            name: "Country",
            options: country,
            placeholder: 'Select Country',
            label: 'Country',
            required: true,
            width: '50%'
        },
        {
            type: "dropdown",
            name: "State",
            options: state,
            placeholder: 'Select State',
            label: 'State',
            required: state.length >= 2,
            width: '50%'
        },
        {
            type: "dropdown",
            name: "City",
            options: city,
            placeholder: 'Select City',
            required: city.length >=2,
            label: 'City',
            width: '50%'
        },
        {
            type: "text",
            name: "Zip/Postal Code",
            placeholder: 'Zip/Postal Code',
            label: 'Zip/Postal Code',
            required: true,
            width: '50%'
        },
    ];

    const handleInputChange = (fieldName, fieldValue) => {
        if (fieldName === "Country") {
            if(fieldValue?.value !== formData[fieldName]?.value) {
                setFormData({ ...formData, [fieldName]: fieldValue, "City": { value: '', label: 'Select City' }, "State": { value: '', label: 'Select State' } })
                return;
            }
        }
        if (fieldName === "State") {
            if(fieldValue?.value !== formData[fieldName]?.value) {
                setFormData({ ...formData, [fieldName]: fieldValue, "City": { value: '', label: 'Select City' } })
                return;
            }
        }
        setFormData({ ...formData, [fieldName]: fieldValue })
    }

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      });
    
      const myBucket = new AWS.S3({
        params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
        region: process.env.REACT_APP_AWS_REGION,
      });

    const uploadToS3 = async (file, name) => {
        const id = generateUUID();
        const fileKey = `user-resident-${id}.${file?.type.split("/")[1]}`;
        const params = {
          Body: file,
          Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
          Key: fileKey,
        };
        try {
          dispatch(isLoading(true));
          const upload = await myBucket.upload(params).promise();
          if (upload) {
            dispatch(isLoading(false));
            handleAdd(upload.Location);
          }
        } catch (error) {
          setToastList([
            {
              id: 0,
              // title: "Error",
              title: "Error while uploading the Resident image.",
              icon: "error",
            },
          ]);
          dispatch(isLoading(false));
        }
      };

    const onButtonClick = (e, clickedButton) => {
        e.preventDefault()
        if (clickedButton === 'Ok') {
            if (mainImage?.name) {
                uploadToS3(mainImage, mainImage.name);
            } else {
                handleAdd();
            }
        }
    }

    const handleAdd = (imageUrl) => {
        const data = {
            // languageId: languageData?.languageId,
            "appId": localStorage.getItem("appId"),
            "userId": userId,
            "propName": formData?.["Property Name"],
            "propTypeId": formData?.["Property Type"]?.value,
            "streetName": formData?.["Street Name"],
            "streetNumber": Number(formData?.["Street Number"]),
            "countryId": formData?.["Country"]?.value,
            "stateId": formData?.["State"]?.value,
            "cityId": formData?.["City"]?.value,
            "zipCode": formData?.["Zip/Postal Code"],
            "status": 'active'
        }
        if (!onEditOpen)
        data["imgPath"] =
          imageUrl && !imageUrl?.includes("undefined")
            ? imageUrl
            : formData?.imgPath;
        if (onEditOpen) {
            if (imageUrl && !imageUrl?.includes("undefined")) {
            data["imgPath"] = imageUrl;
            } else {
            data["imgPath"] = mainImage || formData.imgPath;
            }
        }
        if (onEditOpen) {
            dispatch(betaUserEditProperty(axiosInstance, data, { propertyProfileId: singleData?._id}, singleData?._id)).then((response) => {
                if (onviewPage) {
                    setShow(false)
                    setBetaProperty(false)
                    refresh()
                    setTimeout(() => {
                        setToastList([
                            {
                                id: 0,
                                // title: "Success Editing User Property Type",
                                title: response.data.message,
                                icon: "success",
                            },
                        ]);
                    }, 300)
                }
            }).catch((error) => {
                setToastList([
                    {
                        id: 0,
                        // title: "Error Editing User Property Type",
                        title: error.response.data.message,
                        icon: "error",
                    },
                ]);
            });
        } else {
            dispatch(betaUserAddProperty(axiosInstance, data, {})).then((response) => {
                if (onviewPage) {
                    setShow(false)
                    setBetaProperty(false)
                    refresh()
                } else {
                    setBetaProperty(false)
                    setShowConfirmation(false)
                    setTimeout(() => {
                        setBetaResident(true)
                        setShowConfirmation(true)
                    }, 1500);
                }
                setTimeout(() => {
                    setToastList([
                        {
                            id: 0,
                            title: response.data.message,
                            icon: "success",
                        },
                    ]);
                }, 300);
            }).catch((error) => {
                setToastList([
                    {
                        id: 0,
                        title: error.response.data.message,
                        icon: "error",
                    },
                ]);
            });
        }
    }

    const handleFile = (url) => {
        if (url) {
          setMainImage(url);
        }
      };

    useEffect(() => {
        if(formData?.["Property Name"] !== "" 
            && formData?.["Property Name"] !== undefined  
            && formData?.["Property Type"]?.value !== "" 
            && formData?.["Property Type"]?.value !== undefined 
            && formData?.["Country"]?.value !== "" 
            && formData?.["Country"]?.value !== undefined
            && (state.length >= 2 ? formData?.["State"]?.value !== "" : true)
            && (state.length >= 2 ? formData?.["State"]?.value !== undefined : true)
            && (city.length >= 2 ? formData?.["City"]?.value !== "" : true)
            && (city.length >= 2 ? formData?.["City"]?.value !== undefined : true)
            && formData?.["Street Name"] !== "" 
            && formData?.["Street Name"] !== undefined 
            && formData?.["Street Number"] !== "" 
            && formData?.["Street Number"] !== undefined 
            && formData?.["Zip/Postal Code"] !== ""
            && formData?.["Zip/Postal Code"] !== undefined) {
                setIsSubmitDisabled(false)
            } else {
            setIsSubmitDisabled(true)
        }
    }, [formData, state, city, country])

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <h2 className={style.title}>{title}</h2>
                <p className={style.description}>{description}</p>
                <div className={style.scroll}>

                    <div style={{ padding: '10px 20px 70px 20px' }}>
                        <div className={style.upload}> 
                        <ImageUpload
                            handleFile={handleFile}
                            removeImage={removeImage}
                            previewImage={formData?.imgPath !== "" && formData?.imgPath}
                            width={"calc(100% - 45px)"}
                            height={"100px"}
                            title={"Main Image"}
                        />
                        </div>
                        <div className={style.action_buttons}>
                            <div className={style.form_container}>
                            <form onSubmit={e => onButtonClick(e, 'Ok')}>
                                    {inputFields.map((inputField, index) => (
                                        <div key={index} className={style.input_field} style={{ width: inputField.width }}>
                                            <Input
                                                disablePast={false}
                                                type={inputField.type}
                                                name={inputField.name}
                                                label={inputField.label}
                                                value={formData[inputField.name]}
                                                placeholder={inputField.placeholder}
                                                required={inputField.required}
                                                onChange={(value) => handleInputChange(inputField.name, value)}
                                                options={inputField.options}
                                                disableFuture={true}
                                            />
                                            {/* <div className={style.error}>{formErrors[inputField.name]}</div> */}
                                        </div>
                                    ))}
                                    <div className={style.action_button}>
                                        <Button buttonTextStyle={{ fontWeight: 800 }} text={title} disabled={isSubmitDisabled} buttonType={(isSubmitDisabled ? `${buttonStyle.button_disabled}` : `${buttonStyle.button_primary}`)} />
                                        {/* <Button text={"Add Property"} disabled={isSubmitDisabled || Object.values(formErrors).some(error => Boolean(error))} buttonType={(isSubmitDisabled || Object.values(formErrors).some(error => Boolean(error))) ? `${buttonStyle.button_disabled}` : `${buttonStyle.button_primary}`} /> */}
                                        {/* <Button text={"Add Property"}  buttonType={`${buttonStyle.button_primary}`} /> */}
                                    </div>
                                </form>
                                </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BetaAddProperty;