import { endpoints } from "../../../constants/url.endpoint";
import { booksAction } from "./books.reducer";

const {
  getBooksSuccess,
  deleteBookSuccess,
  resetBooksData,
  addBookSuccess,
  updateBookSuccess,
  viewBookSuccess,
} = booksAction;

export const getBooks = (axiosInstance, params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: endpoints.getBooks,
      method: "GET",
      params,
    })
      .then((response) => {
        dispatch(getBooksSuccess(response?.data?.data));
        resolve(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error in Get Books", error);
        dispatch(resetBooksData());
        reject(error);
      });
  });
};

export const deleteBook = (axiosInstance, bookIds) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: endpoints.deleteBook,
      data: bookIds,
      method: "DELETE",
    })
      .then((response) => {
        dispatch(deleteBookSuccess(response?.data));
        resolve(response?.data);
      })
      .catch((error) => {
        console.error("Error in Delete Book", error);
        dispatch(resetBooksData());
        reject(error);
      });
  });
};

export const updateBook =
  (axiosInstance, bookData, bookId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.updateBook + bookId,
        method: "PATCH",
        data: bookData,
      })
        .then((response) => {
          dispatch(updateBookSuccess(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          console.error("Error in Delete Book", error);
          dispatch(resetBooksData());
          reject(error);
        });
    });
  };

export const viewBook = (axiosInstance, bookId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: endpoints.viewBook + bookId,
      method: "GET",
    })
      .then((response) => {
        dispatch(viewBookSuccess(response?.data?.data));
        resolve(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error in Delete Book", error);
        dispatch(resetBooksData());
        reject(error);
      });
  });
};

export const viewBookSection =
  (axiosInstance, sectionId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.viewBookSection + sectionId,
        method: "GET",
      })
        .then((response) => {
          dispatch(viewBookSuccess(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          console.error("Error in Delete Book", error);
          dispatch(resetBooksData());
          reject(error);
        });
    });
  };

export const updateBookSection =
  (axiosInstance, sectionId, payload) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.viewBookSection + sectionId,
        data: payload,
        method: "PATCH",
      })
        .then((response) => {
          dispatch(viewBookSuccess(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          console.error("Error in Delete Book", error);
          dispatch(resetBooksData());
          reject(error);
        });
    });
  };

export const deleteBookSection =
  (axiosInstance, payload) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.deleteBookSection,
        data: payload,
        method: "DELETE",
      })
        .then((response) => {
          dispatch(viewBookSuccess(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          console.error("Error in Delete Book", error);
          dispatch(resetBooksData());
          reject(error);
        });
    });
  };

export const addBook = (axiosInstance, bookData) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: endpoints.addBook,
      method: "POST",
      data: bookData,
    })
      .then((response) => {
        dispatch(addBookSuccess(response?.data?.data));
        resolve(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error in Delete Book", error);
        dispatch(resetBooksData());
        reject(error);
      });
  });
};
export const addBookSection =
  (axiosInstance, bookSectionData) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.addBookSection,
        method: "POST",
        data: bookSectionData,
      })
        .then((response) => {
          dispatch(addBookSuccess(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          console.error("Error in Delete Book", error);
          dispatch(resetBooksData());
          reject(error);
        });
    });
  };
