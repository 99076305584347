import { endpoints } from "../../constants/url.endpoint";
import { utilitiesAction } from "./utility.reducer";

const {
    getLanguagesSuccess,
    getAccessBasedSuccess,
    resetUtilitiesData
} = utilitiesAction;

export const getLanguages = (axiosInstance, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        axiosInstance({
            url: endpoints.getLanguages,
            method: "GET",
            params: params,
        })
            .then((response) => {
                dispatch(getLanguagesSuccess(response?.data?.data));
                resolve(response?.data?.data);
            })
            .catch((error) => {
                console.error('Error in Get Languages', error)
                dispatch(resetUtilitiesData());
                reject(error);
            });
    });
};

export const getAccessBasedSideMenu = (axiosInstance, params, userId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        axiosInstance({
            url: endpoints.getAccessBasedMenu + userId,
            method: "GET",
            params: params,
        })
            .then((response) => {
                dispatch(getAccessBasedSuccess(response?.data?.data));
                resolve(response?.data?.data);
            })
            .catch((error) => {
                console.error('Error in Get Side Menu', error)
                dispatch(resetUtilitiesData());
                reject(error);
            });
    });
};