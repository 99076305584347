import { useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { MdOutlineTipsAndUpdates, MdOutlineCategory } from "react-icons/md"

// Custom Import
import './CategoryAndTipsSwitchManagement.scss'
import SwitchButton from "../../../../shared/components/switchButton/switchButton"
import TipsManagement from "../../../../components/FengShui/TipsManagement/TipsManagement"
import CategoryManagement from "../../../../components/FengShui/CategoryManagement/CategoryManagement"
import { useDispatch, useSelector } from "react-redux"
import { setType } from "../../../../redux/FengShui/toggle"

const CategoryAndTipsSwitchManagementPage = () => {
  const { type } = useParams()
  const navigate = useNavigate()
  const state = useSelector(state => state)
  const [isTips, setIsTips] = useState(type === 'tips' ? true : false)

  // console.log(type , state?.toggle?.type, 'state')

  if (type !== 'tips' && type !== 'category') return null
  // if (state?.loader?.loading) return null
  if (!state?.getLanguages?.languageId) return null

  const onCategoryClick = () => {
    setIsTips(false)
    navigate('/fengshui/tipsManagement/category')
  }

  const onTipsClick = () => {
    setIsTips(true)
    navigate('/fengshui/tipsManagement/tips')
  }

  return (
    <section className="container">
        <div className="wrapper">
            <SwitchButton
                FirstIcon={MdOutlineCategory}
                SecondIcon={MdOutlineTipsAndUpdates}
                firstText="Category Management"
                secondText="Tips Management"
                onCategoryClick={onCategoryClick}
                onTipsClick={onTipsClick}
                isTips={isTips}
                />
        </div>
        {isTips ? <TipsManagement /> : <CategoryManagement />}
    </section>
  );
};

export default CategoryAndTipsSwitchManagementPage;
