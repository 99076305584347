import { useEffect, useState } from 'react';
import Heading from '../../../../shared/components/heading/heading';
import Input from '../../../../shared/components/inputFields/input';
import { AppType, TextAlign, UserType } from '../../../../shared/globalConstants/constants';
import { USER_MANAGEMENT } from '../userManagementConstants';
import style from './selectAppUserType.module.scss';
import Button from '../../../../shared/components/button/button';
import buttonStyle from '../../../../shared/components/button/button.module.scss';
import { createSearchParams, useNavigate, useRoutes } from 'react-router-dom';
import useAxiosInstance from '../../../../hooks/useAxiosInstance';
import { useDispatch } from 'react-redux';
import { getApps } from '../../../../redux/AurLaVieAdmin/apps/getApps.action';

const SelectAppUserType = (props) => {

    const { isModalClosed } = props;
    const [formErrors, setFormErrors] = useState({});

    const formFields = [
        {
            type: "dropdown",
            name: "app",
            options: AppType,
            placeholder: 'Select App',
            label: 'Select App',
            width: '50%'
        },
        {
            type: "dropdown",
            name: "userType",
            options: UserType,
            placeholder: 'Select User Type',
            label: 'User Type',
            width: '50%'
        }
    ];
    const [formData, setFormData] = useState({});
    const [selectedApp, setSelectedApp] = useState('');
    const [selectedUserType, setSelectedUserType] = useState('');
    const navigate = useNavigate();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [inputFields, setInputFields] = useState(formFields);
    const dispatch = useDispatch();
    const axiosInstance = useAxiosInstance();
    const [toastList, setToastList] = useState([]);

    useEffect(() => {
        setInputFields(formFields);
        setSelectedApp('');
        setSelectedUserType('');
        setFormData({});
        setIsButtonDisabled(true);
    }, [isModalClosed])

    const handleInputChange = (fieldName, fieldValue) => {
        const params = {
            appName: fieldValue.value,
            isAdmin: true,
        }
        getAppsForUsers(axiosInstance, params);
        if (fieldName === 'app') {
            if (fieldValue.value === 'Auracle-Life') {
                setInputFields(inputFields.filter(field => field.name === 'app'));
                setSelectedApp(fieldValue.value);
                setIsButtonDisabled(false);
            } else if (fieldValue.value === 'Feng-Shui') {
                setSelectedApp(fieldValue.value);
                setInputFields(formFields);
                if (selectedUserType === '') {
                    setIsButtonDisabled(true);
                } else {
                    setIsButtonDisabled(false);
                }
            }
        } else if (fieldName === 'userType') {
            if (selectedApp !== '') {
                setIsButtonDisabled(false);
            } else {
                setIsButtonDisabled(true);
            }
            setSelectedUserType(fieldValue.value);
        }
        setFormData({ ...formData, [fieldName]: fieldValue });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate({
            pathname: '/userManagement/addUser',
            search: `?${createSearchParams({
                app: selectedApp === 'Auracle-Life' ? 'Auracle Life' : 'Feng Shui',
                userType: selectedApp === 'Auracle-Life' ? "Normal User" : selectedUserType === 1 ? 'Normal User' : selectedUserType === 2 ? 'Beta User' : ''
            })}`
        })
    }

    const getAppsForUsers = (axiosInstance, appName) => {
        dispatch(getApps(axiosInstance, appName)).then((response) => {
            localStorage.setItem('appId', response?._id);
        }).catch((error) => {
            setToastList([
                {
                    id: 0,
                    // title: "Fetching Apps Failed",
                    title: error?.response?.data?.message,
                    icon: "error",
                },
            ]);
        });
    }

    const handleInputBlur = (e) => {
        const { name, value } = e.target;
        if (name === 'app') {
            if (value === '') {
                setFormErrors({ ...formErrors, first_name: 'Please select an app' });
            } else {
                setFormErrors({ ...formErrors, [name]: '' });
            }
        } else if (name === 'last_name') {
            if (value === '') {
                setFormErrors({ ...formErrors, last_name: 'Last Name is required' });
            } else {
                setFormErrors({ ...formErrors, [name]: '' });
            }
        } else if (name === 'company_name') {
            if (value === '') {
                setFormErrors({ ...formErrors, company_name: 'Company Name is required' });
            } else {
                setFormErrors({ ...formErrors, [name]: '' });
            }
        } else if (name === 'phone_number') {
            if (value === '') {
                setFormErrors({ ...formErrors, phone_number: 'Phone Number is required' });
            } else if (value.length < 10) {
                setFormErrors({ ...formErrors, phone_number: 'Phone Number should be of 10 digits' });
            } else {
                setFormErrors({ ...formErrors, [name]: '' });
            }
        } else if (name === 'password') {
            if (value !== '' && value.length < 8) {
                setFormErrors({ ...formErrors, password: 'Password must be at least 8 characters' });
            } else if (value === '') {
                setFormErrors({ ...formErrors, password: 'Password is required' });
            } else {
                setFormErrors({ ...formErrors, [name]: '' });
            }
        } else {
            setFormErrors({ ...formErrors, [name]: '' });
        }
    };

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <Heading title={USER_MANAGEMENT.SELECT_APP_USERTYPE.HEADING.TITLE} description={USER_MANAGEMENT.SELECT_APP_USERTYPE.HEADING.DESCRIPTION} textAlign={TextAlign.CENTER} />
                <div className={style.form_container}>
                    <form onSubmit={handleSubmit}>
                        <div className={style.input_container}>
                            {inputFields.map((inputField, index) => (
                                <div key={index} className={style.input_field} style={{ width: inputField.width }}>
                                    <Input
                                        type={inputField.type}
                                        label={inputField.label}
                                        name={inputField.name}
                                        value={formData[inputField.name]}
                                        placeholder={inputField.placeholder}
                                        onChange={(value) => handleInputChange(inputField.name, value)}
                                        options={inputField.options}
                                        handleInputBlur={handleInputBlur}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className={style.action_button}>
                            {formErrors['app']}
                            <Button type='submit' text={USER_MANAGEMENT.SELECT_APP_USERTYPE.BUTTONS.TITLE} disabled={isButtonDisabled} buttonType={isButtonDisabled ? `${buttonStyle.button_disabled}` : `${buttonStyle.button_primary}`} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SelectAppUserType;