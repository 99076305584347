import { endpoints } from "../../../constants/url.endpoint";
import { adminCategoryActions } from "./adminCategory.reducer";

const {
  manageAdminCategorySuccess,
  resetAdminCategoryDetail,
  addAdminCategorySuccess,
  viewAdminCategorySuccess,
  editAdminCategorySuccess,
  resetAdminCategoryData,
} = adminCategoryActions;

export const fetchAdminCategoryListing =
  (axiosInstance, payload, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.adminCategoryListing,
        method: "GET",
        params: params,
        data: payload,
      })
        .then((res) => {
          dispatch(manageAdminCategorySuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          console.log(err, "err==>");
          dispatch(resetAdminCategoryDetail());
          reject(err);
        });
    });
  };

export const addAdminCategoryCreate =
  (axiosInstance, data, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.addAdminCategory,
        method: "POST",
        data,
        params,
      })
        .then((response) => {
          dispatch(addAdminCategorySuccess(response?.data?.data));
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(resetAdminCategoryDetail());
          reject(error);
        });
    });
  };

export const editAdminCategory =
  (axiosInstance, data, id, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.editAdminCategory + id,
        method: "PATCH",
        data,
        params,
      })
        .then((response) => {
          dispatch(editAdminCategorySuccess(response?.data?.data));
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(resetAdminCategoryDetail());
          reject(error);
        });
    });
  };

export const viewAdminCategory =
  (axiosInstance, id, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.viewAdminCategory + id,
        method: "GET",
        params: params,
      })
        .then((response) => {
          dispatch(viewAdminCategorySuccess(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          dispatch(resetAdminCategoryDetail());
          reject(error);
        });
    });
  };

export const deleteAdminCategory =
  (axiosInstance, data, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.deleteAdminCategory,
        method: "POST",
        params,
        data,
      })
        .then((response) => {
          dispatch(resetAdminCategoryData(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          dispatch(resetAdminCategoryDetail());
          reject(error);
        });
    });
  };
