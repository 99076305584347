import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    data: null,
};

const subAdminSlice = createSlice({
    name: "subAdmin",
    initialState,
    reducers: {
        getSubAdminSuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        viewSubAdminSuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        addSubAdminSuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        editSubAdminSuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        deleteSubAdminSuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        getRolesSuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        resetSubAdminData: (state, action) => {
            state = {
                listing: [],
                data: null,
            };
        },
    },
});

export const {
    actions: subAdminAction,
    reducer: subAdminReducer,
    name: subAdmin,
} = subAdminSlice;