export const USER_ROLES = {
    HEADING: {
        TITLE: "User Roles",
        DESCRIPTION: "",
    },
    BUTTONS: {
        TITLE: "Add User Role",
    },
    FILTERS: {
        SEARCH: {
            PLACEHOLDER: "Search User Role",
        },
    },
    TABLE: {
        NO_DATA: "No User Roles Found"
    },
    ADD_EDIT_ROLE: {
        HEADING: {
            TITLE_ADD: "Add User Role",
            TITLE_EDIT: "Edit User Role",
            DESCRIPTION: "",
        },
        BUTTONS: {
            TITLE: "Add User Role",
            EDIT_TITLE: "Edit User Role",
        },
    },
    DELETE: {
        TITLE: "Delete User Role",
        DESCRIPTION: "Are you sure you want to delete this User Role?"
    }
}

export const SUB_ADMIN = {
    HEADING: {
        TITLE: "Sub-admin",
        DESCRIPTION: "",
    },
    BUTTONS: {
        TITLE_ADD: "Add Sub-admin",
        TITLE_EDIT: "Update Sub-Admin"
    },
    BACK: {
        TITLE_ADD: "Add Sub-admin",
        TITLE_EDIT: "Edit User Details",
        DESCRIPTION_ADD: 'Back to sub-admin list',
        DESCRIPTION_EDIT: "Back to view sub-admin"
    },
    UPLOAD: {
        TITLE: "User Image"
    },
    FILTERS: {
        SEARCH: {
            PLACEHOLDER: "Search first/last name",
        },
    },
    TABLE: {
        NO_DATA: "No Sub-Admin Found"
    },
    VIEW_SUB_ADMIN: {
        BACK: {
            TITLE: "View User Details",
            DESCRIPTION: "Back to sub-admin list",
        }
    },
    ADD_EDIT_ROLE: {
        HEADING: {
            TITLE_ADD: "Add User Role",
            TITLE_EDIT: "Edit User Role",
            DESCRIPTION: "",
        },
        BUTTONS: {
            TITLE: "Add Sub-admin",
            EDIT_TITLE: "Edit Profile",
        },
    },
    DELETE: {
        TITLE: "Delete Sub-admin",
        DESCRIPTION: "Are you sure you want to delete this sub-admin?"
    }
}