import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    data: null,
};

const utilitiesSlice = createSlice({
    name: "utilities",
    initialState,
    reducers: {
        getLanguagesSuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        getAccessBasedSuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        resetUtilitiesData: (state, action) => {
            state = {
                listing: [],
                data: null,
            };
        },
    },
});

export const {
    actions: utilitiesAction,
    reducer: utilitiesReducer,
    name: utilities,
} = utilitiesSlice;