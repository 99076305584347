export const CATEGORY = {
    HEADING: {
        TITLE: 'Category Management',
        DESCRIPTION: "",
    },
    FILTERS: {
        SEARCH: {
            PLACEHOLDER: "Search Category"
        }
    },
    ADD_CATEGORY: {
        BACK: {
            TITLE: 'Add Category',
            DESCRIPTION: 'Back to category list',
        },
        HEADING: {
            TITLE: 'Category Details',
            DESCRIPTION: "",
        },
        BUTTONS: {
            TITLE: 'Add Category'
        },
        UPLOAD: {
            TITLE: 'Category Image'
        }
    },
    EDIT_CATEGORY: {
        BACK: {
            TITLE: 'Edit Category Management',
            DESCRIPTION: 'Back to view Category',
        },
        HEADING: {
            TITLE: 'Category Details',
            DESCRIPTION: "",
        },
        SUB_HEADING: {
            TITLE: 'Category Details',
            DESCRIPTION: "",
        },
        BUTTONS: {
            TITLE: 'Update Category'
        },
        UPLOAD: {
            TITLE: 'Category Image'
        }
    },
    VIEW_CATEGORY: {
        BACK: {
            TITLE: 'Category Management',
            DESCRIPTION: 'Back to category list',
        },
        HEADING: {
            TITLE: 'User Profile Details',
            DESCRIPTION: "",
        },
        BUTTONS: {
            TITLE: 'Add Category'
        },
        UPLOAD: {
            TITLE: 'Category Image'
        }
    }
}