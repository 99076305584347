import { endpoints } from "../../../constants/url.endpoint";
import { manageUsersActions } from "./manageUsers.reducer";

const {
  manageUsersSuccess,
  addUserSuccess,
  editUserSuccess,
  viewUserSuccess,
  deleteUserSuccess,
  updateAstrologerProfileSuccess,
  deleteAstrologerProfileSuccess,
  addAstrologerProfileSuccess,
  resetManageUsersData,
  resetUserDetail,
  viewUsersDetailsSuccess,
  adminPropertyTypeSuccess,
  adminPropertyTypeError,
  getBetaUserPropertyListSuccess,
  getBetaUserPropertyListError,
} = manageUsersActions;

export const fetchUserListing =
  (axiosInstance, payload, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.userListing,
        method: "POST",
        params: params,
        data: payload,
      })
        .then((res) => {
          dispatch(manageUsersSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(resetUserDetail());
          reject(err);
        });
    });
  };

export const getAdminPropertyType =
  (axiosInstance, data, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.getAdminPropertyType,
        method: "GET",
        params,
        data,
      })
        .then((res) => {
          console.log(res, "ajay");
          dispatch(adminPropertyTypeSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(adminPropertyTypeError());
          reject(err);
        });
    });
  };

export const betaUserAddProperty =
  (axiosInstance, data, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.betaUserCreatePropertyProfile,
        method: "POST",
        params,
        data,
      })
        .then((res) => {
          // dispatch(adminPropertyTypeSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          // dispatch(adminPropertyTypeError());
          reject(err);
        });
    });
  };

export const betaUserEditProperty =
  (axiosInstance, data, params, id) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.betaUserEditPropertyProfile + id,
        method: "PATCH",
        params,
        data,
      })
        .then((res) => {
          // dispatch(adminPropertyTypeSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          // dispatch(adminPropertyTypeError());
          reject(err);
        });
    });
  };

export const getBetaUserPropertyList =
  (axiosInstance, data, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.getBetaUserPropertyProfile,
        method: "GET",
        params,
        data,
      })
        .then((res) => {
          dispatch(getBetaUserPropertyListSuccess(res?.data?.status));
          resolve(res);
        })
        .catch((err) => {
          dispatch(getBetaUserPropertyListError());
          reject(err);
        });
    });
  };

export const getPropertyResidentLinkUp =
  (axiosInstance, data, params, userId, page, limit) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url:
          endpoints.getPropertyResidentLinkUp +
          `?userId=${userId}&page=${page}&limit=${limit}`,
        method: "GET",
        params,
        data,
      })
        .then((res) => {
          // dispatch(adminPropertyTypeSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          // dispatch(adminPropertyTypeError());
          reject(err);
        });
    });
  };

export const linkingtPropertyResidentLinkUp =
  (axiosInstance, data, params, userId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.linkingPropertyResidentLinkUp + userId,
        method: "POST",
        params,
        data,
      })
        .then((res) => {
          // dispatch(adminPropertyTypeSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          // dispatch(adminPropertyTypeError());
          reject(err);
        });
    });
  };

export const getSingleBetaUserPropertyList =
  (axiosInstance, data, params, id) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.getSingleBetaUserPropertyProfile + id,
        method: "GET",
        params,
        data,
      })
        .then((res) => {
          // dispatch(adminPropertyTypeSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          // dispatch(adminPropertyTypeError());
          reject(err);
        });
    });
  };

export const deleteBetaUserProperty =
  (axiosInstance, data, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.deleteBetaUserPropertyProfile,
        method: "POST",
        params,
        data,
      })
        .then((res) => {
          // dispatch(adminPropertyTypeSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          // dispatch(adminPropertyTypeError());
          reject(err);
        });
    });
  };

export const betaUserAddResident =
  (axiosInstance, data, params, id) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.betaUserCreateResident}?propProfileId=${id}`,
        method: "POST",
        params,
        data,
      })
        .then((res) => {
          // dispatch(adminPropertyTypeSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          // dispatch(adminPropertyTypeError());
          reject(err);
        });
    });
  };

export const getSingleBetaUserResident =
  (axiosInstance, data, params, id) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.getSinglebetaUserResident + id,
        method: "GET",
        params,
        data,
      })
        .then((res) => {
          // dispatch(adminPropertyTypeSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          // dispatch(adminPropertyTypeError());
          reject(err);
        });
    });
  };

export const betaUserEditResident =
  (axiosInstance, data, params, id, propProfileId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.betaUserEditResident + id,
        method: "PATCH",
        params,
        data,
      })
        .then((res) => {
          // dispatch(adminPropertyTypeSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          // dispatch(adminPropertyTypeError());
          reject(err);
        });
    });
  };

export const deleteBetaUserResident =
  (axiosInstance, data, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.deletebetaUserResident,
        method: "POST",
        params,
        data,
      })
        .then((res) => {
          // dispatch(adminPropertyTypeSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          // dispatch(adminPropertyTypeError());
          reject(err);
        });
    });
  };

export const getbetaUserResidentList =
  (axiosInstance, data, params, query) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.getbetaUserResident}?userId=${query.userId}&page=${query.page}&limit=${100000}`,
        method: "GET",
        params,
        data,
      })
        .then((res) => {
          // dispatch(adminPropertyTypeSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          // dispatch(adminPropertyTypeError());
          reject(err);
        });
    });
  };

export const addUser =
  (axiosInstance, userDetails, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.addUser,
        method: "POST",
        data: userDetails,
        params: params,
      })
        .then((response) => {
          dispatch(addUserSuccess(response?.data?.data));
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(resetUserDetail());
          reject(error);
        });
    });
  };

export const editUser =
  (axiosInstance, userDetails, userId, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.editUser + userId,
        method: "POST",
        data: userDetails,
        params: params,
      })
        .then((response) => {
          dispatch(editUserSuccess(response?.data?.data));
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(resetUserDetail());
          reject(error);
        });
    });
  };

export const viewUser = (axiosInstance, email, params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: endpoints.viewUser,
      method: "POST",
      params: params,
      data: {
        email: email,
      },
    })
      .then((response) => {
        dispatch(viewUserSuccess(response?.data?.data));
        resolve(response?.data?.data);
      })
      .catch((error) => {
        dispatch(resetUserDetail());
        reject(error);
      });
  });
};

export const deleteAppUser =
  (axiosInstance, details, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.deleteUser,
        method: "POST",
        params: params,
        data: details,
      })
        .then((response) => {
          dispatch(deleteUserSuccess(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          dispatch(resetUserDetail());
          reject(error);
        });
    });
  };

export const addAstrologerProfile =
  (axiosInstance, details, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.addAstrologerProfile,
        method: "POST",
        params: params,
        data: details,
      })
        .then((response) => {
          dispatch(addAstrologerProfileSuccess(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          dispatch(resetUserDetail());
          reject(error);
        });
    });
  };

export const updateAstrologerProfile =
  (axiosInstance, details, params, userId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.updateAstrologerProfile + userId,
        method: "POST",
        params: params,
        data: details,
      })
        .then((response) => {
          dispatch(updateAstrologerProfileSuccess(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          dispatch(resetUserDetail());
          reject(error);
        });
    });
  };

export const updateAstrologerProfileViaAdmin =
  (axiosInstance, details, params, userId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.updateAstrologerProfileViaAdmin + userId,
        method: "POST",
        params: params,
        data: details,
      })
        .then((response) => {
          dispatch(updateAstrologerProfileSuccess(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          dispatch(resetUserDetail());
          reject(error);
        });
    });
  };

export const deleteAstrologerProfile =
  (axiosInstance, userId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.deleteProfileViaAdmin + userId,
        method: "GET",
      })
        .then((response) => {
          dispatch(deleteAstrologerProfileSuccess(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          dispatch(resetUserDetail());
          reject(error);
        });
    });
  };
