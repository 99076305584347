import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  listing: [],
  data: null,
  adminPropertyTypeListing: [],
  propertyListInBetaUser: ''
};
const manageUsersSlice = createSlice({
  name: "manageUsers",
  initialState,
  reducers: {
    manageUsersSuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;
    },
    adminPropertyTypeSuccess: (state, action) => {
      state.adminPropertyTypeListing = action.payload;
    },
    adminPropertyTypeError: (state, action) => {
      state.adminPropertyTypeListing = [];
    },
    addUserSuccess: (state, action) => {
      state.data = action.payload;
    },
    editUserSuccess: (state, action) => {
      state.data = action.payload;
    },
    viewUserSuccess: (state, action) => {
      state.data = action.payload;
    },
    deleteUserSuccess: (state, action) => {
      state.data = action.payload;
    },
    viewUsersDetailsSuccess: (state, action) => {
      state.data = action.payload;
    },
    addAstrologerProfileSuccess: (state, action) => {
      state.data = action.payload;
    },
    deleteAstrologerProfileSuccess: (state, action) => {
      state.data = action.payload;
    },
    updateAstrologerProfileSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetUserDetail: (state, action) => {
      state.data = null;
    },
    getBetaUserPropertyListSuccess: (state, action) => {
      state.propertyListInBetaUser = action.payload
    },
    getBetaUserPropertyListError: (state, action) => {
      state.propertyListInBetaUser = ''
    },
    resetManageUsersData: (state, action) => {
      state = {
        listing: [],
        data: null,
      };
    },
  },
});

export const {
  actions: manageUsersActions,
  reducer: manageUsersReducer,
  name: manageUsers,
} = manageUsersSlice;
