import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { ChangePasswordAction } from "../../../../redux/users/user.actions";
import Button from "../../../../shared/components/button/button";
import buttonStyle from "../../../../shared/components/button/button.module.scss";
import Heading from "../../../../shared/components/heading/heading";
import Input from "../../../../shared/components/inputFields/input";
import { TextAlign } from "../../../../shared/globalConstants/constants";
import style from "./ChangePassword.module.scss";

const ChangePassword = ({ isModalClosed, id, setToastList, closeModal }) => {
  const [formErrors, setFormErrors] = useState({});

  const formFields = [
    {
      type: "password",
      name: "Enter Current Password",
      placeholder: "Enter Current Password",
      label: "Enter Current Password",
      required: true,
      width: "100%",
    },
    {
      type: "password",
      name: "Enter New Password",
      placeholder: "Enter New Password",
      label: "Enter New Password",
      required: true,
      width: "100%",
    },
    {
      type: "password",
      name: "Enter Confirm Password",
      placeholder: "Enter Confirm Password",
      label: "Enter Confirm Password",
      required: true,
      width: "100%",
    },
  ];
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [inputFields, setInputFields] = useState(formFields);
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    setInputFields(formFields);
    setFormData({});
    setIsButtonDisabled(true);
  }, [isModalClosed]);

  useEffect(() => {
    const hasEmptyRequiredFields = inputFields
      .filter((field) => field.required)
      .some((field) => !formData[field.name]);
    setIsButtonDisabled(hasEmptyRequiredFields);
  }, [inputFields, formData]);

  const handleInputChange = (fieldName, fieldValue) => {
    setFormData((form) => ({ ...form, [fieldName]: fieldValue }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      formData?.["Enter New Password"] === formData?.["Enter Confirm Password"]
    ) {
      const passwordDetail = {
        oldPassword: formData?.["Enter Current Password"],
        newPassword: formData?.["Enter New Password"],
      };

      dispatch(ChangePasswordAction(axiosInstance, passwordDetail, id, {}))
        .then((res) => {
          setFormData({});
          setIsButtonDisabled(true);
          closeModal();
          setToastList([
            {
              id: 0,
              title: res.data.message,
              icon: "success",
            },
          ]);
        })
        .catch((err) => {
          setToastList([
            {
              id: 0,
              title: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    } else {
      setToastList([
        {
          id: 0,
          title: "New and Confirm Password did not match.",
          icon: "error",
        },
      ]);
    }
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
    if (name === "Enter Current Password") {
      if (value !== "" && value.length < 8) {
        setFormErrors((form) => ({
          ...form,
          [name]: "Password must be at least 8 characters",
        }));
      } else if (value === "") {
        setFormErrors((form) => ({ ...form, [name]: "Password is required" }));
      } else if (!passwordRegex.test(value)) {
        setFormErrors((form) => ({
          ...form,
          [name]:
            "Please use atleast 1 uppercase, 1 lowercase, 1 special character, 1 numeric",
        }));
      } else {
        setFormErrors((form) => ({ ...form, [name]: "" }));
      }
    } else if (name === "Enter New Password") {
      if (value !== "" && value.length < 8) {
        setFormErrors((form) => ({
          ...form,
          [name]: "Password must be at least 8 characters",
        }));
      } else if (value === "") {
        setFormErrors((form) => ({ ...form, [name]: "Password is required" }));
      } else if (!passwordRegex.test(value)) {
        setFormErrors((form) => ({
          ...form,
          [name]:
            "Please use atleast 1 uppercase, 1 lowercase, 1 special character, 1 numeric",
        }));
      } else {
        if (value !== formData["Enter Confirm Password"]) {
          if (formData["Enter Confirm Password"]) {
            setFormErrors((form) => ({
              ...form,
              "Enter Confirm Password": "",
              [name]: "New password and confirm password does not match",
            }));
            return;
          }
        }
        if (value === formData["Enter Confirm Password"]) {
          setFormErrors((form) => ({
            ...form,
            "Enter Confirm Password": "",
            [name]: "",
          }));
          return;
        }
        setFormErrors((form) => ({ ...form, [name]: "" }));
      }
    } else if (name === "Enter Confirm Password") {
      if (value !== "" && value.length < 8) {
        setFormErrors((form) => ({
          ...form,
          [name]: "Password must be at least 8 characters",
        }));
      } else if (value === "") {
        setFormErrors((form) => ({ ...form, [name]: "Password is required" }));
      } else if (!passwordRegex.test(value)) {
        setFormErrors((form) => ({
          ...form,
          [name]:
            "Please use atleast 1 uppercase, 1 lowercase, 1 special character, 1 numeric",
        }));
      } else {
        if (value !== formData["Enter New Password"]) {
          if (formData["Enter New Password"]) {
            setFormErrors((form) => ({
              ...form,
              "Enter New Password": "",
              [name]: "New password and confirm password does not match",
            }));
            return;
          }
        }
        if (value === formData["Enter New Password"]) {
          setFormErrors((form) => ({
            ...form,
            "Enter New Password": "",
            [name]: "",
          }));
          return;
        }
        setFormErrors((form) => ({ ...form, [name]: "" }));
      }
    } else {
      setFormErrors((form) => ({ ...form, [name]: "" }));
    }
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <Heading
          title="Change Password"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing"
          textAlign={TextAlign.CENTER}
        />
        <div className={style.form_container}>
          <form onSubmit={handleSubmit}>
            <div className={style.input_container}>
              {inputFields.map((inputField, index) => (
                <div
                  key={index}
                  className={style.input_field}
                  style={{ width: inputField.width }}
                >
                  <Input
                    type={inputField.type}
                    label={inputField.label}
                    name={inputField.name}
                    value={formData[inputField.name]}
                    placeholder={inputField.placeholder}
                    onChange={(value) =>
                      handleInputChange(inputField.name, value)
                    }
                    options={inputField.options}
                    handleInputBlur={handleInputBlur}
                  />
                  <div className={style.error}>
                    {formErrors[inputField.name]}
                  </div>
                </div>
              ))}
            </div>
            <div className={style.action_button}>
              <Button
                type="submit"
                text="Submit"
                disabled={
                  isButtonDisabled ||
                  Object.values(formErrors).some((error) => Boolean(error))
                }
                buttonType={
                  isButtonDisabled ||
                  Object.values(formErrors).some((error) => Boolean(error))
                    ? `${buttonStyle.button_disabled}`
                    : `${buttonStyle.button_primary}`
                }
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
