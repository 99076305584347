import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    anotherLoading: false,
}
  
export const AnotherLoadingSlice = createSlice({
    name: 'anotherLoading',
    initialState,
    reducers: {
        setLoading: (state, action) => {
        state.anotherLoading = action.payload
        }
    },
})

export const { setLoading } = AnotherLoadingSlice.actions
export default AnotherLoadingSlice.reducer