import { endpoints } from "../../../constants/url.endpoint";
import { reportsActions } from "./reports.reducer";

const {
  getReportsSuccess,
  addContentIdSuccess,
  editContentIdSuccess,
  deleteReportSuccess,
  getReportCategoriesSuccess,
  getReportsContentIdSuccess,
  resetReportDetail,
} = reportsActions;

export const getAllReports =
  (axiosInstance, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.getAllReports,
        method: "GET",
        params: params,
      })
        .then((res) => {
          dispatch(getReportsSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(resetReportDetail());
          reject(err);
        });
    });
  };

export const addReportsContentId =
  (axiosInstance, payload, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.addReportsContentId,
        method: "POST",
        params: params,
        data: payload
      })
        .then((res) => {
          dispatch(addContentIdSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(resetReportDetail());
          reject(err);
        });
    });
  };

export const editReportsContentId =
  (axiosInstance, payload, params, contentId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.editReportsContentId + contentId,
        method: "PATCH",
        params: params,
        data: payload
      })
        .then((res) => {
          dispatch(editContentIdSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(resetReportDetail());
          reject(err);
        });
    });
  };

  export const viewReportsContentId =
  (axiosInstance, params, reportId) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.viewReportsContentId + reportId,
        method: "GET",
        params: params,
      })
        .then((res) => {
          dispatch(getReportsContentIdSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(resetReportDetail());
          reject(err);
        });
    });
  };

export const getReports =
  (axiosInstance, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.getReports,
        method: "GET",
        params: params,
      })
        .then((res) => {
          dispatch(getReportsSuccess(res?.data?.data));
          resolve(res?.data?.data);
        })
        .catch((err) => {
          dispatch(resetReportDetail());
          reject(err);
        });
    });
  };

export const deleteReport = (axiosInstance, data) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: `${endpoints.deleteReport}`,
      method: "POST",
      data,
    })
      .then((response) => {
        dispatch(deleteReportSuccess(response?.data?.data));
        resolve(response?.data?.data);
      })
      .catch((error) => {
        dispatch(resetReportDetail());
        reject(error);
      });
  });
};

export const getReportCategories =
  (axiosInstance, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.getReportCategories,
        method: "GET",
        params: params,
      })
        .then((res) => {
          dispatch(getReportCategoriesSuccess(res?.data?.data));
          resolve(res?.data?.data);
        })
        .catch((err) => {
          dispatch(resetReportDetail());
          reject(err);
        });
    });
  };
