import { endpoints } from "../../../constants/url.endpoint";
import { manageContentActions } from "./manageContent.reducer";

const {
  addContentSuccess,
  editContentSuccess,
  resetContentDetail,
  viewContentDetailsSuccess,
} = manageContentActions;

export const addContent =
  (axiosInstance, contentDetails) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.addContent,
        method: "POST",
        data: contentDetails,
      })
        .then((response) => {
          dispatch(addContentSuccess(response?.data?.data));
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(resetContentDetail());
          reject(error);
        });
    });
  };

export const updateContent =
  (axiosInstance, contentDetails, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.updateContent}/${params.id}`,
        method: "PATCH",
        data: contentDetails,
      })
        .then((response) => {
          dispatch(editContentSuccess(response?.data?.data));
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(resetContentDetail());
          reject(error);
        });
    });
  };

export const viewContent =
  (axiosInstance, contentDetails) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.viewContent}`,
        params: contentDetails,
        method: "GET",
      })
        .then((response) => {
          dispatch(viewContentDetailsSuccess(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          dispatch(resetContentDetail());
          reject(error);
        });
    });
  };




