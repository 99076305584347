import React, { useState } from "react";
import style from './tabs.module.scss';

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={style.tabs_container}>
      <div className={style.tabs}>
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={activeTab === index ? `${style.active} ${style.tab}` : `${style.tab}`}
            onClick={() => setActiveTab(index)}
          >
            <img src={tab.icon + (activeTab === index ? 'blue.svg' : 'grey.svg')} alt={tab.label} width={20}/>
            {tab.label}
          </button>
        ))}
      </div>
      <div className={style.tab_content}>{tabs[activeTab].content}</div>
    </div>
  );
};

export default Tabs;
