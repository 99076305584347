export const TIPS = {
    HEADING: {
        TITLE: 'Tip Management',
        DESCRIPTION: "",
    },
    ADD_TIPS: {
        BACK: {
            TITLE: 'Add Tip',
            DESCRIPTION: 'Back to tip list',
        },
        HEADING: {
            TITLE: 'Tip Details',
            DESCRIPTION: "",
        },
        BUTTONS: {
            TITLE: 'Create New Tip'
        },
        UPLOAD: {
            TITLE: 'Tip Image'
        }
    },
    EDIT_TIPS: {
        BACK: {
            TITLE: 'Edit Tip',
            DESCRIPTION: 'Back to view tip',
        },
        HEADING: {
            TITLE: 'Tip Details',
            DESCRIPTION: "",
        },
        SUB_HEADING: {
            TITLE: 'Tip Profile Details',
            DESCRIPTION: "",
        },
        BUTTONS: {
            TITLE: 'Update Tip'
        },
        UPLOAD: {
            TITLE: 'Tip Image'
        }
    },
    VIEW_TIPS: {
        BACK: {
            TITLE: 'View Tip',
            DESCRIPTION: 'Back to tip list',
        },
        HEADING: {
            TITLE: 'Tip Details',
            DESCRIPTION: "",
        },
        BUTTONS: {
            TITLE: 'Add Tips'
        },
        UPLOAD: {
            TITLE: 'Tips Image'
        },
        TIPS_LIST: {
            NOT_FOUND: 'No Tips Found'
        }
    }
}