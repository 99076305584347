import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  listing: [],
  data: null,
};
const manageNotificationsSlice = createSlice({
  name: "manageNotifications",
  initialState,
  reducers: {
    manageNotificationsSuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;
    },
    addNotificationSuccess: (state, action) => {
      state.data = action.payload;
    },
    viewNotificationSuccess: (state, action) => {
      state.data = action.payload;
    },
    viewNotificationDetailsSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetNotificationDetail: (state, action) => {
      state.data = null;
    },
    resetManageNotificationsData: (state, action) => {
      state = {
        listing: [],
        data: null,
      };
    },
  },
});

export const {
  actions: manageNotificationActions,
  reducer: manageNotificationReducer,
  name: manageUsers,
} = manageNotificationsSlice;
