import React from 'react';
import ReactDOM from 'react-dom/client';
import './normalize.scss';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { AuthProvider } from './context/authProvider.context';
import Layout from './shared/components/layout';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <AuthProvider>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {/* <Layout> */}
                    <App />
                {/* </Layout> */}
            </PersistGate>
        </Provider>
        </AuthProvider>
    </BrowserRouter>
);
