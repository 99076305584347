import { endpoints } from "../../../constants/url.endpoint";
import { manageSubscriptionActions } from "./manageSubscription.reducer";

const {
  manageSubscriptionListDataSuccess,
  subscriptionDetailsSuccess,
  resetSubscriptionDetail,
  resetSubscriptionAllData,
} = manageSubscriptionActions;

export const fetchSubscriptionListing =
  (axiosInstance, apiData) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.subscription}`,
        method: "GET",
        params: apiData,
      })
        .then((res) => {
          dispatch(manageSubscriptionListDataSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(resetSubscriptionAllData());
          reject(err);
        });
    });
  };

export const addSubscription = (axiosInstance, apiData) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: `${endpoints.addSubscriptionDetail}`,
      method: "POST",
      data: apiData,
    })
      .then((res) => {
        dispatch(subscriptionDetailsSuccess(res?.data?.data));
        resolve(res);
      })
      .catch((err) => {
        dispatch(resetSubscriptionDetail());
        reject(err);
      });
  });
};
export const cancelSubscription =
  (axiosInstance, apiData) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.cancelSubscription}`,
        method: "POST",
        data: apiData,
      })
        .then((res) => {
          dispatch(subscriptionDetailsSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(resetSubscriptionDetail());
          reject(err);
        });
    });
  };

export const fetchSubscriptionDetail =
  (axiosInstance, apiData) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.subscription}/${apiData.id}`,
        method: "GET",
      })
        .then((res) => {
          dispatch(subscriptionDetailsSuccess(res?.data));
          resolve(res?.data);
        })
        .catch((err) => {
          dispatch(resetSubscriptionDetail());
          reject(err);
        });
    });
  };

export const updateSubscription =
  (axiosInstance, apiData) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.subscription}/${apiData?._id}`,
        method: "PATCH",
        data: apiData,
      })
        .then((res) => {
          dispatch(subscriptionDetailsSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

export const updateSubscriptionDate =
  (axiosInstance, apiData) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.updateSubsDate}`,
        method: "PATCH",
        data: apiData,
      })
        .then((res) => {
          dispatch(subscriptionDetailsSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

export const addSubscriptionPayment =
  (axiosInstance, apiData) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.addSubscriptionPayment}`,
        method: "POST",
        data: apiData,
      })
        .then((res) => {
          dispatch(subscriptionDetailsSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(resetSubscriptionDetail());
          reject(err);
        });
    });
  };

export const fetchCheckSubscription =
  (axiosInstance, apiData) => async (dispatch) => {
    apiData["userId"] = localStorage.getItem("userId");
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.checkSubscription}/${apiData?.userId}`,
        method: "GET",
        // params:apiData
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
