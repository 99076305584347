import { useEffect, useRef, useState } from "react";
import Heading from "../../../../shared/components/heading/heading";
import Table from "../../../../shared/components/table/table";
import { ADVICE_MANAGEMENT } from "../advice.constant";
import style from "./activityCardsManagement.module.scss";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAdvicesApi,
  getAllAdvices,
} from "../../../../redux/AurLaVieAdmin/manageAdvices/manageAdvices.actions";
import { languageState } from "../../../../redux/AurLaVieAdmin/languages/getLanguages.selector";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import moment from "moment";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import Back from "../../../../shared/components/backButton/backButton";
import Button from "../../../../shared/components/button/button";
import { isLoading } from "../../../../redux/progressLoader/progressLoader.actions";
import axios from "axios";
import CenterModal from "../../../../shared/components/modal/centeredModal/centeredModal";
import EditActivityCards from "./editActivityCards/editActivityCards";

const AdviceManagment = () => {
  const [advices, setAdvices] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const retainedId = useRef();
  const { id } = useParams();
  const languageData = useSelector(languageState);
  const axiosInstance = useAxiosInstance();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [toastList, setToastList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [totalItem, setTotalItem] = useState(0);
  const currentDeleteRef = useRef(null);
  const [cardName, setCardName] = useState(null);
  const currentAdviceRef = useRef(null);
  const [showEditActivity, setShowEditActivity] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [currentId, setCurrentId] = useState(id);
  const [title, setTitle] = useState(location.state.title);
  const [payloadData, setPayloadData] = useState({});

  const [ids, setIds] = useState(null);

  const [appId, setAppId] = useState(localStorage.getItem("appId"));
  const [languageId, setLanguageId] = useState(
    localStorage.getItem("languageId")
  );

  const handleAdvices = () => {
    currentDeleteRef.current = null;
    setShowDeleteModal(false);
    const params = {
      page,
      limit: 10,
    };

    const payload = {
      appId,
      languageId,
      parentId: currentId,
    };
    setPayloadData(payload);
    getAdvices(axiosInstance, payload, params);
  };

  useEffect(() => {
    if (languageData.languageId) {
      const params = {
        page: 1,
        limit: 10,
      };

      const payload = {
        appId,
        languageId,
        parentId: currentId,
      };
      setPayloadData(payload);
      getAdvices(axiosInstance, payload, params);
    }
  }, [languageData, deleteStatus]);

  useEffect(() => {
    handleAdvices();
  }, [page, deleteStatus]);

  useEffect(() => {
    setPage(1);
    let timerId;
    const fetchResults = async () => {
      try {
        const params = {
          page: 1,
          limit: pageSize,
        };

        const payload = {
          appId,
          languageId,
          parentId: currentId,
          keyword: searchTerm,
        };
        setPayloadData(payload);
        getAdvices(axiosInstance, payload, params);
      } catch (error) {
        console.log(error);
      }
    };

    if (searchTerm || searchTerm === "") {
      timerId = setTimeout(() => {
        fetchResults();
      }, 500);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm, deleteStatus]);

  const onDelete = ({ id, languageId }) => {
    setDeleteStatus(false);
    dispatch(deleteAdvicesApi(axiosInstance, { _id: id, languageId }))
      .then((response) => {
        setDeleteStatus(true);
      })
      .catch((err) => {
        setDeleteStatus(false);
      });
  };

  const getAdvices = (axiosInstance, payload, params) => {
    dispatch(getAllAdvices(axiosInstance, payload, params))
      .then((response) => {
        setPayloadData(payload);
        currentAdviceRef.current = response.data.data.listingData;
        setCardName();
        const updatedAdvices = response?.data?.data?.listingData.map(
          (data, index) => {
            const newIndex = (page - 1) * pageSize + index + 1;
            return {
              id: data?._id,
              "S. No":
                newIndex > 100
                  ? newIndex
                  : newIndex < 100 && newIndex > 9
                  ? `0${newIndex}`
                  : `00${newIndex}`,
              image:
                data.mainImgPath && data.mainImgPath !== "string"
                  ? data.mainImgPath
                  : "/icons/default.svg",
              languageId: data?.languageId,
              "Card Name": data?.adviceName,
              "Order No.": data?.adviceOrder,
              "Created On": moment(data?.created_at).format("DD/MM/YYYY"),
              "Last Edited on": moment(data?.modified_at).format("DD/MM/YYYY"),
            };
          }
        );
        setAdvices(updatedAdvices);
        setTotalItem(response?.data?.data?.meta?.total);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          setAdvices([]);
        }
        setToastList([
          {
            id: 0,
            // title: "Fething Advices Failed",
            title: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const onSearch = (event) => {
    setSearchTerm(event);
  };

  const onEdit = (event) => {
    navigate(`/aurlavie/adviceManagement/activityCards/view/${event.id}`, {
      state: {
        title: event[`Card Name`],
        openModal: true,
      },
    });
  };

  const onView = (event) => {
    navigate(`/aurlavie/adviceManagement/activityCards/view/${event.id}`, {
      state: {
        title: event[`Card Name`],
      },
    });
  };

  const handleMultiple = (event) => {
    setIds(event);
  };

  const handlePage = (page) => {
    setPage(page);
  };

  const selectedRow = (row) => {
    const filteredAdvice = currentAdviceRef.current.filter(
      (element) => element._id === row.id
    );

    if (filteredAdvice[0].adviceType === "Child") {
      const params = {
        page,
        limit: 10,
      };

      const payload = {
        appId,
        languageId,
        parentId: row.id,
      };
      setPayloadData(payload);

      dispatch(getAllAdvices(axiosInstance, payload, params))
        .then((res) => {
          if (res) {
            setCurrentId(row.id);
            setTitle(row[`Card Name`]);
            const params = {
              page: 1,
              limit: pageSize,
            };

            const payload = {
              appId,
              languageId,
              parentId: row.id,
              keyword: searchTerm,
            };

            getAdvices(axiosInstance, payload, params);
          }
        })
        .catch((err) => {
          return navigate(
            `/aurlavie/adviceManagement/contentManagement/${row.id}`,
            {
              state: { data: filteredAdvice[0] },
            }
          );
        });
    } else if (filteredAdvice[0].adviceType === "ContentId") {
      return navigate(
        `/aurlavie/adviceManagement/contentManagement/${row.id}`,
        { state: { data: filteredAdvice[0] } }
      );
    }
  };

  const handleDeleteNotification = (clicked) => {
    if (clicked === "Cancel") {
      setShowDeleteModal(false);
      currentDeleteRef.current = null;
    } else {
      const data = currentDeleteRef.current?.length
        ? { ids: currentDeleteRef.current }
        : { ids };

      // dispatch(deleteNotification(axiosInstance, data))
      //     .then((res) => {
      //         setToastList([
      //             {
      //                 id: 0,
      //                 title: "Notifications Deleted Successfully",
      //                 description: res.message,
      //                 icon: "success",
      //             },
      //         ]);
      //         setTimeout(() => {
      //             handleAdvices();
      //         }, 1000);
      //     })
      //     .catch((err) => {
      //         console.log(err);
      //         setToastList([
      //             {
      //                 id: 0,
      //                 title: "Delete Notification Error",
      //                 description: err?.response?.data?.message,
      //                 icon: "error",
      //             },
      //         ]);
      //     });
    }
  };

  const handleClose = (data) => {
    setShowEditActivity(false);

    const params = {
      page,
      limit: 10,
    };

    const payload = {
      appId,
      languageId,
      parentId: id,
    };
    setPayloadData(payload);
    getAdvices(axiosInstance, payload, params);
  };

  const handleModal = () => {
    setShowEditActivity(true);
  };

  const redirectAdvices = () => {
    navigate(
      `/aurlavie/adviceManagement/activityCards/${retainedId.current.id}`,
      {
        state: {
          title: retainedId.current.title,
          data: retainedId.current.data,
        },
      }
    );
    setPage(1);
    retainedId.current = null;
  };

  return (
    <section className={style.container}>
      <div className={style.wrapper}>
        <CenterModal isOpen={showEditActivity} onClose={handleClose}>
          <EditActivityCards
            title={ADVICE_MANAGEMENT.ADD_ACTIVITY_CARD.HEADING.TITLE}
            isClosed={isClosed}
            payload={payloadData}
            handleClose={handleClose}
            select={"Add"}
          />
        </CenterModal>
        <div className={style.back}>
          <Back
            redirect={retainedId?.current?.id ? redirectAdvices : null}
            title={ADVICE_MANAGEMENT.ACTIVITY_CARDS.BACK.DESCRIPTION}
            description={ADVICE_MANAGEMENT.ACTIVITY_CARDS.BACK.TITLE}
          />
        </div>
        <section className={style.title_filters}>
          <Heading
            fontStyle={`italic`}
            subtitle={"  (section)"}
            title={title}
            description={ADVICE_MANAGEMENT.HEADING.DESCRIPTION}
          />
          <div className={style.filters}>
            <Button
              onClick={() => setShowEditActivity(true)}
              icon={"/icons/add.svg"}
              text={ADVICE_MANAGEMENT.ACTIVITY_CARDS.ADD_BUTTON.TITLE}
            />
          </div>
        </section>
        <section className={style.listing}>
          <Table
            noDataText="No Advices found"
            handleMultiple={handleMultiple}
            data={advices}
            onView={(event) => onView(event)}
            onEdit={(event) => onEdit(event)}
            onDelete={(event) => onDelete(event)}
            totalItem={totalItem}
            handlePage={handlePage}
            pageNumber={page}
            selectedRow={selectedRow}
            deleteNotRequired={false}
          />
        </section>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </section>
  );
};

export default AdviceManagment;
