import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    data: null,
};

const getAppsSlice = createSlice({
    name: "getApps",
    initialState,
    reducers: {
        getAppsSuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        resetGetAppsData: (state, action) => {
            state = {
                listing: [],
                data: null,
            };
        },
    },
});

export const {
    actions: getAppsAction,
    reducer: getAppsReducer,
    name: getApps,
} = getAppsSlice;