import React, { useEffect, useState } from "react";
import style from "./LinkModal.module.scss";
import Button from "../../../shared/components/button/button";
import buttonStyle from "../../../shared/components/button/button.module.scss";
import { useDispatch } from "react-redux";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import {
  getPropertyResidentLinkUp,
  linkingtPropertyResidentLinkUp,
} from "../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions";
import useAuth from "../../../hooks/useAuth.hook";
import { isLoading } from "../../../redux/progressLoader/progressLoader.actions";
import { setLoading } from "../../../redux/anotherLoading/anotherLoading";


const LinkModal = ({ property, resident, userId,setToastList,setShowLinkModal, refresh }) => {

  const [formData, setFormData] = useState({});
  const { getUserAuth } = useAuth();
  const [propertList, setPropertyList] = useState(property);
  const [residentList, setResidentList] = useState(resident);
  const [filteredData, setFilteredData] = useState({});
  const [unFilteredData, setUnFilteredData] = useState([]);
  const [finalData, setFinalData] = useState({});
  // const [isChecked, setIsChecked] = useState(false)
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const [getPropertyResidentLink, setGetPropertyResidentLink] = useState([]);

  useEffect(() => {
    propertyResedentLinkUp();
  }, [userId, property, resident]);

  const propertyResedentLinkUp = () => {
    dispatch(
      getPropertyResidentLinkUp(axiosInstance, {}, {}, userId, 1, 500000)
    )
      .then((response) => {
        setGetPropertyResidentLink(response?.data?.data?.listingData);
      })
      .catch((error) => {
        // setToastList([
        //     {
        //         id: 0,
        //         title: "Error adding user",
        //         description: error.response.data.message,
        //         icon: "error",
        //     },
        // ]);
      });
  }

  useEffect(() => {
    if (resident.length > 0 && property.length > 0) {
      let arr = [];
      property.map((data) => {
        resident.map((item) => {
          arr.push({
            propProfileId: { id: data._id, propName: data.propName },
            userProfileId: { id: item.id, name: item.ResidentName },
          });
        });
        return arr;
      });
      console.log(arr, "arr");
      setUnFilteredData(arr);
    }
  }, [property, resident, getPropertyResidentLink]);

  useEffect(() => {
    if (unFilteredData && getPropertyResidentLink) {
      let newArray = [...unFilteredData];
      unFilteredData.map((item, index) => {
        if (
          getPropertyResidentLink.find(
            (data) =>
              item?.propProfileId?.id === data?.propProfileId?._id &&
              data?.userProfileId?._id === item?.userProfileId?.id
          )
        ) {
          newArray[index] = { ...newArray[index], isTrue: true };
        } else {
          newArray[index] = { ...newArray[index], isTrue: false };
        }
      });
      const groupedData = newArray.reduce(function (r, a) {
        r[a.propProfileId.propName] = r[a.propProfileId.propName] || [];
        r[a.propProfileId.propName].push(a);
        return r;
      }, Object.create(null));
      setFilteredData(groupedData);
    }
  }, [unFilteredData, getPropertyResidentLink]);

  const onButtonClick = (e) => {
    e.preventDefault();
    let sendingData = [];
    let validate = {};
    Object.keys(filteredData).map((data, index) =>
      filteredData[data].map((item) => {
        if (validate.hasOwnProperty(item.userProfileId.name)) {
          if (item.isTrue) {
            validate[`${item.userProfileId.name}`].push(true);
          }
        } else {
          item.isTrue
            ? (validate[`${item.userProfileId.name}`] = [true])
            : (validate[`${item.userProfileId.name}`] = []);
        }
      })
    );
    let error = [];
    Object.keys(validate).map((data, index) => {
      if (!validate[data].length) {
        setToastList([
                {
                  id: 0,
                  title: 'Resident should have atleat one property linked!!!',
                  icon: "error",
                },
              ]); 
              error.push(true)
              return;
      }
      
    });
    if(error.length>0) return;
    Object.keys(filteredData).map((data, index) =>
      filteredData[data].map((item) => {
        if (item.isTrue) {
          sendingData.push({
            userProfileId: item.userProfileId.id,
            propProfileId: item.propProfileId.id,
            userId,
            created_by: getUserAuth().userId,
            status: "active",
            created_at: new Date().toISOString(),
            modified_at: new Date().toISOString(),
          });
        }
      })
    );
    let data = {
      data: sendingData,
    };
    dispatch(linkingtPropertyResidentLinkUp(axiosInstance, data, {}, userId))
      .then((response) => {
        setToastList([
          {
              id: 0,
              title: response.data.message,
              icon: "success",
          },
      ]);
      // propertyResedentLinkUp();
      refresh();
      setShowLinkModal(false);
      })
      .catch((error) => {
        setToastList([
            {
                id: 0,
                title: error.response.data.message,
                icon: "error",
            },
        ]);
      })
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <h2 className={style.title}>{"Link Resident with Property"}</h2>
        <p className={style.description}>
          {"Link Resident with Property lora ipsum"}
        </p>

        <div className={style.action_buttons}>
          <div className={style.form_container}>
            <form onSubmit={onButtonClick}>
              <div className={style.tableWrapper}>
                <table className={style.table}>
                  <thead>
                    <tr>
                      <th className={style.sticky}></th>
                      {resident.map((item, index) => (
                        <th key={item.name + index}>{item.ResidentName}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(filteredData).map((row, index) => {
                      return (
                        <tr key={index}>
                          <td className={style.sticky}>{row}</td>
                          {filteredData[row].map((insideItem, innerindex) => {
                            return (
                              <React.Fragment key={innerindex + row}>
                                <td>
                                  <div className={style.body_box}>
                                    <label className="custom-checkbox">
                                      <input
                                        type="checkbox"
                                        name={`${row}${`column${innerindex}`}`}
                                        // checked={row[insideItem][`column${index+2}`]}
                                        checked={
                                          filteredData[row][innerindex].isTrue
                                        }
                                        onChange={() => {
                                          let arr = { ...filteredData };
                                          arr[row][innerindex].isTrue =
                                            !arr[row][innerindex].isTrue;
                                          setFilteredData(arr);
                                        }}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                </td>
                              </React.Fragment>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className={style.link_button_container}>
                <Button
                  disabled={false}
                  text="Submit"
                  buttonType={buttonStyle.button_primary}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkModal;
