export const REPORT_MANAGEMENT = {
    HEADING: {
        TITLE: 'Reports',
        DESCRIPTION: '',
    },
    FILTERS: {
        SEARCH: {
            PLACEHOLDER: "Search Report"
        }
    },
    DELETE_REPORT: {
        TITLE: "Delete Report",
        DESCRIPTION: "Are you sure you want to delete this report?"
    },
    ADD_REPORT_CONTENT_ID: {
        HEADING: {
            TITLE: 'Add Content Id',
            DESCRIPTION: '',
        },
        BUTTONS: {
            TITLE: 'Add Content id'
        }
    },
    EDIT_REPORT_CONTENT_ID: {
        BACK: {
            TITLE: 'Edit Content',
            DESCRIPTION: 'Back to resident report',
        },
        HEADING: {
            TITLE: 'User Details',
            DESCRIPTION: '',
        },
        SUB_HEADING: {
            TITLE: 'User Profile Details',
            DESCRIPTION: '',
        },
        PROFILE_DETAILS: {
            TITLE: 'Astrology Profile Listing Person',
            DESCRIPTION: '',
        },
        BUTTONS: {
            TITLE: 'Update Content'
        },
        UPLOAD: {
            TITLE: 'User Image'
        }
    },
    VIEW_REPORT_CONTENT_ID: {
        BACK: {
            TITLE: 'View Property Report',
            DESCRIPTION: 'Back to property report list',
        },
        HEADING: {
            TITLE: 'User Profile Details',
            DESCRIPTION: '',
        },
        BUTTONS: {
            DELETE_TITLE: 'Delete Report'
        },
        UPLOAD: {
            TITLE: 'User Image'
        }
    },
    UPDATE_ASTROLOGY_PROFILE: {
        HEADING: {
            TITLE: 'Update Astrology Profile Details',
            DESCRIPTION: '',
        },
        BUTTONS: {
            TITLE: 'Update Profile'
        },
    }
}