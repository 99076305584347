import AWS from "aws-sdk";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import { generateUUID } from "../../../../hooks/uniqueIdGenerator";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { addUser } from "../../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions";
import { getUserTypes } from "../../../../redux/AurLaVieAdmin/userType/userType.action";
import { isLoading } from "../../../../redux/progressLoader/progressLoader.actions";
import Back from "../../../../shared/components/backButton/backButton";
import BetaAddProperty from "../../../../shared/components/betaAddProperty/betaAddProperty";
import BetaAddResident from "../../../../shared/components/betaAddResident/betaAddResident";
import BetaConfirmation from "../../../../shared/components/betaConfirmation/betaConfirmation";
import Button from "../../../../shared/components/button/button";
import buttonStyle from "../../../../shared/components/button/button.module.scss";
import Confirmation from "../../../../shared/components/confirmation/confirmation";
import Heading from "../../../../shared/components/heading/heading";
import ImageUpload from "../../../../shared/components/imageUpload/imageUpload";
import Input from "../../../../shared/components/inputFields/input";
import CenterModal from "../../../../shared/components/modal/centeredModal/centeredModal";
import {
  Born,
  Gender,
  SexualPreference,
  Status,
  Twins,
} from "../../../../shared/globalConstants/constants";
import { USER_MANAGEMENT } from "../userManagementConstants";
import style from "./addUser.module.scss";

const AddUser = () => {
  const inputFields = [
    {
      type: "text",
      name: "name",
      placeholder: "Name",
      label: "Name",
      required: true,
      width: "50%",
    },
    {
      type: "text",
      name: "email",
      placeholder: "Email ID",
      label: "Email ID",
      required: true,
      width: "50%",
    },
    {
      type: "password",
      name: "password",
      placeholder: "Password",
      label: "Password",
      required: true,
      width: "50%",
    },
    {
      type: "password",
      name: "passwordConfirm",
      placeholder: "Confirm Password",
      label: "Confirm Password",
      required: true,
      width: "50%",
    },
    {
      type: "mui-date-picker",
      name: "dob",
      placeholder: "Date of Birth",
      label: "Date of Birth",
      width: "50%",
    },
    {
      type: "radio",
      name: "gender",
      placeholder: "Gender",
      label: "Gender",
      options: Gender,
      width: "50%",
    },
    {
      type: "radio",
      name: "twins",
      placeholder: "Are you twins?",
      label: "Are you twins?",
      options: Twins,
      width: "50%",
    },
    {
      type: "radio",
      name: "born",
      placeholder: "Born",
      label: "Born",
      options: Born,
      width: "50%",
    },
    {
      type: "radio",
      name: "sexualPreference",
      placeholder: "Sexual Preference",
      label: "Sexual Preference",
      options: SexualPreference,
      width: "50%",
    },
    {
      type: "radio",
      name: "status",
      placeholder: "Status",
      label: "Status",
      options: Status,
      width: "50%",
    },
  ];
  const [formData, setFormData] = useState({});
  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const [toastList, setToastList] = useState([]);
  const [appId, setAppId] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [searchpr, _] = useSearchParams();
  const [userId, setUserId] = useState("");
  const [removeImage, setRemoveImage] = useState(false);

  const app = searchpr.get("app");
  const userType = searchpr.get("userType");

  useEffect(() => {
    const selectedAppId = localStorage.getItem("appId");
    setAppId(selectedAppId);
  }, [appId]);

  useEffect(() => {
    const hasEmptyRequiredFields = inputFields
      .filter((field) => field.required)
      .some((field) => !formData[field.name]);
    setIsSubmitDisabled(hasEmptyRequiredFields);
  }, [inputFields, formData]);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
    region: process.env.REACT_APP_AWS_REGION,
  });

  const uploadToS3 = async (file, name) => {
    dispatch(isLoading(true));
    const id = generateUUID();
    const fileKey = `user-${id}.${file?.type.split("/")[1]}`;
    const params = {
      Body: file,
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: fileKey,
    };
    try {
      const upload = await myBucket.upload(params).promise();
      if (upload) {
        dispatch(isLoading(false));
        getUserType(axiosInstance, appId, upload.Location);
      }
    } catch (error) {
      // console.log(error);
      setToastList([
        {
          id: 0,
          // title: "Error",
          title: "Error while uploading the resume.",
          icon: "error",
        },
      ]);
      dispatch(isLoading(false));
    }
  };

  const handleFile = (url) => {
    if (url) {
      setUserImage(url);
    }
  };

  const handleInputChange = (fieldName, fieldValue) => {
    setFormData({ ...formData, [fieldName]: fieldValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (userImage) {
      uploadToS3(userImage, userImage.name);
    } else {
      getUserType(axiosInstance, appId);
    }
  };

  const getUserType = (axiosInstance, appId, imageUrl) => {
    dispatch(getUserTypes(axiosInstance, appId))
      .then((response) => {
        if (response?.length) {
          const filtered = response.filter((element) =>
            userType.includes(element.typeName)
          );
          formData["appId"] = appId;
          formData["userTypeId"] = filtered[0]._id;
          formData["modified_by"] = localStorage.getItem("userId");

          if (imageUrl && !imageUrl?.includes("undefined")) {
            formData["profilePic"] = imageUrl;
          } else {
            delete formData.profilePic;
          }

          const params = {
            isAdmin: true,
          };
          addUserDetails(axiosInstance, formData, params);
        }
      })
      .catch((error) => {
        setToastList([
          {
            id: 0,
            // title: "Fetching User Type Failed",
            title: error?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const addUserDetails = (axiosInstance, userDetails, params) => {
    if (userDetails.dob) {
      userDetails.dob = moment(userDetails.dob);
    }
    dispatch(addUser(axiosInstance, userDetails, params))
      .then((response) => {
        if (userType === "Beta User" && app === "Feng Shui")
          setBetaConfirmation(true);
        setShowConfirmation(true);
        setUserId(response?.data?._id);
        setFormData({});
        setRemoveImage(true);
      })
      .catch((error) => {
        setToastList([
          {
            id: 0,
            // title: "Error adding user",
            title: error.response.data.message,
            icon: "error",
          },
        ]);
      });
  };

  const onButtonClick = (clickedButton) => {
    if (clickedButton === "Ok") {
      setShowConfirmation(false);
      navigate("/userManagement");
    }
  };

  const [betaConfirmation, setBetaConfirmation] = useState(false);
  const [betaProperty, setBetaProperty] = useState(false);
  const [betaResident, setBetaResident] = useState(false);

  const onButtonConfirmation = (clickedButton) => {
    if (clickedButton === "Ok") {
      setBetaConfirmation(false);
      setBetaProperty(true);
    }
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const emailRegex = /^[a-z0-9.]+@[a-z]+\.[a-z]{1,3}$/;
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
    const nameRegex = /^[a-zA-Z ]+$/;
    if (name === "name") {
      if (value === "") {
        setFormErrors({ ...formErrors, name: "Name is required" });
      } else if (value.length > 50) {
        setFormErrors({ ...formErrors, name: "Maximum 50 characters allowed" });
      } else if (!nameRegex.test(value)) {
        setFormErrors({ ...formErrors, name: "Only alphabets allowed" });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    } else if (name === "email") {
      if (value === "") {
        setFormErrors({ ...formErrors, email: "Email is required" });
      } else if (!emailRegex.test(value)) {
        setFormErrors({ ...formErrors, email: "Invalid Email" });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    } else if (name === "password") {
      if (value !== "" && value.length < 8) {
        setFormErrors({
          ...formErrors,
          password: "Password must be at least 8 characters",
        });
      } else if (value === "") {
        setFormErrors({ ...formErrors, password: "Password is required" });
      } else if (!passwordRegex.test(value)) {
        setFormErrors({
          ...formErrors,
          password:
            "Please use atleast 1 uppercase, 1 lowercase, 1 special character, 1 numeric",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    } else if (name === "passwordConfirm") {
      if (value !== "" && value.length < 8) {
        setFormErrors({
          ...formErrors,
          passwordConfirm: "Password must be at least 8 characters",
        });
      } else if (value === "") {
        setFormErrors({
          ...formErrors,
          passwordConfirm: "Password is required",
        });
      } else if (!passwordRegex.test(value)) {
        setFormErrors({
          ...formErrors,
          passwordConfirm:
            "Please use atleast 1 uppercase, 1 lowercase, 1 special character, 1 numeric",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <CenterModal
          isOpen={showConfirmation}
          onClose={() => {
            navigate("/userManagement");
            // setShowConfirmation(false)
            // setBetaConfirmation(false)
            // setBetaProperty(false)
            // setBetaResident(false)
          }}
          minWidth={"300px"}
        >
          {userType === "Beta User" && app === "Feng Shui" ? (
            <>
              {betaConfirmation && (
                <BetaConfirmation
                  title={"User Created Successfully!"}
                  description={
                    "User has been created and to change password, link set"
                  }
                  description2={"to the given email id"}
                  onButtonClick={onButtonConfirmation}
                />
              )}

              {betaProperty && (
                <BetaAddProperty
                  title={"Add Property"}
                  description={
                    "Pellentesque at ipsum ac felis suscipit sollicitudin nec eu ex."
                  }
                  setBetaProperty={setBetaProperty}
                  setBetaResident={setBetaResident}
                  setToastList={setToastList}
                  setShowConfirmation={setShowConfirmation}
                  userId={userId}
                />
              )}

              {betaResident && (
                <BetaAddResident
                  title={"Add Resident"}
                  description={
                    "Pellentesque at ipsum ac felis suscipit sollicitudin nec eu ex."
                  }
                  setBetaResident={setBetaResident}
                  setShowConfirmation={setShowConfirmation}
                  setToastList={setToastList}
                  userId={userId}
                />
              )}
            </>
          ) : (
            <Confirmation
              title={"User Created Successfully!"}
              description={
                "User has been created and login credentials has been sent to the given email id"
              }
              onButtonClick={onButtonClick}
            />
          )}
        </CenterModal>
        <div className={style.back}>
          <Back
            title={USER_MANAGEMENT.ADD_USER.BACK.TITLE}
            description={USER_MANAGEMENT.ADD_USER.BACK.DESCRIPTION}
          />
        </div>
        <div className={style.heading}>
          <Heading
            title={USER_MANAGEMENT.ADD_USER.HEADING.TITLE}
            description={USER_MANAGEMENT.ADD_USER.HEADING.DESCRIPTION}
          />
          {(app || userType) && (
            <div className={style.app_user}>
              <img src="/icons/info_blue.svg" alt="info" width={30} />
              <span className={style.app}>
                {app === "Feng Shui" ? "Feng Shui App" : app}
              </span>
              <span className={style.user}>
                {app === "Feng Shui" ? userType : null}
              </span>
            </div>
          )}
        </div>
        <div className={style.form_container}>
          <div className={style.upload}>
            <ImageUpload
              handleFile={handleFile}
              removeImage={removeImage}
              title={USER_MANAGEMENT.ADD_USER.UPLOAD.TITLE}
            />
          </div>
          <form onSubmit={handleSubmit}>
            {inputFields.map((inputField, index) => (
              <div
                key={index}
                className={style.input_field}
                style={{ width: inputField.width }}
              >
                <Input
                  disablePast={false}
                  type={inputField.type}
                  name={inputField.name}
                  label={inputField.label}
                  value={
                    inputField.name === "email"
                      ? formData[inputField.name]?.toLowerCase()
                      : formData[inputField.name]
                  }
                  placeholder={inputField.placeholder}
                  required={inputField.required}
                  onChange={(value) =>
                    handleInputChange(inputField.name, value)
                  }
                  options={inputField.options}
                  handleInputBlur={handleInputBlur}
                  disableFuture={true}
                  views={["year", "day", "hours", "minutes"]}
                  openTo={"year"}
                  ampmRequired={false}
                />
                <div className={style.error}>{formErrors[inputField.name]}</div>
              </div>
            ))}
            <div className={style.action_button}>
              <Button
                text={USER_MANAGEMENT.ADD_USER.BUTTONS.TITLE}
                disabled={
                  isSubmitDisabled ||
                  Object.values(formErrors).some((error) => Boolean(error))
                }
                buttonType={
                  isSubmitDisabled ||
                  Object.values(formErrors).some((error) => Boolean(error))
                    ? `${buttonStyle.button_disabled}`
                    : `${buttonStyle.button_primary}`
                }
              />
            </div>
          </form>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </div>
  );
};

export default AddUser;
