export const endpoints = {
  // adminUser ENDPOINT
  adminUser: "/admin",
  adminUserLogin: "/adminUser/login",
  adminUserForgetPassword: "/adminUser/forget-password",
  adminUserResetPassword: "/adminUser/reset-password",
  adminUserEditProfile: "/adminUser/profileUpdate/",
  adminUserChangePasswordProfile: "/adminUser/profilePasswordChange/",

  //subscription

  addSubscriptionDetail: "/usersubscriptions/addusersubAdmin",
  cancelSubscription: "/usersubscriptions/cancelsubscription",
  updateSubsDate: "/usersubscriptions/updatesubsdate",

  //user

  userListing: "/user/getUsers",
  addUser: "/user/adduser",
  editUser: "/user/edituser/",
  viewUser: "/user/getuserdetails",
  deleteUser: "/adminUser/delete",
  addAstrologerProfile: "/userprofiles/addprofileViaAdmin",
  deleteProfileViaAdmin: "/userprofiles/deleteViaAdmin/",
  updateAstrologerProfile: "/userprofiles/edituserprofile/",
  updateAstrologerProfileViaAdmin:"adminUserProfiles/edituserprofile/",
  getAdminPropertyType: "/propertyType/getPropertyTypes",
  betaUserCreatePropertyProfile: "/propertyProfile/create",
  betaUserEditPropertyProfile: "/propertyProfile/update/",
  getBetaUserPropertyProfile: "/propertyProfile/getUserProperty",
  getPropertyResidentLinkUp: "/resident/getPropertyResidentLinkUp",
  linkingPropertyResidentLinkUp: "/resident/propertyResidentLinkUp/",
  getSingleBetaUserPropertyProfile:
    "/propertyProfile/getAdminPropertyProfileDetails/",
  deleteBetaUserPropertyProfile: "/propertyProfile/deletePropertyById",
  betaUserCreateResident: "/adminUserProfiles/addResident",
  getSinglebetaUserResident: "/adminUserProfiles/getprofiledetails/",
  betaUserEditResident: "/adminUserProfiles/editprofile/",
  getbetaUserResident: "/adminUserProfiles/getUserResident",
  deletebetaUserResident: "/adminUserProfiles/delete",

  // tips
  tipsListing: "/adminTips/getTips",
  addTip: "/adminTips/createTip",
  viewTip: "/adminTips/getTipById/",
  deleteTip: "/adminTips/deleteTipById",
  editTip: "/adminTips/updateTip/",

  //adminCategory
  adminCategoryListing: "/adminCategories/getCategorylist",
  addAdminCategory: "/adminCategories/createCategory",
  viewAdminCategory: "/adminCategories/getCategoryById/",
  editAdminCategory: "/adminCategories/updateCategory/",
  deleteAdminCategory: "/adminCategories/deleteCategoryById",

  //notification listing
  notificationListing: "/notifications/getnotifications",
  addNotification: "/notifications/addnotification",
  viewNotification: "/notifications/getnotification",
  updateNotification: "/notifications/editnotification",
  deleteNotification: "/notifications/delete",

  //advices routes
  adviceListing: "/adminAdvices/getAlladvices",
  deleteAdviceNestingList: "/advices/handleDeleteChilds",
  viewAdviceByID: "/adminAdvices/getAdviceById",
  addAdvices: "/adminAdvices/add",
  updateAdvices: "/adminAdvices/edit",

  //ContentId routes
  contentIdListing: "/adminAdvices/advicecontent/getContentIds",
  viewContentIdData: "/adminAdvices/advicecontent/getContentIdData",
  editContentIdData: "/adminAdvices/advicecontent/edit",
  addContentIdData: "/adminAdvices/advicecontent/add",

  //content management

  addContent: "/contents/add",
  viewContent: "/contents",
  updateContent: "/contents",

  /**
   * Utilities
   */

  getLanguages: "/languages",
  getAllLanguages: "/languages/getAll",
  getAccessBasedMenu: "/rolePermissions/getUserPermissions/",

  /**
   * Get Apps Url
   */
  getApps: "/apps",
  getAllApps: "/apps/getAll",
  getAppById: "/apps/getAppDetailsById/",

  /**
   * Get User Types
   */
  getUserTypes: "/userTypes",

  /**
   * Books
   */
  getBooks: "adminBooks",
  deleteBook: "adminBooks/delete",
  viewBook: "adminBooks/",
  viewBookSection: "adminBooks/sections/",
  updateBook: "adminBooks/",
  addBook: "adminBooks/add",
  addBookSection: "adminBooks/sections/add",
  deleteBookSection: "adminBooks/sections/delete",

  /**
   * Get Help
   */
  getHelp: "getHelp/admin",
  editHelp: "getHelp/",
  addHelp: "getHelp/add",
  deleteHelp: "getHelp/delete",

  /**
   * Roles
   */
  getRoles: "rolePermissions",
  addRole: "roles/add",
  editRole: "roles/edit/",
  deleteRole: "roles/delete",
  permissions: "permissions/getpermissions",
  roles: "roles",

  /**
   * SubAdmin
   */
  getSubAdmins: "adminUser/getusers",
  viewSubAdmin: "adminUser/getuser/",
  addSubAdmin: "adminUser/adduser",
  editSubAdmin: "adminUser/edituser/",
  deleteSubAdmin: "adminUser/deladmin",

  /**
   * Reports
   */
  getAllReports: "adminReportContentId/getContentId",
  viewReportsContentId: "adminReportContent/detail/",
  addReportsContentId: "adminReportContentId/create",
  editReportsContentId: "adminReportContent/update/",
  getReports: "adminReportContent/getReports",
  deleteReport: "adminReportContentId/delete",

  /**
   * Report Categories
   */
  getReportCategories: "adminReportCategory/getCategory",
};
