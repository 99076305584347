import React, { useEffect, useState } from 'react'
import style from './EditTips.module.scss'
import { TIPS } from '../Tips/tipsManagementConstant'
import Back from '../../../../shared/components/backButton/backButton'
import ImageUpload from '../../../../shared/components/imageUpload/imageUpload'
import Input from '../../../../shared/components/inputFields/input'
import Button from '../../../../shared/components/button/button'
import buttonStyle from '../../../../shared/components/button/button.module.scss'
import { Status } from '../../../../shared/globalConstants/constants'
import useAxiosInstance from '../../../../hooks/useAxiosInstance'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { editTip, viewTip } from '../../../../redux/FengShui/manageTips/manageTips.actions'
import Toaster from '../../../../components/Global/Toaster/Toaster.component'
import { fetchAdminCategoryListing } from '../../../../redux/FengShui/adminCategory/adminCategory.actions'
import { selectAdminCategoryListingData } from '../../../../redux/FengShui/adminCategory/adminCategory.selectors'
import { isLoading } from '../../../../redux/progressLoader/progressLoader.actions'
import AWS from "aws-sdk"
import { generateUUID } from '../../../../hooks/uniqueIdGenerator'
import moment from 'moment'

const EditTips = () => {
  const [formData, setFormData] = useState({})
  const [toastList, setToastList] = useState([])
  const axiosInstance = useAxiosInstance()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [mainImage, setMainImage] = useState("")
  const [subImages1, setSubImages1] = useState("")
  const [subImages2, setSubImages2] = useState("")
  const [subImages3, setSubImages3] = useState("")
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()


  const adminCategoryList = useSelector(selectAdminCategoryListingData)
  const category = adminCategoryList?.listing?.listingData?.map(value => {
    return {
      value: value?._id,
      label: value?.categoryName
    }
  })

  useEffect(() => {
    const queryParams = {
      isAdmin: true,
    }
    viewTipDetail(axiosInstance, queryParams)
  }, [])

  const viewTipDetail = (axiosInstance, params) => {
    dispatch(viewTip(axiosInstance, id, params)).then((response) => {
      const newFormData = {
        "Tip Name": response?.title,
        "Tip Content": response?.description,
        "Category": {
          label: response?.categoryId?.categoryName,
          value: response?.categoryId?._id
        },
        "status": response?.status,
        "imgPath": response?.imgPath,
        "subImages": response?.subImages,
        "created_at": moment(response?.created_at).format("MM / DD / YYYY")
      }
      setFormData({ ...formData, ...newFormData })
    }).catch((error) => {
      setToastList([
        {
          id: 0,
          title: error.response.data.message,
          icon: "error",
        },
      ]);
    });
  }

  const inputFields = [
    {
      type: "text",
      name: "Tip Name",
      label: 'Tip Name',
      required: false,
      width: '100%'
    },
    {
      type: "textarea",
      name: "Tip Content",
      label: "Tip Content",
      width: "100%",
    },
    {
      type: "dropdown",
      name: "Category",
      options: category,
      placeholder: 'Select Category',
      label: 'Category',
      width: '50%'
    },
    {
      type: "text",
      name: "created_at",
      label: 'Created On',
      width: '50%',
      isDisabled: true
    },
    {
      type: "radio",
      name: "status",
      label: 'Status',
      options: Status,
      width: '50%'
    },
  ]

  useEffect(() => {
    if(state?.getLanguages?.languageId) {
      const parms = {
        "languageId": state?.getLanguages?.languageId,
        "limit": 10,
        "page": 1
      }
      dispatch(fetchAdminCategoryListing(axiosInstance, {}, parms))
    }
  }, [state?.getLanguages])

  const handleInputChange = (fieldName, fieldValue) => {
    setFormData({ ...formData, [fieldName]: fieldValue });
  }

  const handleFile = (url) => {
    if (url || url === "") {
        setMainImage(url);
    }
}

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  })

  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
    region: process.env.REACT_APP_AWS_REGION,
  })

  const handleUpdate = (imageUrl, img1, img2, img3) => {
    // const appId = localStorage.getItem("appId") // Getting Undefine
    const userId = state?.user?.data?._id

    const tipDetails = {
      "languageId": state?.getLanguages?.languageId,
      "appId": localStorage.getItem("appId"),
      "categoryId": formData?.Category?.value,
      "title": formData['Tip Name'],
      "description": formData['Tip Content'],
      "subImages": [],
      "status": formData?.status,
      "modified_by": userId,
    }
    if (imageUrl && !imageUrl?.includes('undefined')) {
      tipDetails['imgPath'] = imageUrl;
    } else {
      tipDetails['imgPath'] = mainImage === " " ? '' : mainImage || formData.imgPath;
    }

    if (img1 && !img1?.includes('undefined')) {
      tipDetails['subImages'][0] = img1;
    } else {
      tipDetails['subImages'][0] = subImages1 === " " ? '' : subImages1 || formData?.subImages?.[0];
    }

    if (img2 && !img2?.includes('undefined')) {
      tipDetails['subImages'][1] = img2;
    } else {
      tipDetails['subImages'][1] = subImages2 === " " ? '' : subImages2 || formData?.subImages?.[1];
    }

    if (img3 && !img3?.includes('undefined')) {
      tipDetails['subImages'][2] = img3;
    } else {
      tipDetails['subImages'][2] = subImages3 === " " ? '' : subImages3 || formData?.subImages?.[2];
    }
    dispatch(editTip(axiosInstance, tipDetails, id, {}))
      .then((res) => {
        setToastList([
          {
            id: 0,
            title: res.message,
            icon: "success",
          },
        ])
        setTimeout(() => {
          navigate(-1)
        }, 1500)
        const newFormData = {
          "Tip Name": res?.data?.title,
          "Tip Content": res?.data?.description,
          "Category": {
            label: res?.data?.categoryId?.categoryName,
            value: res?.data?.categoryId?._id
          },
          "status": res?.data?.status,
          "imgPath": res?.data?.imgPath,
          "subImages": res?.data?.subImages,
          "created_at": moment(res?.data?.created_at).format("MM / DD / YYYY")
        }
        setFormData({ ...formData, ...newFormData })
      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            // title: "Editing Tip Failed",
            title: err?.response?.data?.message,
            icon: "error",
          },
        ])
      })
  }

  const uploadToS3 = async (file, name) => {
    const id = generateUUID();
    const fileKey = `tip-main-${id}.${file?.type.split("/")[1]}`;
    const params = {
      Body: file,
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: fileKey,
    }
    try {
      dispatch(isLoading(true))
      const upload = await myBucket.upload(params).promise()
      if (upload) {
        dispatch(isLoading(false))
        return upload.Location
      }
    } catch (error) {
      setToastList([
        {
          id: 0,
          // title: "Error",
          title: "Error while uploading the Tip image.",
          icon: "error",
        },
      ]);
      dispatch(isLoading(false));
    }
  }

  const onUpdate = (e) => {
    e.preventDefault()
    const fun = async () => {
      let img = ''
      let img1 = ''
      let img2 = ''
      let img3 = ''
      if(mainImage?.name) img = await uploadToS3(mainImage, mainImage.name)
      if(subImages1?.name) img1 = await uploadToS3(subImages1, subImages1.name)
      if(subImages2?.name) img2 = await uploadToS3(subImages2, subImages2.name)
      if(subImages3?.name) img3 = await uploadToS3(subImages3, subImages3.name)
      handleUpdate(img, img1, img2, img3)
    }
    fun()
  }

  const handleSubFile1 = (url) => {
    if (url || url === "") {
      setSubImages1(url)
    }
  }
  const handleSubFile2 = (url) => {
    if (url || url === "") {
      setSubImages2(url)
    }
  }
  const handleSubFile3 = (url) => {
    if (url || url === "") {
      setSubImages3(url)
    }
  }

  useEffect(() => {
    if(formData?.["Tip Name"] !== "" 
        && formData?.["Tip Name"] !== undefined  
        && formData?.["Tip Content"] !== "" 
        && formData?.["Tip Content"] !== undefined 
        && formData?.["status"] !== "" 
        && formData?.["status"] !== undefined 
        && formData?.["Category"]?.value !== ""
        && formData?.["Category"]?.value !== undefined) {
            setIsSubmitDisabled(false)
        } else {
        setIsSubmitDisabled(true)
    }
}, [formData])

  return ( 
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className={style.back}>
          <Back title={TIPS.EDIT_TIPS.BACK.TITLE} description={TIPS.EDIT_TIPS.BACK.DESCRIPTION} />
        </div>
        <div className={style.form_container} style={{ marginTop: '2rem' }}>
          <div className={style.upload}>
            <ImageUpload handleFile={handleFile} title={TIPS.EDIT_TIPS.UPLOAD.TITLE} id={'mainimage1'} previewImage={formData?.imgPath} />
            <div className={style.smallUpload}>
              <ImageUpload height={'64px'} width={'64px'} backgroundSize={'cover'} padding={'10px'} handleFile={handleSubFile1} id={'mainimage2'} previewImage={formData?.subImages?.[0]} />
              <ImageUpload height={'64px'} width={'64px'} backgroundSize={'cover'} padding={'10px'} handleFile={handleSubFile2} id={'mainimage3'} previewImage={formData?.subImages?.[1]} />
              <ImageUpload height={'64px'} width={'64px'} backgroundSize={'cover'} padding={'10px'} handleFile={handleSubFile3} id={'mainimage4'} previewImage={formData?.subImages?.[2]} />
            </div>
          </div>
          <form onSubmit={onUpdate}>
            <div className={style.form_container}>
              {inputFields.map((inputField, index) => (
                <div key={index} className={style.input_field} style={{ width: inputField.width }}>
                  <Input
                    type={inputField.type}
                    name={inputField.name}
                    label={inputField.label}
                    value={formData[inputField?.name]}
                    placeholder={inputField.placeholder}
                    options={inputField.options}
                    required={inputField.required}
                    isDisabled={inputField.isDisabled}
                    onChange={(value) => handleInputChange(inputField?.name, value)}
                  />
                  {/* <div className={style.error}>{formErrors[inputField.name]}</div> */}
                </div>
              ))}
              <div className={style.action_button}>
                <Button disabled={isSubmitDisabled} text={TIPS.EDIT_TIPS.BUTTONS.TITLE} buttonType={isSubmitDisabled ? `${buttonStyle.button_disabled}` : `${buttonStyle.button_primary}`} />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </div>
  )
}

export default EditTips