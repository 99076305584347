export const ADVICE_MANAGEMENT = {
  HEADING: {
    TITLE: "Advice Management",
    DESCRIPTION: "",
  },
  FILTERS: {
    SEARCH: {
      PLACEHOLDER: "Search",
    },
  },
  ACTIVITY_CARDS: {
    BACK: {
      TITLE: "Back to section list",
      DESCRIPTION: "View Occassion",
    },

    ADD_BUTTON: {
      TITLE: "Add Card"
    }
  },
  VIEW_ACTIVITY_CARD: {
    HEADING: {
      DESCRIPTION: "",
    },
    BACK: {
      TITLE: "Back to card list",
      DESCRIPTION: "View Activity",
    },
    IMAGE: {
      TITLE: "Image"
    },
    BUTTONS: {
      EDIT: {
        TITLE: "Edit Activity",
      },
      DELETE: {
        TITLE: "Delete Activity",
      }
    }
  },
  EDIT_ACTIVITY_CARD: {
    HEADING: {
      TITLE: "Edit Activity",
      DESCRIPTION: "",
    },
    IMAGE: {
      TITLE: "Main Image"
    },
    BUTTONS: {
      SUBMIT: {
        TITLE: "Save Changes",
      },
    }
  },
  ADD_ACTIVITY_CARD: {
    HEADING: {
      TITLE: "Add Activity",
    },
  },
  CONTENT_LISTING: {
    HEADING: {
      DESCRIPTION: "",
    },
    BACK: {
      TITLE: "Back to advice management listing",
      DESCRIPTION: "View Content Id",
    },
    BUTTONS: {
      ADD: {
        TITLE: "Add Content Id",
      }
    }
  },
  VIEW_CONTENT_DATA: {
    HEADING: {
      TITLE: "Food to Eat",
      DESCRIPTION: "",
    },
    BACK: {
      TITLE: "Back to card list",
      DESCRIPTION: "View Activity",
    },
    BUTTONS: {
      EDIT: {
        TITLE: "Edit Activity",
      },
      DELETE: {
        TITLE: "Delete Activity",
      }
    }
  },
  EDIT_CONTENT_DATA: {
    HEADING: {
      TITLE: "Food to Eat",
      DESCRIPTION: "",
    },
    BACK: {
      TITLE: "Back to view detail section",
      DESCRIPTION: "Edit Activity Detail",
    },
    BUTTONS: {
      SAVE: {
        TITLE: "Save Changes",
      },
    }
  },
  ADD_CONTENT_DATA: {
    HEADING: {
      TITLE: "Add Content Id",
      DESCRIPTION: "",
    },
    BACK: {
      TITLE: "Back to content id listing",
      DESCRIPTION: "Add Content Id",
    },
    BUTTONS: {
      SAVE: {
        TITLE: "Add Content",
      },
    }
  }
};
