import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    data: null,
};

const userRolesSlice = createSlice({
    name: "userRoles",
    initialState,
    reducers: {
        getUserRolesuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        addUserRolesuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        editUserRolesuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        deleteUserRolesuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        getPermissionssuccess: (state, action) => {
            state.listing = action.payload;
            state.data = null;
        },
        resetUserRolesData: (state, action) => {
            state = {
                listing: [],
                data: null,
            };
        },
    },
});

export const {
    actions: userRolesAction,
    reducer: userRolesReducer,
    name: userRoles,
} = userRolesSlice;