import Button from "../button/button";
import style from "./betaAddResident.module.scss";
import buttonStyle from "../button/button.module.scss";
import Input from "../inputFields/input";
import { useDispatch } from "react-redux";
import {
  Gender2,
  SexualPreference2,
  Twins,
} from "../../globalConstants/constants";
import ImageUpload from "../imageUpload/imageUpload";
import { useEffect, useState } from "react";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import {
  betaUserAddResident,
  betaUserEditResident,
  getBetaUserPropertyList,
} from "../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import { generateUUID } from "../../../hooks/uniqueIdGenerator";
import { isLoading } from "../../../redux/progressLoader/progressLoader.actions";
import dayjs from "dayjs";

const BetaAddResident = ({
  title,
  description,
  userId,
  setBetaResident,
  setShowConfirmation,
  onviewPage,
  setShow,
  setToastList,
  singleData,
  onEditOpen,
  refresh,
}) => {
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [mainImage, setMainImage] = useState("");
  const [removeImage, setRemoveImage] = useState(false);
  const [propertyName, setPropertyName] = useState([
    { value: "", label: "Select Property Name" },
  ]);

  useEffect(() => {
    const params = {
      userId,
      page: 1,
    };
    dispatch(getBetaUserPropertyList(axiosInstance, {}, params))
      .then((response) => {
        const propertyList = response?.data?.data?.map((value) => ({
          value: value?._id,
          label: value?.propName,
        }));
        setPropertyName([
          { value: "", label: "Select Property Name" },
          ...propertyList,
        ]);
      })
      .catch((error) => {
        setToastList([
          {
            id: 0,
            // title: "Error Fetching User Property Details",
            title: error.response.data.message,
            icon: "error",
          },
        ]);
      });
  }, [userId, axiosInstance]);

  useEffect(() => {
    if (singleData && propertyName && onEditOpen) {
      const data = {
        Twins: singleData?.twins,
        "Resident Name": singleData?.name,
        Gender: Gender2.find(
          (item) =>
            item.value.toLowerCase() === singleData?.gender.toLowerCase()
        ),
        // "Birth Date": moment(singleData?.dob).format('YYYY-MM-DDTHH:mm'),
        "Birth Date": singleData?.dob ?  dayjs(singleData?.dob) : null ,
        "Sexual Orientation": SexualPreference2.find(
          (item) => item.value === singleData?.sexualPreference
        ),
        born: singleData?.born,
        "Property Name": singleData?.userPropertyName,
        imgPath: singleData?.profilePic,
      };
      setFormData(data);
    }
  }, [singleData, propertyName]);

  const inputFields = [
    {
      type: "text",
      name: "Resident Name",
      placeholder: "Resident Name",
      label: "Resident Name",
      required: true,
      width: "50%",
    },
    {
      type: "dropdown",
      name: "Property Name",
      options: propertyName,
      placeholder: "Select Property Name",
      label: "Property Name",
      required: true,
      width: "50%",
    },
    {
      type: "dropdown",
      name: "Gender",
      options: Gender2,
      placeholder: "Select Gender",
      label: "Gender",
      required: true,
      width: "50%",
    },
    {
      type: "mui-date-picker",
      name: "Birth Date",
      label: "Birth Date With Time",
      required: true,
      width: "50%",
    },
    {
      type: "dropdown",
      name: "Sexual Orientation",
      options: SexualPreference2,
      placeholder: "Select Sexual Orientation",
      label: "Sexual Orientation",
      required: true,
      width: "50%",
    },
    {
      type: "radio",
      name: "Twins",
      options: Twins,
      label: "Twins",
      required: true,
      width: "50%",
    },
  ];

  const handleInputChange = (fieldName, fieldValue) =>
    setFormData({ ...formData, [fieldName]: fieldValue });

  const onButtonClick = (e) => {
    e.preventDefault();
    if (mainImage?.name) {
      uploadToS3(mainImage, mainImage.name);
    } else {
      handleAdd();
    }
  };

  const handleAdd = (imageUrl) => {
    const data = {
      name: formData?.["Resident Name"],
      userId: userId,
      appId: localStorage.getItem("appId"),
      // born: 0,
      dob: formData?.["Birth Date"],
      twins: formData?.["Twins"],
      gender: formData?.["Gender"]?.value,
      sexualPreference: formData?.["Sexual Orientation"]?.value,
    };
    if (!onEditOpen)
      data["profilePic"] =
        imageUrl && !imageUrl?.includes("undefined")
          ? imageUrl
          : formData?.imgPath;
    if (onEditOpen) {
      if (imageUrl && !imageUrl?.includes("undefined")) {
        data["profilePic"] = imageUrl;
      } else {
        data["profilePic"] = mainImage || formData.imgPath;
      }
    }
    if (onEditOpen) {
      dispatch(
        betaUserEditResident(
          axiosInstance,
          data,
          {},
          singleData?._id
        )
      )
        .then((response) => {
          if (onviewPage) {
            setShow(false);
            setBetaResident(false);
            refresh();
          }
          setToastList([
            {
              id: 0,
              // title: "Adding User Resident",
              title: response.data.message,
              icon: "success",
            },
          ]);
        })
        .catch((error) => {
          setToastList([
            {
              id: 0,
              // title: "Error Adding User Resident",
              title: error.response.data.message,
              icon: "error",
            },
          ]);
        });
    } else {
      dispatch(
        betaUserAddResident(
          axiosInstance,
          data,
          {},
          formData?.["Property Name"]?.value
        )
      )
        .then((response) => {
          if (onviewPage) {
            setShow(false);
            setBetaResident(false);
            refresh();
          } else {
            setTimeout(() => {
              setBetaResident(false);
              setShowConfirmation(false);
              navigate("/userManagement");
            }, 1500);
          }
          setToastList([
            {
              id: 0,
              // title: "Adding User Resident",
              title: response.data.message,
              icon: "success",
            },
          ]);
        })
        .catch((error) => {
          setToastList([
            {
              id: 0,
              // title: "Error Adding User Resident",
              title: error.response.data.message,
              icon: "error",
            },
          ]);
        });
    }
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
    region: process.env.REACT_APP_AWS_REGION,
  });

  const uploadToS3 = async (file, name) => {
    const id = generateUUID();
    const fileKey = `user-resident-${id}.${file?.type.split("/")[1]}`;
    const params = {
      Body: file,
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: fileKey,
    };
    try {
      dispatch(isLoading(true));
      const upload = await myBucket.upload(params).promise();
      if (upload) {
        dispatch(isLoading(false));
        handleAdd(upload.Location);
      }
    } catch (error) {
      setToastList([
        {
          id: 0,
          // title: "Error",
          title: "Error while uploading the Resident image.",
          icon: "error",
        },
      ]);
      dispatch(isLoading(false));
    }
  };

  const handleFile = (url) => {
    if (url) {
      setMainImage(url);
    }
  };

  console.log(formData, "for check");

  useEffect(() => {
    if (
      formData?.["Resident Name"] !== "" &&
      formData?.["Resident Name"] !== undefined &&
      (onEditOpen ? formData?.["Property Name"] !== "" &&
      formData?.["Property Name"] !== undefined :  formData?.["Property Name"]?.value !== "" &&
      formData?.["Property Name"]?.value !== undefined) &&
      formData?.["Gender"]?.value !== "" &&
      formData?.["Gender"]?.value !== undefined &&
      formData?.["Birth Date"] !== "" &&
      formData?.["Birth Date"] !== undefined &&
      formData?.["Sexual Orientation"]?.value !== "" &&
      formData?.["Sexual Orientation"]?.value !== undefined &&
      formData?.["Twins"] !== "" &&
      formData?.["Twins"] !== undefined
    ) {
        
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [formData]);
// console.log("form...",formData)
  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <h2 className={style.title}>{title}</h2>
        <p className={style.description}>{description}</p>
        <div className={style.scroll}>

        <div style={{ padding: '10px 20px 80px 20px' }}>
          <div className={style.upload}>
            <ImageUpload
              handleFile={handleFile}
              removeImage={removeImage}
              previewImage={formData?.imgPath !== "" && formData?.imgPath}
              width={"calc(100% - 45px)"}
              height={"100px"}
              title={"Main Image"}
            />
          </div>
          <div className={style.action_buttons}>
            <div className={style.form_container}>
              <form onSubmit={onButtonClick}>
                {inputFields.map((inputField, index) => (
                  <div
                    key={index}
                    className={style.input_field}
                    style={{ width: inputField.width }}
                  >
                    {console.log("input", inputField, title)}
                    {inputField.name 
                    === "Property Name" &&
                    title === "Edit Resident" ? (
                      <div style={{marginLeft:'10px',marginBottom:'20px'}}>
                        <div style={{marginBottom:'18px',color:'#8D98AF',fontWeight:600}}>{inputField.name}</div>
                        <label style={{marginBottom:'-80px',color:'#8D98AF',fontWeight:600,opacity:'80%'}}>{formData[inputField.name]}</label>
                      </div>
                    ) : (
                      <Input
                        disablePast={false}
                        type={inputField.type}
                        name={inputField.name}
                        label={inputField.label}
                        value={formData[inputField?.name]}
                        placeholder={inputField.placeholder}
                        required={inputField.required}
                        onChange={(value) =>
                          handleInputChange(inputField.name, value)
                        }
                        options={inputField.options}
                        // handleInputBlur={handleInputBlur}
                        disableFuture={true}
                        views={["year", "day", "hours", "minutes"]}
                        openTo={"year"}
                        ampmRequired={true}
                      />
                    )}
                    {/* <div className={style.error}>{formErrors[inputField.name]}</div> */}
                  </div>
                ))}
                <div className={style.action_button}>
                  <Button
                    buttonTextStyle={{ fontWeight: 800 }}
                    text={title}
                    disabled={isSubmitDisabled}
                    buttonType={
                      isSubmitDisabled
                        ? `${buttonStyle.button_disabled}`
                        : `${buttonStyle.button_primary}`
                    }
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default BetaAddResident;
