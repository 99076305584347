const useExtractSubstring = (stringValue) => {
    const regex = /\d+/g;
    const firstFiveNumbers = stringValue?.match(regex).join('').substring(0, 5);
    return firstFiveNumbers;
}

export const extractSubstring = (stringValue) => {
    const regex = /\d+/g;
    const firstFiveNumbers = stringValue?.match(regex).join('').substring(0, 5);
    return firstFiveNumbers;
}

export default useExtractSubstring;