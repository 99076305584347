import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  listing: [],
  data: null,
};
const adminCategorySlice = createSlice({
  name: "manageAdminCategory",
  initialState,
  reducers: {
    manageAdminCategorySuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;
    },
    addAdminCategorySuccess: (state, action) => {
      state.data = action.payload;
    },
    editAdminCategorySuccess: (state, action) => {
      state.data = action.payload;
    },
    viewAdminCategorySuccess: (state, action) => {
      state.data = action.payload;
    },
    viewAdminCategoryDetailsSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetAdminCategoryDetail: (state, action) => {
      state.data = null;
    },
    resetAdminCategoryData: (state, action) => {
      state = {
        listing: [],
        data: null,
      };
    },
  },
});

export const {
  actions: adminCategoryActions,
  reducer: adminCategoryReducer,
  name: adminCategory,
} = adminCategorySlice;
