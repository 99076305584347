import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: null,
};
const manageContentSlice = createSlice({
  name: "manageContent",
  initialState,
  reducers: {
    addContentSuccess: (state, action) => {
      state.data = action.payload;
    },
    editContentSuccess: (state, action) => {
      state.data = action.payload;
    },
    viewContentDetailsSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetContentDetail: (state, action) => {
      state.data = null;
    },
  },
});

export const {
  actions: manageContentActions,
  reducer: manageContentReducer,
  name: manageContent,
} = manageContentSlice;
