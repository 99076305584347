import Back from "../../../../../shared/components/backButton/backButton";
import Button from "../../../../../shared/components/button/button";
import Heading from "../../../../../shared/components/heading/heading";
import { BOOKS_MANAGEMENT } from "../../booksManagementConstants";
import style from "./editBookSection.module.scss";
import buttonStyle from "../../.././../../shared/components/button/button.module.scss";
import Input from "../../../../../shared/components/inputFields/input";
import Editor from "../../../../../shared/components/editor/editor";
import { useState, useEffect } from "react";
import Chip from "../../../../../shared/components/chips/chips";
import {
  updateBookSection,
  viewBookSection,
} from "../../../../../redux/AurLaVieAdmin/books/books.action";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Toaster from "../../../../../components/Global/Toaster/Toaster.component";
import { axiosInstance } from "../../../../../constants/axiosInstance";
import { isLoading } from "../../../../../redux/progressLoader/progressLoader.actions";

const EditBookSection = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toastList, setToastList] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [chips, setChips] = useState(null);
  const [keywordsHeading, setKeywordsHeading] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    handleViewBookSection(axiosInstance);
  }, [axiosInstance]);

  const handleViewBookSection = (axiosInstance) => {
    dispatch(isLoading(true));
    dispatch(viewBookSection(axiosInstance, id))
      .then((res) => {
        dispatch(isLoading(false));
        setSectionName(res?.sectionName);
        setChips(
          res?.keywords.map((element) => {
            return { label: element };
          })
        );
        setDescription(res?.description);
        setKeywordsHeading(res?.keywordsHeading);
      })
      .catch((err) => {
        dispatch(isLoading(false));
        setToastList([
          {
            id: 0,
            // title: "Fething section Failed",
            title: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const newTag = event.target.value;
      setChips([...chips, { label: newTag }]);
      event.target.value = "";
      setFormData({});
    }
  };

  const remove = (chipToBeRemoved) => {
    const newChips = chips.filter((tag) => tag.label !== chipToBeRemoved.label);
    setChips(newChips);
  };

  const handleSave = () => {
    let errors = {};
    if (!sectionName.trim().length) {
      errors = { ...errors, sectionName: "Section Name is required" };
    } else {
      delete errors.sectionName;
    }

    // if (!chips.length) {
    //   errors = { ...errors, tags: "Tags are required" };
    // } else {
    //   delete errors.tags;
    // }

    // if (!keywordsHeading.trim().length) {
    //   errors = { ...errors, keywordsHeading: "Keyword Heading is required" };
    // } else {
    //   delete errors.keywordsHeading;
    // }

    if (!description.trim().length || description === "<p><br></p>") {
      errors = { ...errors, description: "Description is required" };
    } else {
      delete errors.description;
    }
    if (Object.keys(errors).length) {
      return setFormErrors({ ...errors });
    } else {
      setFormErrors({});
      const newArray = chips.map((element) => {
        return element.label;
      });
      const updatedDescription = description.replaceAll("<br>", "<br/>");
      const payload = {
        sectionName,
        keywords: newArray,
        keywordsHeading,
        description: updatedDescription,
      };
      dispatch(isLoading(true));
      dispatch(updateBookSection(axiosInstance, id, payload))
        .then((res) => {
          setToastList([
            {
              id: 0,
              // title: "Section Updated successfully",
              title: res?.message,
              icon: "success",
            },
          ]);
          dispatch(isLoading(false));
          setTimeout(() => {
            navigate(-1);
          }, 700);
        })
        .catch((err) => {
          dispatch(isLoading(false));
          console.log(err);
          setToastList([
            {
              id: 0,
              // title: "Updating section Failed",
              title: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    }
  };

  const handleInputChange = (fieldName, fieldValue) => {
    setFormData({ ...formData, [fieldName]: fieldValue });
  };

  return (
    <section className={style.container}>
      <div className={style.wrapper}>
        <div className={style.back}>
          <Back
            title={BOOKS_MANAGEMENT.EDIT_BOOK_SECTION.BACK.TITLE}
            description={BOOKS_MANAGEMENT.EDIT_BOOK_SECTION.BACK.DESCRIPTION}
          />
        </div>
        <section className={style.title_filters}>
          {/* <Heading
            title={sectionName}
            description={BOOKS_MANAGEMENT.EDIT_BOOK.HEADING.DESCRIPTION}
          /> */}
          <div className={style.filters}>
            <Button
              text={"Save Changes"}
              buttonType={`${buttonStyle.button_primary}`}
              marginTop={0}
              onClick={handleSave}
            />
          </div>
        </section>
        <form>
          <div className={style.input_field} style={{ width: "100%" }}>
            <Input
              type="text"
              name="sectionName"
              placeholder="Section Name"
              label="Section Name"
              width="50%"
              onChange={(data) => setSectionName(data)}
              value={sectionName}
            />
            <div className={style.error}>{formErrors["sectionName"]}</div>
          </div>
          <div className={style.input_field} style={{ width: "50%" }}>
            <div className={style.tags}>
              <Input
                type="text"
                name="tags"
                placeholder="Add tag and press enter"
                label="Tags"
                value={formData["tags"]}
                onKeyDown={handleKeyDown}
                onChange={(value) => handleInputChange("tags", value)}
              />
              {/* <img src="/icons/add.svg" alt="add" className={style.add} /> */}
              <div className={style.error}>{formErrors["tags"]}</div>
            </div>
          </div>
          <div className={style.input_field} style={{ width: "50%" }}>
            <Input
              type="text"
              name="keywordsHeading"
              placeholder="Tag Heading"
              label="Tag Heading"
              width="50%"
              onChange={(data) => setKeywordsHeading(data)}
              value={keywordsHeading}
            />
            <div className={style.error}>{formErrors["keywordsHeading"]}</div>
          </div>
          <div className={style.tags_container}>
            <Chip data={chips} onRemove={remove} showRemove={true} />
          </div>
          <div className={style.editor}>
            <h4 className={style.label}>Content</h4>
            <Editor
              setContent={(data) => setDescription(data)}
              content={description}
            ></Editor>
            <div className={style.error}>{formErrors["description"]}</div>
          </div>
        </form>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={3500}
      />
    </section>
  );
};

export default EditBookSection;
