import { Navigate } from "react-router-dom";
// import useAuth from '../hooks/useAuth.hook';

const PrivateRoute = ({ children, path }) => {
  const isUserLoggedIn = localStorage.getItem("keepMeLoggedIn");
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken && path !== "/login") return <Navigate exact to="/login" />;
  if (accessToken && isUserLoggedIn == null && path !== "/login")
    return <Navigate exact to="/login" />;

  return children;
};

export default PrivateRoute;
