import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { maskPhoneNumber } from "../../../../hooks/useMaskPhoneNumber";
import {
  deleteSubAdmin,
  getSubAdmins,
} from "../../../../redux/AurLaVieAdmin/subAdmin/subAdmin.action";
import Button from "../../../../shared/components/button/button";
import Delete from "../../../../shared/components/delete/delete";
import Heading from "../../../../shared/components/heading/heading";
import Input from "../../../../shared/components/inputFields/input";
import CenterModal from "../../../../shared/components/modal/centeredModal/centeredModal";
import Table from "../../../../shared/components/table/table";
import { SUB_ADMIN } from "../subAdminUserRolesContants";
import style from "./subAdmin.module.scss";

const SubAdmin = () => {
  const [subAdmins, setSubAdmins] = useState([]);
  const [toastList, setToastList] = useState([]);
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [showAddSubAdmin, setShowAddSubAdmin] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [ids, setIds] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const currentDeleteRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState(null);

  useEffect(() => {
    currentDeleteRef.current = null;
    const params = {
      page,
      limit: pageSize,
      isAdmin: true,
    };
    getAllSubAdmins(axiosInstance, params);
  }, [page]);

  useEffect(() => {
    let timerId;
    const fetchResults = async () => {
      try {
        setPage(1);
        const params = {
          page: 1,
          limit: pageSize,
        };
        getAllSubAdmins(axiosInstance, params, searchTerm);
      } catch (error) {
        console.log(error);
      }
    };

    if (searchTerm || searchTerm === "") {
      timerId = setTimeout(() => {
        fetchResults();
      }, 500);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  const getAllSubAdmins = (axiosInstance, params, searchTerm) => {
    dispatch(getSubAdmins(axiosInstance, params, searchTerm))
      .then((response) => {
        const updatedSubAdmins = response?.listingData?.map(
          (subAdmin, index) => {
            const newIndex = (page - 1) * pageSize + index + 1;
            return {
              id: subAdmin?._id,
              "S. No":
                newIndex > 100
                  ? newIndex
                  : newIndex < 100 && newIndex > 9
                    ? `0${newIndex}`
                    : `00${newIndex}`,
              image: subAdmin?.profilePic
                ? subAdmin?.profilePic
                : "/icons/user_blue.svg",
              name: subAdmin?.name,
              contactNumber: maskPhoneNumber(subAdmin?.contactNumber),
              emailId: subAdmin?.email,
              userRole: subAdmin?.roleId?.roleName,
            };
          }
        );
        setSubAdmins(updatedSubAdmins);
        setTotalItem(response?.meta?.total);
      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            // title: "Fetching SubAdmins Failed",
            title: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const onSearch = (event) => {
    setSearchTerm(event);
  };

  const handlePage = (page) => {
    setPage(page);
  };

  const handleMultiple = (event) => {
    setIds(event);
  };

  const closeModal = () => {
    setShowAddSubAdmin(false);
  };

  const addSubAdmin = () => {
    navigate("/subAdmin/addSubAdmin");
  };

  const onEdit = (row) => {
    navigate(`/subAdmin/editSubAdmin/${row.id}`);
  };

  const deletemany = () => {
    if (ids.length) {
      setShowDeleteModal(true);
    }
  };

  const onDelete = (data) => {
    currentDeleteRef.current = [data.id];
    setShowDeleteModal(true);
  };

  const handleDeleteRoles = (clicked) => {
    if (clicked === "Cancel") {
      setShowDeleteModal(false);
      currentDeleteRef.current = null;
    } else {
      const data = currentDeleteRef.current?.length
        ? { ids: currentDeleteRef.current }
        : { ids };

      dispatch(deleteSubAdmin(axiosInstance, data))
        .then((res) => {
          setShowDeleteModal(false);
          setIds(null);
          currentDeleteRef.current = null;
          const params = {
            isAdmin: true,
          };
          getAllSubAdmins(axiosInstance, params);
          setToastList([
            {
              id: 0,
              // title: "SubAdmin Deleted Successfully",
              title: res.message,
              icon: "success",
            },
          ]);
          //   setTimeout(() => {
          //     handleLanguages();
          //   }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setToastList([
            {
              id: 0,
              // title: "Delete SubAdmin Error",
              title: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    }
  };

  const onView = (row) => {
    navigate(`/subAdmin/viewSubAdmin/${row.id}`);
  };

  return (
    <section className={style.container}>
      <div className={style.wrapper}>
        <CenterModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          minWidth={"300px"}
        >
          <Delete
            title={SUB_ADMIN.DELETE.TITLE}
            description={SUB_ADMIN.DELETE.DESCRIPTION}
            onButtonClick={handleDeleteRoles}
          />
        </CenterModal>
        <section className={style.title_filters}>
          <Heading
            title={SUB_ADMIN.HEADING.TITLE}
            description={SUB_ADMIN.HEADING.DESCRIPTION}
            icon="/icons/subAdmin_blue.svg"
          />
          <div className={style.filters}>
            <Button
              icon={"/icons/add.svg"}
              text={SUB_ADMIN.BUTTONS.TITLE_ADD}
              onClick={addSubAdmin}
            />
            <Input
              type={"search"}
              placeholder={SUB_ADMIN.FILTERS.SEARCH.PLACEHOLDER}
              value={searchTerm}
              onChange={onSearch}
            />
            <Button icon={"/icons/delete.svg"} onClick={deletemany} />
          </div>
        </section>
        <section className={style.listing}>
          <Table
            data={subAdmins}
            onView={onView}
            onEdit={onEdit}
            onDelete={onDelete}
            noDataText={SUB_ADMIN.TABLE.NO_DATA}
            totalItem={totalItem}
            handlePage={handlePage}
            pageNumber={page}
            handleMultiple={handleMultiple}
          />
        </section>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </section>
  );
};

export default SubAdmin;
