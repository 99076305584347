// import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth.hook";
import { axiosInstance } from "../constants/axiosInstance";
import useRefreshToken from "./useRefreshToken.hook";
import { useDispatch } from "react-redux";
import { isLoading } from "../redux/progressLoader/progressLoader.actions";

const useAxiosInstance = () => {
  const refresh = useRefreshToken();
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { removeUserAuth } = useAuth();

  useEffect(() => {
    const requestIntercept = axiosInstance.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] =
            `Bearer ${localStorage.getItem("accessToken")}`;
        } else {
          dispatch(isLoading(true));
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosInstance.interceptors.response.use(
      (response) => {
        dispatch(isLoading(false));
        return response;
      },
      async (error) => {
        dispatch(isLoading(false));
        const prevRequest = error?.config;
        if (
          error?.response?.status === 401 &&
          !prevRequest?.sent &&
          localStorage.getItem("keepMeLoggedIn") === "true"
        ) {
          prevRequest.sent = true;
          const refreshResponse = await refresh();
          if (refreshResponse?.data?.token) {
            localStorage.setItem("accessToken", refreshResponse?.data?.token);
            prevRequest.headers["Authorization"] =
              `Bearer ${refreshResponse?.data?.token}`;
            return axiosInstance(prevRequest);
          } else if (refreshResponse?.response?.status === 401) {
            removeUserAuth();
            return navigateTo("/login");
          }
        } else if (
          error?.response?.status === 401 &&
          (localStorage.getItem("keepMeLoggedIn") === "false" ||
            localStorage.getItem("keepMeLoggedIn") == null)
        ) {
          removeUserAuth();
          navigateTo("/login");
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    };
  }, [refresh]);

  return axiosInstance;
};

export default useAxiosInstance;
