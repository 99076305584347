import { axiosInstance, axiosPrivate } from "../../constants/axiosInstance";
import { endpoints } from "../../constants/url.endpoint";
import { userActions } from "./user.reducer";

const { loginSuccess, logoutSuccess, editProfileSuccess } = userActions;

export const login = (userCredentials, params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    // try {
    axiosPrivate({
      url: endpoints.adminUserLogin,
      method: "POST",
      data: userCredentials,
      params: params,
    })
      .then((res) => {
        dispatch(loginSuccess(res?.data?.data));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const EditProfileAction = (axiosInstance, data, params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: endpoints.adminUserEditProfile + data._id,
      method: "PATCH",
      data,
      params,
    })
      .then((res) => {
        dispatch(editProfileSuccess(res?.data?.data));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const ChangePasswordAction = (axiosInstance, data, id, params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: endpoints.adminUserChangePasswordProfile + id,
      method: "POST",
      data,
      params,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const orgSignup = (userCredentials) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    // try {
    axiosPrivate({
      url: endpoints.orgSignup,
      method: "POST",
      data: userCredentials,
    })
      .then((res) => {
        // dispatch(loginSuccess(res?.data?.data));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgetPasswordAction = (userCredentials) => async () => {
  return new Promise((resolve, reject) => {
    axiosPrivate({
      url: endpoints.adminUserForgetPassword,
      method: "POST",
      data: userCredentials,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetPasswordAction = (userCredentials) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: `${endpoints.adminUserResetPassword + "/"}${
        userCredentials.urlToken
      }`,
      method: "POST",
      data: userCredentials,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const logout = () => async (dispatch) => {
  try {
    return dispatch(logoutSuccess(null));
  } catch (e) {
    //catch err
  }
};
