import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storageSession from "reduxjs-toolkit-persist/lib/storage/session";

import { postsReducer } from "./posts/posts.reducer";
import { userReducer } from "./users/user.reducer";
import { loaderReducer } from "./progressLoader/progressLoader.reducer";
import { filterReducer } from "./Common/tablefilter/tableFilter.reducer";
import { manageUsersReducer } from "./AurLaVieAdmin/manageUsers/manageUsers.reducer";
import { toasterReducer } from "./appToaster/appToaster.reducer";
import { myAccountReducer } from "./Common/myAccount/myAccount.reducer";
import { getAppsReducer } from "./AurLaVieAdmin/apps/getApps.reducer";
import { userTypesReducer } from "./AurLaVieAdmin/userType/userType.reducer";
import { manageNotificationReducer } from "./AurLaVieAdmin/manageNotifications/manageNotification.reducer";
import { getLanguageReducer } from "./AurLaVieAdmin/languages/getLanguages.reducer";
import { manageContentReducer } from "./AurLaVieAdmin/manageContent/manageContent.reducer";
import { getHelpReducer } from "./AurLaVieAdmin/getHelp/getHelp.reducer";
import { userRolesReducer } from "./AurLaVieAdmin/userRoles/userRoles.reducer";
import { manageAdvicesReducer } from "./AurLaVieAdmin/manageAdvices/manageAdvices.reducer";
import { manageTipsReducer } from "./FengShui/manageTips/manageTips.reducer";
import { adminCategoryReducer } from "./FengShui/adminCategory/adminCategory.reducer";
import { reportsReducer } from "./AurLaVieAdmin/reports/reports.reducer";
import ToggleReducer from './FengShui/toggle'
import AnotherLoadingReducer from './anotherLoading/anotherLoading'

const userPersistConfig = {
  key: "user",
  storage: storageSession,
};

const reducers = {
  user: persistReducer(userPersistConfig, userReducer),
  posts: postsReducer,
  loader: loaderReducer,
  tableFilters: filterReducer,
  manageUsers: manageUsersReducer,
  toaster: toasterReducer,
  myAccount: myAccountReducer,
  getApps: getAppsReducer,
  userTypes: userTypesReducer,
  notifications: manageNotificationReducer,
  getLanguages: getLanguageReducer,
  manageContent: manageContentReducer,
  getHelps: getHelpReducer,
  getRoles: userRolesReducer,
  manageAdvices: manageAdvicesReducer,
  manageTips: manageTipsReducer,
  adminCategory: adminCategoryReducer,
  reports: reportsReducer,
  toggle: ToggleReducer,
  anotherLoading: AnotherLoadingReducer
};

const reducer = combineReducers(reducers);
// const persistedReducer = persistReducer(rootPersistConfig, reducer);

export const store = configureStore({
  reducer: reducer,
  // reducer: persistReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
