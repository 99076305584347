import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../../hooks/useAxiosInstance";
import { useDispatch } from "react-redux";
import Heading from "../../../../../shared/components/heading/heading";
import ImageUpload from "../../../../../shared/components/imageUpload/imageUpload";
import Input from "../../../../../shared/components/inputFields/input";
import Button from "../../../../../shared/components/button/button";
import { TextAlign } from "../../../../../shared/globalConstants/constants";
import style from "./editActivityCards.module.scss";
import buttonStyle from "../../../../../shared/components/button/button.module.scss";
import {
  updateAdvicesActivity,
  addAdvices,
} from "../../../../../redux/AurLaVieAdmin/manageAdvices/manageAdvices.actions";
import { ADVICE_MANAGEMENT } from "../../advice.constant";
import AWS from "aws-sdk";
import { isLoading } from "../../../../../redux/progressLoader/progressLoader.actions";
import Toaster from "../../../../../components/Global/Toaster/Toaster.component";
import { generateUUID } from "../../../../../hooks/uniqueIdGenerator";
import { useParams } from "react-router-dom";

const EditActivityCards = ({
  handleClose,
  payload,
  id,
  isClosed,
  image,
  title,
  select,
}) => {
  const [formData, setFormData] = useState({});
  const { id: cardId, parentAdviceBool } = useParams();
  const topMostAdvice = parentAdviceBool === "true" ? true : false;
  const topMostUrl =
    window.location.href.indexOf("activityCards") < 0 ? true : false;

  const [contentType, setContentType] = useState([
    {
      value: "Child",
      label: "Child",
    },
    {
      value: "ContentId",
      label: "ContentId",
    },
  ]);

  const inputFields =
    !topMostAdvice && cardId
      ? [
          {
            type: "text",
            name: "adviceName",
            placeholder: "Activity Name",
            label: "Activity Name",
            width: "50%",
          },
          {
            type: "text",
            name: "adviceLabel",
            placeholder: "Advice Label",
            label: "Advice Label",
            width: "50%",
          },
          {
            type: "dropdown",
            name: "adviceType",
            options: contentType,
            placeholder: "Content Type",
            label: "Select Content Type",
            width: "50%",
          },
          {
            type: "text",
            name: "adviceOrder",
            placeholder: "Advice Order",
            label: "Advice Order",
            width: "50%",
          },
          {
            type: "textarea",
            name: "description",
            placeholder: "Content",
            label: "Content",
            width: "100%",
          },
        ]
      : [
          {
            type: "text",
            name: "adviceName",
            placeholder: "Activity Name",
            label: "Activity Name",
            width: "50%",
          },
          {
            type: "text",
            name: "adviceLabel",
            placeholder: "Advice Label",
            label: "Advice Label",
            width: "50%",
          },
        ];

  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const [toastList, setToastList] = useState([]);
  const [activityImage, setActivityImage] = useState();
  const [removeImage, setRemoveImage] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    if (select === "Edit") {
      setFormData(payload);
      setPreviewImage(image);
    }
  }, [select, cardId]);

  const handleInputChange = (fieldName, fieldValue) => {
    setFormData({ ...formData, [fieldName]: fieldValue });
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
    region: process.env.REACT_APP_AWS_REGION,
  });

  const uploadToS3 = async (file, name) => {
    const id = generateUUID();
    const fileKey = `activity-${id}.${file?.type.split("/")[1]}`;
    const params = {
      Body: file,
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: fileKey,
    };
    try {
      dispatch(isLoading(true));
      const upload = await myBucket.upload(params).promise();
      if (upload) {
        dispatch(isLoading(false));
        handleUpdate(upload.Location);
      }
    } catch (error) {
      setToastList([
        {
          id: 0,
          title: "Error while updating the activity.",
          icon: "error",
        },
      ]);
      dispatch(isLoading(false));
    }
  };

  const handleUpdate = (imageUrl, option) => {
    const languageId = localStorage.getItem("languageId");
    const appId = localStorage.getItem("appId");

    formData["languageId"] = languageId;
    formData["appId"] = appId;
    formData["parentId"] = payload?.parentId || null;

    if (formData?.adviceType?.value) {
      formData["adviceType"] = formData?.adviceType?.value;
    } else {
      formData["adviceType"] = payload?.adviceType;
    }

    formData["created_by"] = localStorage.getItem("userId");
    formData["modified_by"] = localStorage.getItem("userId");

    if (select === "Edit") {
      if (imageUrl) {
        formData["mainImgPath"] = imageUrl;
      } else {
        formData["mainImgPath"] =
          option === "blank"
            ? formData["parentId"] === null
              ? "no-image"
              : null
            : activityImage || image;
      }
      dispatch(updateAdvicesActivity(axiosInstance, formData, { id }))
        .then((res) => {
          setToastList([
            {
              id: 0,
              title: res.message,
              icon: "success",
            },
          ]);

          setTimeout(() => {
            setRemoveImage(true);
            setFormData({});
            handleClose();
          }, 500);
        })
        .catch((err) => {
          console.log(err, "err=========>");
          setToastList([
            {
              id: 0,
              // title: "Updating Card Failed",
              title: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    } else if (select === "Add") {
      if (imageUrl) {
        formData["mainImgPath"] = imageUrl;
      } else {
        delete formData.imageUrl;
      }
      if (topMostAdvice || topMostUrl) {
        formData["description"] = "null";
        formData["mainImgPath"] = "null";
      }
      formData["adviceLabel"] = formData?.adviceLabel
        ? formData?.adviceLabel?.replaceAll(" ", "-")
        : formData?.adviceName?.replaceAll(" ", "-");
      dispatch(addAdvices(axiosInstance, formData))
        .then((res) => {
          setToastList([
            {
              id: 0,
              // title: "Activity Added Successfully",
              title: res.message,
              icon: "success",
            },
          ]);

          setTimeout(() => {
            setRemoveImage(true);
            setFormData({});
            handleClose();
          }, 500);
        })
        .catch((err) => {
          console.log(err, "err=========>");
          setToastList([
            {
              id: 0,
              // title: "Adding Card Failed",
              title: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (activityImage?.name) {
      uploadToS3(activityImage, activityImage.name);
    } else if (activityImage === " ") {
      handleUpdate(null, "blank");
    } else {
      handleUpdate();
    }
  };

  const handleFile = (url) => {
    if (url) {
      setActivityImage(url);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className={style.heading}>
          <Heading
            title={title}
            description={
              ADVICE_MANAGEMENT.EDIT_ACTIVITY_CARD.HEADING.DESCRIPTION
            }
            textAlign={TextAlign.CENTER}
          />
        </div>
        <div className={style.form_container}>
          {!topMostAdvice && cardId && (
            <div className={style.upload}>
              <ImageUpload
                select={select}
                id={select === "edit" ? "editMainImage" : "addMainImage"}
                width={`180px`}
                height={`180px`}
                previewImage={previewImage}
                handleFile={handleFile}
                title={ADVICE_MANAGEMENT.EDIT_ACTIVITY_CARD.IMAGE.TITLE}
              />
            </div>
          )}
          <form
            onSubmit={handleSubmit}
            style={!topMostAdvice && cardId ? {} : { width: "100%" }}
          >
            {inputFields.map((inputField, index) => (
              <div
                key={index}
                className={style.input_field}
                style={{ width: inputField.width }}
              >
                <Input
                  rows={5}
                  type={inputField.type}
                  label={inputField.label}
                  value={formData[inputField.name]}
                  placeholder={
                    inputField.name === "adviceType" && select === "Edit"
                      ? formData[inputField.name]
                      : inputField.placeholder
                  }
                  onChange={(value) =>
                    handleInputChange(inputField.name, value)
                  }
                  options={inputField.options}
                />
              </div>
            ))}
            <div className={style.action_button}>
              <Button
                text={title}
                buttonType={`${buttonStyle.button_primary}`}
              />
            </div>
          </form>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={3500}
      />
    </div>
  );
};

export default EditActivityCards;
