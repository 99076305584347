import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    type: 'tips',
  }
  
  export const ToggleSlice = createSlice({
    name: 'toggle',
    initialState,
    reducers: {
      setType: (state, action) => {
        state.type = action.payload
      }
    },
  })
  
  export const { setType } = ToggleSlice.actions
  export default ToggleSlice.reducer