import AppSwitchButton from "../../../../../../shared/components/appSwitchButton/appSwitchButton";
import Back from "../../../../../../shared/components/backButton/backButton";
import Button from "../../../../../../shared/components/button/button";
import Heading from "../../../../../../shared/components/heading/heading";
import { ADVICE_MANAGEMENT } from "../../../advice.constant";
import style from "./editContent.module.scss";
import { useEffect, useRef, useState } from "react";
import useAxiosInstance from "../../../../../../hooks/useAxiosInstance";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import buttonStyle from "../../../../../../shared/components/button/button.module.scss";
import Editor from "../../../../../../shared/components/editor/editor";
import ImageUpload from "../../../../../../shared/components/imageUpload/imageUpload";
import Input from "../../../../../../shared/components/inputFields/input";
import { useParams } from "react-router-dom";
import {
  viewContentIdData,
  editContentIdData,
} from "../../../../../../redux/AurLaVieAdmin/manageAdvices/manageAdvices.actions";
import { isLoading } from "../../../../../../redux/progressLoader/progressLoader.actions";
import Chip from "../../../../../../shared/components/chips/chips";
import AWS from "aws-sdk";
import Toaster from "../../../../../../components/Global/Toaster/Toaster.component";
import { generateUUID } from "../../../../../../hooks/uniqueIdGenerator";

const ViewUser = () => {
  const { id } = useParams();
  const location = useLocation();
  const [toastList, setToastList] = useState([]);
  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const [contentDetails, setContentDetails] = useState({});
  const [containers, setContainers] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsValue, setTagsValue] = useState("");
  const [description, setDescription] = useState("");
  const [tagHeading, setTagHeading] = useState("");
  const [title, setTitle] = useState("");
  const [contentId, setContentId] = useState("");
  const [urlValue, setUrlValue] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = {
      isAdmin: true,
      id: id,
    };
    viewContentIdDetails(axiosInstance, queryParams);
  }, []);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
    region: process.env.REACT_APP_AWS_REGION,
  });

  const uploadToS3 = async (
    file,
    name,
    containerIndex,
    subContainerIndex,
    select
  ) => {
    const id = generateUUID();
    const fileKey = `content-${id}.${file?.type.split("/")[1]}`;
    const params = {
      Body: file,
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: fileKey,
    };
    try {
      dispatch(isLoading(true));
      const upload = await myBucket.upload(params).promise();
      if (upload) {
        dispatch(isLoading(false));
        if (select === "container") {
          handleInputChange(
            upload.Location,
            subContainerIndex,
            containerIndex,
            "img"
          );
        } else {
          handleMainContent(upload.Location, select);
        }
      }
    } catch (error) {
      setToastList([
        {
          id: 0,
          // title: "Error",
          title: "Error while uploading the resume.",
          icon: "error",
        },
      ]);
      dispatch(isLoading(false));
    }
  };

  const addContainer = () => {
    setContainers([...containers, { heading: "" }]);
  };

  const removeContainer = (index) => {
    const updatedContainers = [...containers];
    updatedContainers.splice(index, 1);
    setContainers(updatedContainers);
  };

  const addsubContainer = (index) => {
    let updatedContainers = JSON.parse(JSON.stringify([...containers]));

    if (!updatedContainers[index]?.images) {
      updatedContainers[index][`images`] = [];
    }
    updatedContainers[index]?.images?.push({ imgName: "", imgPath: "" });

    setContainers([...updatedContainers]);
  };

  const removeSubContainer = (index, containerDetails) => {
    let updatedContainers = JSON.parse(JSON.stringify([...containers]));
    updatedContainers[containerDetails].images.splice(index, 1);
    setContainers([...updatedContainers]);
  };

  const handleInputChange = (
    value,
    subContainerIndex,
    containerIndex,
    select
  ) => {
    let updatedContainers = JSON.parse(JSON.stringify([...containers]));

    if (select === "subcontainer") {
      updatedContainers[containerIndex].images[subContainerIndex].imgName =
        value;
    } else if (select === "img") {
      updatedContainers[containerIndex].images[subContainerIndex].imgPath =
        value;
    } else if (select === "heading") {
      updatedContainers[containerIndex].heading = value;
    }
    setContainers([...updatedContainers]);
  };

  const handleMainContent = (value, select) => {
    let updatedContainersHead = JSON.parse(JSON.stringify(contentDetails));

    if (select === "mainImage") {
      if (value === "" || value.includes("undefined")) {
        updatedContainersHead.mainImgPath = null;
      } else {
        updatedContainersHead.mainImgPath = value;
      }
    }
    if (select === "headImage") {
      if (value === "" || value.includes("undefined")) {
        updatedContainersHead.headImgPath = null;
      } else {
        updatedContainersHead.headImgPath = [value];
      }
    }

    setContentDetails(updatedContainersHead);
  };

  const handleFile = (url, containerIndex, subContainerIndex) => {
    if (url === " ") {
      handleInputChange("", subContainerIndex, containerIndex, "img");
    } else if (url && url.name) {
      uploadToS3(url, url.name, containerIndex, subContainerIndex, "container");
    }
  };

  const handleMainImage = (url, imageType) => {
    if (url === " ") {
      handleMainContent("", imageType);
    } else if (url && url.name) {
      uploadToS3(url, url.name, null, null, imageType);
    }
  };

  const viewContentIdDetails = (axiosInstance, params) => {
    dispatch(viewContentIdData(axiosInstance, params))
      .then((response) => {
        setDescription(response.content);
        setTitle(response.title);
        setContentId(response?.contentId);
        setUrlValue(response?.url);
        setButtonLabel(response?.buttonLabel);
        setTagHeading(response?.tagHeadings[0]?.heading);
        setTags(
          response?.tagHeadings[0]?.tags.map((element) => {
            return { label: element };
          })
        );
        setContainers(
          response.subHeadings.map((element) => {
            return { ...element };
          })
        );
        setContentDetails(response);
      })
      .catch((error) => {
        setToastList([
          {
            id: 0,
            // title: "Error fetching content",
            title: error.response.data.message,
            icon: "error",
          },
        ]);
      });
  };

  const remove = (chipToBeRemoved) => {
    const newChips = tags.filter((tag) => tag.label !== chipToBeRemoved.label);
    setTags(newChips);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const newTag = event.target.value;
      setTags([...tags, { label: newTag }]);
      event.target.value = "";
      setTagsValue("");
    }
  };

  const handleSave = () => {
    let updatedContainersHead = JSON.parse(JSON.stringify(contentDetails));

    if (urlValue && urlValue.length) {
      updatedContainersHead.url = urlValue;
    } else {
      updatedContainersHead.url = "";
    }
    if (buttonLabel && buttonLabel.length) {
      updatedContainersHead.buttonLabel = buttonLabel;
    } else {
      updatedContainersHead.buttonLabel = "";
    }

    if (containers && containers.length) {
      updatedContainersHead.subHeadings = containers;
    } else {
      updatedContainersHead.subHeadings = [];
    }

    if (tags && tags.length) {
      updatedContainersHead.tagHeadings[0].tags = tags.map(function (item) {
        return item.label;
      });
    }

    if (tags && !tags.length) {
      updatedContainersHead.tagHeadings[0].tags = [];
    }

    if (tagHeading && tagHeading.trim().length) {
      updatedContainersHead.tagHeadings[0].heading = tagHeading;
    }

    if (description && description.trim().length) {
      updatedContainersHead.content = description;
    }

    if (title && title.trim().length) {
      updatedContainersHead.title = title;
    }
    if (contentId && contentId.trim().length) {
      updatedContainersHead.contentId = contentId;
    }

    dispatch(
      editContentIdData(axiosInstance, { id: id }, updatedContainersHead)
    )
      .then((res) => {
        setToastList([
          {
            id: 0,
            // title: "Edited Successfully",
            title: "Content Edited Successfully.",
            icon: "success",
          },
        ]);

        setTimeout(() => {
          navigate(-1);
        }, 700);
      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            // title: "Edit Content Error",
            title: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className={style.back}>
          <Back
            title={ADVICE_MANAGEMENT.EDIT_CONTENT_DATA.BACK.DESCRIPTION}
            description={ADVICE_MANAGEMENT.EDIT_CONTENT_DATA.BACK.TITLE}
          />
        </div>
        <div className={style.heading}>
          <Heading
            title={contentDetails?.adviceId?.adviceName}
            description={
              ADVICE_MANAGEMENT.VIEW_CONTENT_DATA.HEADING.DESCRIPTION
            }
          />
          <div className={style.action_buttons}>
            <Button
              text={"Save Changes"}
              buttonType={`${buttonStyle.button_primary}`}
              marginTop={0}
              onClick={handleSave}
            />
          </div>
        </div>
        <section className={style.content}>
          <div className={style.upload}>
            <h3 className={style.heading}>Main Image</h3>
            <ImageUpload
              previewImage={
                contentDetails?.mainImgPath ? contentDetails?.mainImgPath : null
              }
              id="mainImage"
              handleFile={(url) => handleMainImage(url, "mainImage")}
            />
          </div>
          <div className={style.inner_content}> 
            <Input
              type="text"
              name="contentId"
              placeholder="Content Id"
              label="Content Id"
              value={contentId}
              mainWidth={"100%"}
              onChange={(value) => setContentId(value)}
            />
            {contentDetails &&
            contentDetails?.parentAdviceId?.adviceLabel !== "food" ? (
              <div className={style.conditional_input}>
                <Input
                  type="text"
                  name="subHeading"
                  placeholder="Subheading"
                  label="Title"
                  value={title}
                  mainWidth={"100%"}
                  onChange={(data) => setTitle(data)}
                />
              </div>
            ) : null}

            {contentDetails &&
            contentDetails?.parentAdviceId?.adviceLabel !== "food" ? (
              <div className={style.editor_container}>
                <h3 className={style.heading}>Head Image</h3>
                <ImageUpload
                  height={`80px`}
                  width={`80px`}
                  id="headImage"
                  previewImage={
                    contentDetails?.headImgPath?.length
                      ? contentDetails?.headImgPath[0]
                      : null
                  }
                  handleFile={(url) => handleMainImage(url, "headImage")}
                />
              </div>
            ) : null}
            <div className={style.editor_container}>
              <h3 className={style.heading}>Content</h3>
              <Editor
                borderRequired={false}
                readyOnly={false}
                setContent={(data) => setDescription(data)}
                content={description}
              />
            </div>

            {contentDetails &&
            contentDetails?.parentAdviceId?.adviceLabel !== "perfumes" ? (
              containers && containers?.length ? (
                containers?.map((content, outerIndex) => {
                  return (
                    <div key={outerIndex} className={style.row}>
                      <div className={style.subHeading}>
                        <Input
                          type="text"
                          name="subHeading"
                          placeholder="Subheading"
                          label="Sub Heading"
                          value={content.heading}
                          mainWidth={"100%"}
                          onChange={(value) =>
                            handleInputChange(
                              value,
                              null,
                              outerIndex,
                              "heading"
                            )
                          }
                        />
                        <Button
                          icon={"/icons/delete.svg"}
                          onClick={() => removeContainer(outerIndex)}
                        />
                      </div>
                      <div className={style.action_buttons}>
                        <Button
                          text={"+ Add Sub Container"}
                          buttonType={`${buttonStyle.button_secondary}`}
                          marginTop={0}
                          onClick={() => addsubContainer(outerIndex)}
                        />
                      </div>
                      <div className={style.subcontent_divider}>
                        <div className={style.label_container}>
                          {content?.images?.length
                            ? content?.images.map((data, innerIndex) => (
                                <div key={innerIndex} className={style.content}>
                                  <ImageUpload
                                    containerIndex={outerIndex}
                                    subContainerIndex={innerIndex}
                                    id={`${outerIndex}${innerIndex}`}
                                    handleFile={(data) =>
                                      handleFile(data, outerIndex, innerIndex)
                                    }
                                    previewImage={data.imgPath}
                                    height={"80px"}
                                    width={"80px"}
                                  />
                                  <Input
                                    type="text"
                                    name="imageName"
                                    placeholder="Image Name"
                                    label="Image Name"
                                    value={data.imgName}
                                    onChange={(value) =>
                                      handleInputChange(
                                        value,
                                        innerIndex,
                                        outerIndex,
                                        "subcontainer"
                                      )
                                    }
                                    mainWidth={"100%"}
                                  />
                                  <Button
                                    icon={"/icons/delete.svg"}
                                    onClick={() =>
                                      removeSubContainer(innerIndex, outerIndex)
                                    }
                                  />
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                      <div className={style.action_buttons}>
                        <Button
                          text={"+ Add Subheading"}
                          buttonType={`${buttonStyle.button_secondary}`}
                          marginTop={0}
                          onClick={() => addContainer()}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                contentDetails?.parentAdviceId?.adviceLabel !== "perfumes" && (
                  <div className={style.action_buttons}>
                    <Button
                      text={"+ Add Subheading"}
                      buttonType={`${buttonStyle.button_secondary}`}
                      marginTop={0}
                      onClick={() => addContainer()}
                    />
                  </div>
                )
              )
            ) : (
              <div className={style.form_container}>
                <div className={style.conditional_input}>
                  <Input
                    type="text"
                    name="subHeading"
                    placeholder="Tag Heading"
                    label="Tag Heading"
                    value={tagHeading}
                    mainWidth={"100%"}
                    onChange={(value) => setTagHeading(value)}
                  />
                </div>
                <div className={style.input_field}>
                  <div className={style.tags}>
                    <Input
                      type="text"
                      name="tags"
                      placeholder="Add tag and press enter"
                      label="Tags"
                      value={tagsValue}
                      onKeyDown={handleKeyDown}
                      onChange={(value) => setTagsValue(value)}
                    />
                    {/* <img src="/icons/add.svg" alt="add" className={style.add} /> */}
                  </div>
                </div>
                <div className={style.chips}>
                  <Chip
                    data={tags?.length ? tags : null}
                    showRemove={true}
                    onRemove={remove}
                  />
                </div>
                <div className={style.input_field}>
                  <div className={style.tags}>
                    <Input
                      type="text"
                      name="url"
                      placeholder="Add Order Now Button URL"
                      label="Order Now Button URL"
                      value={urlValue}
                      mainWidth={"100%"}
                      onChange={(value) => setUrlValue(value)}
                    />
                    {/* <img src="/icons/add.svg" alt="add" className={style.add} /> */}
                  </div>
                </div>
                <div className={style.input_field}>
                  <div className={style.tags}>
                    <Input
                      type="text"
                      name="buttonLabel"
                      placeholder="Add Button Label"
                      label="Button Label"
                      value={buttonLabel}
                      mainWidth={"100%"}
                      onChange={(value) => setButtonLabel(value)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </div>
  );
};

export default ViewUser;
