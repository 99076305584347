import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  listing: [],
  data: null,
};
const manageTipsSlice = createSlice({
  name: "manageTips",
  initialState,
  reducers: {
    manageTipSuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;
    },
    addTipSuccess: (state, action) => {
      state.data = action.payload;
    },
    editTipSuccess: (state, action) => {
      state.data = action.payload;
    },
    viewTipSuccess: (state, action) => {
      state.data = action.payload;
    },
    viewTipDetailsSuccess: (state, action) => {
      state.data = action.payload;
    },
    resetTipDetail: (state, action) => {
      state.data = null;
    },
    resetManageTipData: (state, action) => {
      state = {
        listing: [],
        data: null,
      };
    },
  },
});

export const {
  actions: manageTipsActions,
  reducer: manageTipsReducer,
  name: manageTips,
} = manageTipsSlice;
