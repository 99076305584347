import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import useExtractSubstring from "../../../../hooks/useExtractSubstring";
import {
  deleteBetaUserProperty,
  getSingleBetaUserPropertyList,
} from "../../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions";
import Back from "../../../../shared/components/backButton/backButton";
import Button from "../../../../shared/components/button/button";
import Delete from "../../../../shared/components/delete/delete";
import Heading from "../../../../shared/components/heading/heading";
import CenterModal from "../../../../shared/components/modal/centeredModal/centeredModal";
import style from "./userPropertyDetail.module.scss";

const UserPropertyDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();
  const [toastList, setToastList] = useState([]);
  const [detail, setDetail] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getSingleBetaUserPropertyList(axiosInstance, {}, {}, id))
      .then((response) => {
        setDetail(response?.data?.data);
      })
      .catch((error) => {
        setToastList([
          {
            id: 0,
            // title: "Error Fetching User Resident",
            title: error.response.data.message,
            icon: "error",
          },
        ]);
      });
  }, []);

  console.log(detail);

  const onDelete = (type) => {
    if (type === "Cancel") {
      setShowDelete(false);
    } else if (type === "Ok") {
      dispatch(deleteBetaUserProperty(axiosInstance, { ids: [id] }, {}))
        .then((res) => {
          setShowDelete(false);
          setToastList([
            {
              id: 0,
              // title: `Property Deleting Successful`,
              title: res.data.message,
              icon: "success",
            },
          ]);
          setTimeout(() => {
            navigate(-1);
          }, 1500);
        })
        .catch((error) => {
          setShowDelete(false);
          setToastList([
            {
              id: 0,
              // title: `Error deleting Property`,
              title: error.response.data.message,
              icon: "error",
            },
          ]);
        });
    }
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <CenterModal
          isOpen={showDelete}
          onClose={() => {
            setShowDelete(false);
          }}
          minWidth={"300px"}
        >
          <Delete
            title={"Delete Property"}
            description={"Are you sure you want to delete this Property?"}
            onButtonClick={onDelete}
          />
        </CenterModal>
        <div className={style.back}>
          <Back title={"Property Details"} description={"Back to view user"} />
        </div>
        <div className={style.heading}>
          <Heading title={useExtractSubstring(detail?._id)} description={""} />
          <div className={style.action_buttons}>
            <Button
              icon={"/icons/delete.svg"}
              onClick={() => setShowDelete(true)}
            />
          </div>
        </div>
        <div className={style.details}>
          <div className={style.profile_details}>
            <Heading
              title={"Property Details"}
              description={""}
              fontSize={"1.4rem"}
            />
            <hr className={style.divider} />
            <div className={style.column}>
              <div className={style.row}>
                <div className={style.sub_detail}>
                  <label className={style.label}>Property Name</label>
                  <p className={style.value}>{detail?.propName}</p>
                </div>
                <div className={style.sub_detail}>
                  <label className={style.label}>status</label>
                  <p className={style.value}>{detail?.status}</p>
                </div>
                <div className={style.sub_detail}>
                  <label className={style.label}>User ID</label>
                  <p className={style.value}>{detail?.userId}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </div>
  );
};

export default UserPropertyDetail;
