import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: null,
};

const booksSlice = createSlice({
  name: "books",
  initialState,
  reducers: {
    getBooksSuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;
    },
    deleteBookSuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;
    },
    addBookSuccess: (state, action) => {
      state.listing = null;
      state.data = action.payload;
    },
    updateBookSuccess: (state, action) => {
      state.listing = null;
      state.data = action.payload;
    },
    viewBookSuccess: (state, action) => {
      state.listing = null;
      state.data = action.payload;
    },
    resetBooksData: (state, action) => {
      state = {
        listing: [],
        data: null,
      };
    },
  },
});

export const {
  actions: booksAction,
  reducer: booksReducer,
  name: books,
} = booksSlice;
