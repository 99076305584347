export const BOOKS_MANAGEMENT = {
  HEADING: {
    TITLE: "Books Management",
    DESCRIPTION: "",
  },
  FILTERS: {
    APP_TYPE: {
      PLACEHOLDER: "Select App User",
    },
    SEARCH: {
      PLACEHOLDER: "Search",
    },
  },
  SELECT_APP_USERTYPE: {
    HEADING: {
      TITLE: "Add User",
      DESCRIPTION: "",
    },
    BUTTONS: {
      TITLE: "Add User",
    },
  },
  ADD_BOOK: {
    BACK: {
      TITLE: "Add User",
      DESCRIPTION: "Back to user management list",
    },
    HEADING: {
      TITLE: "Add Book",
      DESCRIPTION: "",
    },
    BUTTONS: {
      TITLE: "Add Book",
    },
    UPLOAD: {
      TITLE: "Book Image",
    },
  },
  EDIT_BOOK: {
    BACK: {
      TITLE: "Book",
      DESCRIPTION: "Back to book list",
    },
    HEADING: {
      TITLE: "S No. 001",
      DESCRIPTION: "",
    },
    SUB_HEADING: {
      TITLE: "User Profile Details",
      DESCRIPTION: "",
    },
    BUTTONS: {
      TITLE: "Update User",
    },
    UPLOAD: {
      TITLE: "Book Image",
    },
    NO_DATA: "No Sections Found",
  },
  VIEW_USER: {
    BACK: {
      TITLE: "View User",
      DESCRIPTION: "Back to normal user list",
    },
    HEADING: {
      DESCRIPTION: "",
    },
    BUTTONS: {
      TITLE: "Add User",
    },
    UPLOAD: {
      TITLE: "User Image",
    },
  },
  DELETE_BOOKS: {
    TITLE: "Delete Books",
    DESCRIPTION: "Are you sure you want to delete this books?",
  },
  DELETE_BOOK_SECTION: {
    TITLE: "Delete Section",
    DESCRIPTION: "Are you sure you want to delete this section?",
  },
  ADD_BOOK_SECTION: {
    BACK: {
      TITLE: "Add Book Sections",
      DESCRIPTION: "Back to view detail section",
    },
  },
  EDIT_BOOK_SECTION: {
    BACK: {
      TITLE: "Book Edit Detail",
      DESCRIPTION: "Back to view detail section",
    },
  },
  VIEw_BOOK_SECTION: {
    BACK: {
      TITLE: "Book Sections Detail",
      DESCRIPTION: "Back to view detail section",
    },
  },
};
