import AWS from "aws-sdk";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Toaster from "../../../../components/Global/Toaster/Toaster.component";
import { generateUUID } from "../../../../hooks/uniqueIdGenerator";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { viewSubAdmin } from "../../../../redux/AurLaVieAdmin/subAdmin/subAdmin.action";
import { isLoading } from "../../../../redux/progressLoader/progressLoader.actions";
import { EditProfileAction } from "../../../../redux/users/user.actions";
import Back from "../../../../shared/components/backButton/backButton";
import Button from "../../../../shared/components/button/button";
import buttonStyle from "../../../../shared/components/button/button.module.scss";
import ImageUpload from "../../../../shared/components/imageUpload/imageUpload";
import Input from "../../../../shared/components/inputFields/input";
import CenterModal from "../../../../shared/components/modal/centeredModal/centeredModal";
import ChangePassword from "../changePassword/ChangePassword";
import { PROFILE_MANAGEMENT } from "../profileManagement.constant";
import style from "./EditProfileManagement.module.scss";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const EditProfileManagement = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [showAddBook, setShowAddBook] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [mainImage, setMainImage] = useState("");
  const user = useSelector((store) => store.user.data);
  const [toastList, setToastList] = useState([]);
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    const params = {
      isAdmin: true,
    };
    viewSubAdminUser(axiosInstance, params, user._id);
  }, [user]);

  const viewSubAdminUser = (axiosInstance, params, userId) => {
    dispatch(viewSubAdmin(axiosInstance, params, userId))
      .then((response) => {
        const newFormData = {
          "Email Id": response?.email,
          "First Name": response?.name,
          imgPath: response?.profilePic,
        };
        setFormData(newFormData);
      })
      .catch((error) => {
        setToastList([
          {
            id: 0,
            // title: "Error viewing user",
            title: error?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  useEffect(() => {
    if (
      formData["Email Id"] !== "" &&
      formData["Email Id"] !== undefined &&
      formData["First Name"] !== "" &&
      formData["First Name"] !== undefined &&
      validateEmail(formData["Email Id"])
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [formData]);

  const inputFields = [
    {
      type: "text",
      name: "First Name",
      label: "First Name",
      required: false,
      width: "50%",
    },
    {
      type: "email",
      name: "Email Id",
      label: "Email Id",
      required: false,
      width: "50%",
      isDisabled: true,
    },
  ];

  const handleInputChange = (fieldName, fieldValue) => {
    setFormData({ ...formData, [fieldName]: fieldValue });
  };

  const handleFile = (url) => {
    if (url || url === "") {
      setMainImage(url);
    }
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
    region: process.env.REACT_APP_AWS_REGION,
  });

  const handleUpdate = (imageUrl) => {
    const userDetail = {
      _id: user._id,
      email: formData["Email Id"],
      isAdmin: user.isAdmin,
      isVip: user.isVip,
      status: user.status,
      roleId: user.roleId,
      contactNumber: user.contactNumber,
      name: formData["First Name"],
    };

    if (imageUrl && !imageUrl?.includes("undefined")) {
      userDetail["profilePic"] = imageUrl;
    } else {
      userDetail["profilePic"] = mainImage || formData.imgPath;
    }
    dispatch(EditProfileAction(axiosInstance, userDetail, {}))
      .then((res) => {
        localStorage.setItem("userPic", imageUrl);
        localStorage.setItem("userName", formData["First Name"]);
        setToastList([
          {
            id: 0,
            title: res.data.message,
            icon: "success",
          },
        ]);
        const newFormData = {
          "Email Id": res?.email,
          "First Name": res?.name,
          imgPath: res?.profilePic,
        };
        setFormData({ ...formData, ...newFormData });
        setTimeout(() => {
          navigate("/profileManagement");
        }, 1500);
      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            title: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const uploadToS3 = async (file, name) => {
    dispatch(isLoading(true));
    const id = generateUUID();
    const fileKey = `user-${id}.${file?.type.split("/")[1]}`;
    const params = {
      Body: file,
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: fileKey,
    };
    try {
      const upload = await myBucket.upload(params).promise();
      if (upload) {
        dispatch(isLoading(false));
        handleUpdate(upload.Location);
      }
    } catch (error) {
      setToastList([
        {
          id: 0,
          // title: "Error",
          title: "Error while uploading the Profile image.",
          icon: "error",
        },
      ]);
      dispatch(isLoading(false));
    }
  };

  const onUpdate = (e) => {
    e.preventDefault();
    if (mainImage.name) {
      uploadToS3(mainImage, mainImage.name);
    } else {
      handleUpdate();
    }
  };

  useEffect(() => {
    setIsClosed(!showAddBook);
  }, [showAddBook]);

  const closeModal = () => setShowAddBook(false);
  const ShowPasswordChangeModel = () => setShowAddBook(true);

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <CenterModal isOpen={showAddBook} onClose={closeModal}>
          <ChangePassword
            id={user._id}
            setToastList={setToastList}
            closeModal={closeModal}
            isModalClosed={isClosed}
          />
        </CenterModal>
        <div className={style.back}>
          <Back
            title={PROFILE_MANAGEMENT.EDIT_PROFILE.BACK.TITLE}
            description={PROFILE_MANAGEMENT.EDIT_PROFILE.BACK.DESCRIPTION}
          />
        </div>
        <div className={style.form_container} style={{ marginTop: "2rem" }}>
          <div className={style.upload}>
            <ImageUpload
              handleFile={handleFile}
              title={PROFILE_MANAGEMENT.EDIT_PROFILE.UPLOAD.TITLE}
              previewImage={formData?.imgPath !== "" && formData?.imgPath}
            />
          </div>
          <form onSubmit={onUpdate}>
            <div className={style.form_container}>
              {inputFields.map((inputField, index) => (
                <div
                  key={index}
                  className={style.input_field}
                  style={{ width: inputField.width }}
                >
                  <Input
                    type={inputField.type}
                    name={inputField.name}
                    label={inputField.label}
                    value={formData[inputField?.name]}
                    placeholder={inputField.placeholder}
                    options={inputField.options}
                    required={inputField.required}
                    isDisabled={inputField.isDisabled}
                    onChange={(value) =>
                      handleInputChange(inputField?.name, value)
                    }
                    handleInputBlur={() => {}}
                  />
                  {/* <div className={style.error}>{formErrors[inputField.name]}</div> */}
                </div>
              ))}
              <div className={style.button_container}>
                <div className={style.action_button}>
                  <Button
                    disabled={isSubmitDisabled}
                    text="Update Profile"
                    buttonType={
                      isSubmitDisabled
                        ? `${buttonStyle.button_disabled}`
                        : `${buttonStyle.button_primary}`
                    }
                  />
                </div>
                <div className={style.action_button}>
                  <Button
                    disabled={false}
                    type="button"
                    onClick={ShowPasswordChangeModel}
                    text="Change Password"
                    buttonType={buttonStyle.button_primary}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </div>
  );
};

export default EditProfileManagement;
