import React from "react";
import style from "./chips.module.scss";

const Chip = ({ data, onRemove, showRemove }) => {
  return (
    <>
      {data?.length > 0 &&
        data.map((chip, index) => {
          return (
            <div key={index} className={style.chip}>
              <span className={style.chip_label}>{chip.label}</span>
              {showRemove ? (
                <span
                  className={style.chip_close}
                  onClick={() => onRemove(chip)}
                >
                  <img src="/icons/close.svg" alt="close" width={15} />
                </span>
              ) : null}
            </div>
          );
        })}
    </>
  );
};

export default Chip;
