import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Toaster from "../../../components/Global/Toaster/Toaster.component";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { languageState } from "../../../redux/AurLaVieAdmin/languages/getLanguages.selector";
import {
  deleteReport,
  getAllReports,
} from "../../../redux/AurLaVieAdmin/reports/reports.action";
import Button from "../../../shared/components/button/button";
import Delete from "../../../shared/components/delete/delete";
import Heading from "../../../shared/components/heading/heading";
import Input from "../../../shared/components/inputFields/input";
import CenterModal from "../../../shared/components/modal/centeredModal/centeredModal";
import Table from "../../../shared/components/table/table";
import AddContentId from "./addContentId/addContentId";
import { REPORT_MANAGEMENT } from "./reportsManagementContants";
import style from "./reportsMangement.module.scss";

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [showAddContentIdModal, setShowAddContentIdModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [toastList, setToastList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [isModalClosed, setIsModalClosed] = useState(false);
  const [totalItem, setTotalItem] = useState(0);
  const [showDelete, setShowDelete] = useState({ value: false, id: "" });
  const languageData = useSelector(languageState);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const currentDeleteRef = useRef(null);
  const [ids, setIds] = useState(null);

  useEffect(() => {
    if (languageData?.languageId) {
      const params = {
        page,
        limit: pageSize,
        isAdmin: true,
        languageId: languageData?.languageId,
      };
      getReports(axiosInstance, params);
    }
  }, [languageData, page]);

  useEffect(() => {
    setPage(1);
    let timerId;
    const fetchResults = async () => {
      try {
        const params = {
          page,
          limit: pageSize,
          isAdmin: true,
          languageId: languageData?.languageId,
          keyword: searchTerm,
        };
        getReports(axiosInstance, params);
      } catch (error) {
        console.log(error);
      }
    };

    if (searchTerm || searchTerm === "") {
      timerId = setTimeout(() => {
        fetchResults();
      }, 500);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  const getReports = (axiosInstance, params) => {
    dispatch(getAllReports(axiosInstance, params))
      .then((response) => {
        const updatedReports = response.data.data.listingData.map(
          (contentId) => {
            return {
              id: contentId?._id,
              contentId: contentId?.reportContentId,
              lastUpdatedOn: moment(contentId?.modified_at).format(
                "MM/DD/YYYY"
              ),
            };
          }
        );
        setReports(updatedReports);
        setTotalItem(response?.data?.data?.meta?.total);
      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            // title: "Fething Reports Failed",
            title: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const handlePage = (page) => {
    setPage(page);
  };

  const onSearch = (event) => {
    setSearchTerm(event);
  };

  // const onEdit = (event) => {
  //   navigate(`/fengshui/reportsManagement/editContentId/${event.id}`);
  // };

  const onDelete = (data) => {
    currentDeleteRef.current = [data.id];
    setShowDeleteModal(true);
  };

  const onView = (event) => {
    navigate(
      `/fengshui/reportsManagement/viewContentId/property/${event.contentId}`,
      { state: { id: event.id } }
    );
  };

  const addUser = () => {
    setShowAddContentIdModal(true);
    setIsModalClosed(false);
  };

  const handleMultiple = (event) => {
    setIds(event);
  };

  const deleteMultiple = () => {
    if (ids.length) {
      setShowDeleteModal(true);
    }
  };

  const handleDeleteReport = (clicked) => {
    if (clicked === "Cancel") {
      setShowDeleteModal(false);
      currentDeleteRef.current = null;
    } else {
      const data = currentDeleteRef.current?.length
        ? { contentIds: currentDeleteRef.current }
        : { contentIds: ids };
      dispatch(deleteReport(axiosInstance, data))
        .then((res) => {
          setIds(null);
          currentDeleteRef.current = null;
          setToastList([
            {
              id: 0,
              // title: "Report Deleted",
              title: "Report Deleted Successfully",
              icon: "success",
            },
          ]);
          setTimeout(() => {
            const params = {
              page,
              limit: pageSize,
              isAdmin: true,
              languageId: languageData?.languageId,
            };
            getReports(axiosInstance, params);
            setShowDeleteModal(false);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setToastList([
            {
              id: 0,
              // title: "Delete Report Error",
              title: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    }
  };

  const closeModal = () => {
    setShowAddContentIdModal(false);
    setIsModalClosed(true);
    const params = {
      page,
      limit: pageSize,
      isAdmin: true,
      languageId: languageData?.languageId,
    };
    getReports(axiosInstance, params);
  };

  return (
    <section className={style.container}>
      <div className={style.wrapper}>
        <CenterModal
          isOpen={showAddContentIdModal}
          onClose={() => {
            setShowAddContentIdModal(false);
          }}
        >
          <AddContentId isClosed={closeModal} />
        </CenterModal>
        <CenterModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          minWidth={"300px"}
        >
          <Delete
            title={REPORT_MANAGEMENT.DELETE_REPORT.TITLE}
            description={REPORT_MANAGEMENT.DELETE_REPORT.DESCRIPTION}
            onButtonClick={handleDeleteReport}
          />
        </CenterModal>
        <section className={style.title_filters}>
          <Heading
            title={REPORT_MANAGEMENT.HEADING.TITLE}
            description={REPORT_MANAGEMENT.HEADING.DESCRIPTION}
            icon="/icons/reports.svg"
          />
          <div className={style.filters}>
            <Button
              icon={"/icons/add.svg"}
              text={REPORT_MANAGEMENT.ADD_REPORT_CONTENT_ID.BUTTONS.TITLE}
              onClick={addUser}
              width={"220px"}
            />
            <Input
              type={"search"}
              placeholder={REPORT_MANAGEMENT.FILTERS.SEARCH.PLACEHOLDER}
              value={searchTerm}
              onChange={onSearch}
              width={"220px"}
            />
            <Button icon={"/icons/delete.svg"} onClick={deleteMultiple} />
          </div>
        </section>
        <section className={style.listing}>
          <Table
            data={reports}
            editNotRequired={true}
            onView={onView}
            onEdit={() => {}}
            // onEdit={onEdit}
            onDelete={onDelete}
            totalItem={totalItem}
            handlePage={handlePage}
            pageNumber={page}
            handleMultiple={handleMultiple}
            noDataText={"No Reports Found"}
          />
        </section>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </section>
  );
};

export default Reports;
