import { endpoints } from "../../../constants/url.endpoint";
import { getLanguagesAction } from "./getLanguages.reducer";

const { getLanguagesSuccess, resetLanguagesData, setLanguageID } =
  getLanguagesAction;

export const getLanguages =
  (axiosInstance, languageCode) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.getLanguages,
        method: "GET",
        params: languageCode,
      })
        .then((response) => {
          dispatch(getLanguagesSuccess(response?.data?.data));
          resolve(response?.data?.data);
        })
        .catch((error) => {
          console.log("er", error);
          dispatch(resetLanguagesData());
          reject(error);
        });
    });
  };

export const getAllLanguages = (axiosInstance) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: endpoints.getAllLanguages,
      method: "GET",
    })
      .then((response) => {
        dispatch(getLanguagesSuccess(response?.data?.data));
        resolve(response?.data?.data);
      })
      .catch((error) => {
        console.log("er", error);
        dispatch(resetLanguagesData());
        reject(error);
      });
  });
};

export const updateLanguage = (languageId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setLanguageID(languageId));
    resolve(languageId);
  });
};
