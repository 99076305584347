import { endpoints } from "../../../constants/url.endpoint";
import { userTypesAction } from "./userType.reducer";

const {
    getUserTypeSuccess,
    resetUserTypeData
} = userTypesAction;

export const getUserTypes =
    (axiosInstance, appId) => async (dispatch) => {
        return new Promise((resolve, reject) => {
            axiosInstance({
                url: endpoints.getUserTypes,
                method: "GET",
                params: {appId: appId},
            })
                .then((response) => {
                    dispatch(getUserTypeSuccess(response?.data?.data));
                    resolve(response?.data?.data);
                })
                .catch((error) => {
                    console.log('er', error)
                    dispatch(resetUserTypeData());
                    reject(error);
                });
        });
    };