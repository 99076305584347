import React from "react";
import style from "./toggleButton.module.scss";

const ToggleButton = ({
  preLable,
  postLable,
  isChecked,
  handleToggle,
  justifyContent,
  disableButton,
}) => {
  const disableStyle = { opacity: disableButton ? "0.4" : "1" };
  return (
    <>
      <div
        style={{
          justifyContent: justifyContent ? justifyContent : null,
          pointerEvents: disableButton ? "none" : "auto",
        }}
        className={style.toggle_container}
      >
        <span style={disableStyle} className={style.label}>
          {preLable}
        </span>
        <label style={disableStyle} className={style.switch}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleToggle}
          ></input>
          <span className={`${style.slider} ${style.round}`}></span>
        </label>
        <span style={disableStyle} className={style.label}>
          {postLable}
        </span>
      </div>
    </>
  );
};

export default ToggleButton;
