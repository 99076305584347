import { useNavigate } from 'react-router-dom';
import style from './backButton.module.scss';

const Back = (props) => {

    const { title, description, redirect } = props;
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

    return (
        <div className={style.container}>
            <div className={style.wrapper}>
                <div className={style.icon} onClick={redirect ? redirect : goBack}>
                    <img src='/icons/back_grey.svg' alt={title} loading="lazy" width={50} />
                </div>
                <div className={style.heading}>
                    <p className={style.description}>{description}</p>
                    <h3 className={style.title}>{title}</h3>
                </div>
            </div>
        </div>
    )
}

export default Back;