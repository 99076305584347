import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import ProgressLoaderComponent from "../../components/Global/ProgressLoader/ProgressLoader.component";
import { axiosInstance } from "../../constants/axiosInstance";
import { getAppById } from "../../redux/AurLaVieAdmin/apps/getApps.action";

const RedirectAppPage = () => {
  const [options, setOptions] = useState([]);
  const [appId, setAppId] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();

  const handleRedirection = () => {
    const routeSplit = location?.pathname.split("/");
    const appId = routeSplit[2];
    setAppId(appId);
    const urlToken = routeSplit[routeSplit.length - 1];
    getAppInfo(axiosInstance, appId, urlToken);
  };

  useEffect(() => {
    console.log("redirection runnning");
    handleRedirection();
  }, [location.pathname]);

  const getAppInfo = (axiosInstance, appId, urlToken) => {
    dispatch(getAppById(axiosInstance, appId))
      .then((res) => {
        let ua = navigator.userAgent.toLowerCase();
        let isAndroid = ua.indexOf("android") > -1; // android check
        let isIphone = ua.indexOf("iphone") > -1; // ios check
        console.log({ res, ua });

        let iosPackageUrl = res.iosPackageUrl;
        let iosStoreUrl = res.iosStoreUrl;
        let androidPackageUrl = res.androidPackageUrl;
        let playStoreUrl = res.playStoreUrl;

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let pageType = params.get("type");
        let pageName = "";
        if (pageType == "forgotpassword") {
          pageName = `forgotpassword/?urlToken=${urlToken}`;
        } else if (pageType == "login") {
          pageName = "login";
        }

        if (isIphone == true) {
          console.log({
            iosPackageUrl,
            url: `${iosPackageUrl}${pageName}`,
            second: `com.aurlavie://forgotpassword/${urlToken}`,
            pageName,
          });

          let app = {
            launchApp: function () {
              window.location.href = `com.auracle://${pageName}`;
              setTimeout(this.openWebApp, 1000);
            },
            openWebApp: function () {
              window.location.href = iosStoreUrl;
            },
          };
          app.launchApp();
        } else if (isAndroid == true) {
          let app = {
            launchApp: function () {
              window.location.href = `intent://com.auraclelife/?urlToken=${urlToken}#Intent;scheme=https;package=com.auraclelife;end`;
              // window.location.href = 'intent://com.auraclelife/?sdfsdfdsf/sdfsdf#Intent;scheme=https;package=com.auraclelife;end';
              setTimeout(this.openWebApp, 1000);
            },
            openWebApp: function () {
              window.location.href = playStoreUrl;
              // "https://play.google.com/store/apps/details?id=com.fashioncolor.fashioncolorapp";
            },
          };
          app.launchApp();
        } else {
          let app = {
            launchApp: function () {
              setTimeout(
                (window.location.href = playStoreUrl),
                // "https://play.google.com/store/apps/details?id=com.fashioncolor.fashioncolorapp"),
                500
              );
            },
          };
          app.launchApp();

          //navigate to website url
        }
      })
      .catch((error) => {
        console.log("err", error?.response?.data.message);
      });
  };

  return <ProgressLoaderComponent />;
};
export default RedirectAppPage;
