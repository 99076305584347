import React, { useState } from 'react'
import style from './betaUserDetails.module.scss';
import Button from '../../../shared/components/button/button';
import Table from '../../../shared/components/table/table';
import CenterModal from '../../../shared/components/modal/centeredModal/centeredModal';
import Delete from '../../../shared/components/delete/delete';
import { useDispatch } from 'react-redux';
import { deleteBetaUserProperty, deleteBetaUserResident } from '../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions';
import useAxiosInstance from '../../../hooks/useAxiosInstance';
import BetaAddProperty from '../../../shared/components/betaAddProperty/betaAddProperty';
import BetaAddResident from '../../../shared/components/betaAddResident/betaAddResident';
import { useNavigate } from 'react-router-dom';

const BetaUserDetails = ({ DATA, heading, subHeading, title, setToastList, raw, refresh, refreshResident, userId }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const axiosInstance = useAxiosInstance()
    const [ids, setIds] = useState([])
    const [show, setShow] = useState(false)
    const [betaProperty, setBetaProperty] = useState(false)
    const [betaResident, setBetaResident] = useState(false)
    const [onEditOpen, setOnEditOpen] = useState(false)
    const [singleData, setSingleData] = useState({})
    const [showDelete, setShowDelete] = useState({ value: false, id: '' })
    const handleMultiple = (event) => setIds(event)
    const onDelete = item => setShowDelete({ value: true, id: item.id })

    const onDeleteValue = (type) => {
        if(type === 'Cancel') {
            setShowDelete({ value: false, id: '' })
        } else if (type === 'Ok') {
          if(showDelete.id) {
            deleteItem([showDelete.id])
          } else {
            deleteItem(ids)
          }
        }
    }

    const deleteItem = (idList) => {
        dispatch(title === "Property" ? deleteBetaUserProperty(axiosInstance, {ids: idList}, {}) : deleteBetaUserResident(axiosInstance, {ids: idList}, {})).then(res => {
            setShowDelete({ value: false, id: '' })
            refresh()
            if (title === 'Property') refreshResident()
            setTimeout(() => {
                setIds([])
                setToastList([{
                    id: 0,
                    // title: `${title}${idList.length >= 2 ? 's': '' } deleted successfully`,
                    title: res.data.message,
                    icon: "success",
                }])
            }, 300)
        }).catch(error => {
            setShowDelete({ value: false, id: '' })
            setTimeout(() => {
                setToastList([{
                    id: 0,
                    // title: `Error deleting ${title}${idList.length >= 2 ? 's': '' }`,
                    title: error.response.data.message,
                    icon: "error",
                }])
            }, 300);
        })
    }

    const onAdd = () => {
        setSingleData({})
        setOnEditOpen(false)
        if(title === "Resident") setBetaResident(true)
        if(title === "Property") setBetaProperty(true)
        setShow(true)
    }

    const onAddClose = () => {
        setShow(false)
        if(title === "Resident") setBetaResident(false)
        if(title === "Property") setBetaProperty(false)
    }

    const onEdit = (e) => {
        const data = raw.find(value => value?._id === e.id)
        setSingleData(data)
        setOnEditOpen(true)
        if(title === "Resident") setBetaResident(true)
        if(title === "Property") setBetaProperty(true)
        setShow(true)
    }

    const onView = (e) => {
        if(title === "Resident") navigate(`/userManagement/viewUser/resident/${e.id}`)
        if(title === "Property") navigate(`/userManagement/viewUser/property/${e.id}`)
    }

    return (
        <>  
                <CenterModal
                    isOpen={showDelete?.value}
                    onClose={() => {
                      setShowDelete({ value: false, id: '' })
                    }}
                    minWidth={"300px"}
                    >
                    <Delete
                        title={`Delete ${title}`}
                        description={`Are you sure you want to delete this ${title}?`}
                        onButtonClick={onDeleteValue}
                    />
                </CenterModal>
                <CenterModal isOpen={show} onClose={onAddClose} minWidth={'300px'}>
                    <>
                        {betaProperty && 
                            <BetaAddProperty
                                title={onEditOpen ? "Edit Property" : "Add Property"}
                                description={'Pellentesque at ipsum ac felis suscipit sollicitudin nec eu ex.'}
                                onviewPage={true}
                                setShow={setShow}
                                setBetaProperty={setBetaProperty}
                                setToastList={setToastList}
                                singleData={singleData}
                                onEditOpen={onEditOpen}
                                refresh={refresh}
                                userId={userId}
                                />}

                        {betaResident &&
                            <BetaAddResident
                                title={onEditOpen ? 'Edit Resident' : 'Add Resident'}
                                description={'Pellentesque at ipsum ac felis suscipit sollicitudin nec eu ex.'}
                                onviewPage={true}
                                setShow={setShow}
                                setToastList={setToastList}
                                setBetaResident={setBetaResident}
                                singleData={singleData}
                                onEditOpen={onEditOpen}
                                refresh={refresh}
                                userId={userId}
                                />}
                    </>
                </CenterModal>
                <div className={style.header_box}>
                        <div className={style.headings}>
                            <h3 className={style.headings_main}>{heading}</h3>
                            <p className={style.headings_sub}>{subHeading}</p>
                        </div>
                        <Button
                        icon={"/icons/add.svg"}
                        text={`Add ${title}`}
                        onClick={onAdd}
                        />
                    </div>

                    <section className={style.listing}>
                    <div className={style.heading}>
                        <h3 className={style.label}>{title}</h3>
                        {ids?.length >= 1 && <Button icon={"/icons/delete.svg"} onClick={() => setShowDelete({ value: true, id: '' })} />}
                    </div>
                    <Table
                        data={DATA}
                        onView={onView}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        // totalItem={() => {}}
                        // handlePage={() => {}}
                        pageNumber={1}
                        noDataText={'No Data'}
                        marginTop={'100px'}
                        // handleToggle={() => {}}
                        handleMultiple={(handleMultiple)}
                    />
                    </section>
        </>
    )
}

export default BetaUserDetails