import { endpoints } from "../../../constants/url.endpoint";
import { manageNotificationActions } from "./manageNotification.reducer";

const {
  addNotificationSuccess,
  manageNotificationsSuccess,
  resetNotificationDetail,
  viewNotificationSuccess,
} = manageNotificationActions;

export const fetchNotifications =
  (axiosInstance, apiData, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.notificationListing,
        method: "POST",
        data: apiData,
        params,
      })
        .then((res) => {
          dispatch(manageNotificationsSuccess(res?.data?.data));
          resolve(res);
        })
        .catch((err) => {
          dispatch(resetNotificationDetail());
          reject(err);
        });
    });
  };

export const addNotifications =
  (axiosInstance, notificationDetails) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: endpoints.addNotification,
        method: "POST",
        data: notificationDetails,
      })
        .then((response) => {
          dispatch(addNotificationSuccess(response?.data?.data));
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(resetNotificationDetail());
          reject(error);
        });
    });
  };

export const updateNotification =
  (axiosInstance, notificationDetails, params) => async (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `${endpoints.updateNotification}/${params.id}`,
        method: "POST",
        data: notificationDetails,
      })
        .then((response) => {
          dispatch(manageNotificationsSuccess(response?.data?.data));
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(resetNotificationDetail());
          reject(error);
        });
    });
  };

export const viewNotification = (axiosInstance, params) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: `${endpoints.viewNotification}/${params.id}`,
      method: "POST",
    })
      .then((response) => {
        dispatch(viewNotificationSuccess(response?.data?.data));
        resolve(response?.data?.data);
      })
      .catch((error) => {
        dispatch(resetNotificationDetail());
        reject(error);
      });
  });
};

export const deleteNotification = (axiosInstance, data) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axiosInstance({
      url: `${endpoints.deleteNotification}`,
      method: "POST",
      data,
    })
      .then((response) => {
        dispatch(viewNotificationSuccess(response?.data));
        resolve(response?.data);
      })
      .catch((error) => {
        dispatch(resetNotificationDetail());
        reject(error);
      });
  });
};
