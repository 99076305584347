import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import style from "./editor.module.scss";
import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageResize", ImageResize);
Quill.register(Quill.import("attributors/style/direction"), true);
Quill.register(Quill.import("attributors/style/align"), true);

const allowedFontSizes = [
  "8px",
  "9px",
  "10px",
  "12px",
  "14px",
  "16px",
  "20px",
  "24px",
  "32px",
  "42px",
  "54px",
  "68px",
  "84px",
  "98px",
];
var Sizes = Quill.import("attributors/style/size");
Sizes.whitelist = allowedFontSizes;
Quill.register(Sizes, true);

const Link = Quill.import("formats/link");
Link.sanitize = function (url) {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return `http://${url}`;
  }
  return url;
};

Quill.register(Link, true);

const Editor = ({
  content,
  readyOnly,
  setContent,
  borderRequired,
  editorStyle,
}) => {
  return (
    <div className={style.editor} style={{ ...editorStyle }}>
      <ReactQuill
        className={
          !readyOnly
            ? null
            : borderRequired
            ? "border-editor"
            : "blurred-editor"
        }
        readOnly={readyOnly}
        value={content}
        onChange={setContent}
        modules={{
          toolbar: {
            container: [
              ["bold", "italic", "underline"],
              [{ size: allowedFontSizes }],
              [{ header: "1" }, { header: "2" }],
              [
                { align: "" },
                { align: "center" },
                { align: "right" },
                { align: "justify" },
              ],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ link: "link" }, "image"],
              [
                {
                  color: [
                    "#222222",
                    "#e60000",
                    "#ff9900",
                    "#ffff00",
                    "#008a00",
                    "#0066cc",
                    "#9933ff",
                    "#ffffff",
                    "#facccc",
                    "#ffebcc",
                    "#ffffcc",
                    "#cce8cc",
                    "#cce0f5",
                    "#ebd6ff",
                    "#bbbbbb",
                    "#f06666",
                    "#ffc266",
                    "#ffff66",
                    "#66b966",
                    "#66a3e0",
                    "#c285ff",
                    "#888888",
                    "#a10000",
                    "#b26b00",
                    "#b2b200",
                    "#006100",
                    "#0047b2",
                    "#6b24b2",
                    "#444444",
                    "#5c0000",
                    "#663d00",
                    "#666600",
                    "#003700",
                    "#002966",
                    "#3d1466",
                    // "custom-color",
                  ],
                },
              ],
            ],
          },
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
          },
          imageResize: {
            parchment: Quill.import("parchment"),
            modules: ["Resize", "DisplaySize"],
          },
        }}
        formats={[
          "header",
          "font",
          "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          "image",
          "video",
          "align",
          "color",
          "width",
        ]}
      />
    </div>
  );
};

export default Editor;
