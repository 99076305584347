import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  data: null,
  languageId: null,
};

const getLanguageSlice = createSlice({
  name: "getLanguages",
  initialState,
  reducers: {
    getLanguagesSuccess: (state, action) => {
      state.listing = action.payload;
      state.data = null;
    },
    resetLanguagesData: (state, action) => {
      state = {
        listing: [],
        data: null,
      };
    },
    setLanguageID: (state, action) => {
      state.languageId = action.payload;
    },
  },
});

export const {
  actions: getLanguagesAction,
  reducer: getLanguageReducer,
  name: getLanguages,
} = getLanguageSlice;
