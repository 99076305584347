export const TIPS_MANAGEMENT = {
    HEADING: {
        TITLE: 'Tips Management',
        DESCRIPTION: "",
    },
    SWITCH_BUTTON_TEXT: {
        CATEGORY_MANAGEMENT: 'CATEGORY_MANAGEMENT',
        TIPS_MANAGEMENT: 'TIPS_MANAGEMENT'
    },
    FILTERS: {
        APP_TYPE: {
            PLACEHOLDER: "Select Category"
        },
        SEARCH: {
            PLACEHOLDER: "Search Tip"
        }
    },
    SELECT_APP_USERTYPE: {
        HEADING: {
            TITLE: 'Add User',
            DESCRIPTION: "",
        },
        BUTTONS: {
            TITLE: 'Add User'
        }
    },
    ADD_USER: {
        BACK: {
            TITLE: 'Add User',
            DESCRIPTION: 'Back to user management list',
        },
        HEADING: {
            TITLE: 'User Details',
            DESCRIPTION: "",
        },
        BUTTONS: {
            TITLE: 'Add User'
        },
        UPLOAD: {
            TITLE: 'User Image'
        }
    },
    EDIT_USER: {
        BACK: {
            TITLE: 'Edit User',
            DESCRIPTION: 'Back to view user',
        },
        HEADING: {
            TITLE: 'User Details',
            DESCRIPTION: "",
        },
        SUB_HEADING: {
            TITLE: 'User Profile Details',
            DESCRIPTION: "",
        },
        BUTTONS: {
            TITLE: 'Update User'
        },
        UPLOAD: {
            TITLE: 'User Image'
        }
    },
    VIEW_USER: {
        BACK: {
            TITLE: 'View User',
            DESCRIPTION: 'Back to normal user list',
        },
        HEADING: {
            TITLE: 'User Profile Details',
            DESCRIPTION: "",
        },
        BUTTONS: {
            TITLE: 'Add User'
        },
        UPLOAD: {
            TITLE: 'User Image'
        }
    }
}